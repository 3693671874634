export const APPDATA = {
    "name": "Republic of Singapore Flying Club",
    "description": "Making flying affordable for all",
    // "server": "http://192.168.1.220:4000",
    // "server": "http://localhost:4000",
    // "server": "http://172.20.10.6:4000",
    "server": "https://rsfc-backend.ourcodelab.com",
    "local": "https://rsfc.ourcodelab.com",
    // "local" : "http://localhost:3000",
    // "local": "http://172.30.33.198:3000",
    // "local": "http://172.30.80.81:3000",
    // "local": "http://172.30.33.81:3000",
    // "local": "http://192.168.1.12:3000",
    // "local": "http://192.168.1.220:3000",
    //"local":"http://172.20.10.6:3000"
    
}