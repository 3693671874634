import Chatbot,{ createChatBotMessage } from "react-chatbot-kit";
import config from "../chat-bot/config";
import MessageParser from "../chat-bot/MessageParser";
import ActionProvider from "../chat-bot/ActionProvider";
import "../chat-bot/chatbot.css"
import { useState } from "react";
import { BsFillChatLeftDotsFill } from "react-icons/bs";
import { IoClose } from "react-icons/io5";

const RSFCChatBot = () => {
    const [showState, setShowState] = useState(false)
    return ( 
        <div className="floatingChatBot">
            
            {showState?
                <Chatbot 
                    style={{position:"fixed",bottom:0,right:0,zIndex:1000}}
                    config={config}
                    messageParser={MessageParser}
                    actionProvider={ActionProvider}
                /> :
            null}

            <div className="chatbot-button" onClick={() => {setShowState(!showState);console.log(showState)}}>
                {showState?<IoClose color={"white"}/>:<BsFillChatLeftDotsFill color={"white"}/>}

            </div>
        </div>

     );
}
 
export default RSFCChatBot;