import {HashLink as Link} from 'react-router-hash-link';
import "../css/components.css"

export default function Footer () {
    return(
        <div className="footer">
            <div className="footer__container">
                <h4>RSFC</h4>
                <Link className='links' to={"/about-us#aboutus"}>About Us</Link>
                <Link className='links' to={"/about-us#contactus"}>Contact Us</Link>
                <Link className='links' to={"/about-us#ourpeople"}>Our People</Link>
                <Link className='links' to={"/about-us#fleet"}>Our Fleet</Link>
                <Link className='links' to={"/about-us#stories"}>Our Stories</Link>
            </div>
            {/* <div className="footer__container">
                <h4>Events</h4>
                <Link className='links' to={"/events"}>Past Events</Link>
                <Link className='links' to={"/membership"}>Join Us</Link>
            </div> */}
            <div className="footer__container">
                <h4>Membership</h4>
                <Link className='links' to={"/membership#ourmembership"}>Our Membership</Link>
                <Link className='links' to={"/membership#learningtofly"}>Learning to Fly</Link>
                <Link className='links' to={"/membership#summary"}>Obtaining your license</Link>
            </div>
            <div className="footer__container">
                <h4>Resources</h4>
                <a className='links' href="https://www.caas.gov.sg/legislation-regulations/guidelines-advisory/air-navigation-services/aeronautical-information-publication-amendments" target="_blank">AIP</a>
                <a className='links' href="https://www.caas.gov.sg/docs/default-source/pdf/directory-of-dme-of-caas_jan2021.pdf" target="_blank">Designated Medical Examiners (Last updated Jan 2021)</a>
                <a className='links' href="https://www.caas.gov.sg/docs/default-source/pdf/caas-fc06-application-for-student-pilot-licence-r6_oct18.pdf" target="_blank">Application Form for SPL</a>
                <a className='links' href="https://www.caas.gov.sg/docs/default-source/pdf/caas-fc67-renewal-of-certificate-of-experience_r4_aug-19.pdf" target="_blank">Renewal of Certificate of Experience</a>
                <a className='links' href="https://www.caas.gov.sg/docs/default-source/pdf/caas-fc07-application-for-private-pilot-licence-r6_oct18.pdf" target="_blank">Application Form for PPL</a>
                <a className='links' href="https://www.aviationweather.gov/adds/tafs/?station_ids=WSSL&std_trans=translated&submit_both=Get+TAFs+and+METARs" target="_blank">METAR and TAF</a>
            </div>
        </div>
    )
}