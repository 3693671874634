import { Link, useLocation } from "react-router-dom";
import Footer from "../components/Footer";
import AdminHeader from "../components/AdminHeader";
import "../css/admin-support-page.css";
import { APPDATA } from "../app-config";
import { useState, useEffect } from "react";
import Switch from "react-switch";





const AdminSupportPage = ({ }) => {
    const { state } = useLocation();
    const [support, setSupport] = useState([])
    // const [loading, setLoading] = useState(true)
    const [reply, setReply] = useState(state.reply)
    const [caseStatus, setCaseStatus] = useState(state.case_status)

    // let date = new Date(state.createdAt)
    // let dateCreated = date.getDate() + "/" + (date.getMonth() + 1) + "/" + date.getFullYear()


    const updateReply = async () => {
        let endpoint = APPDATA.server + "/support/" + state._id;
        let fetchResponse = await fetch(endpoint, {
            method: "POST",
            credentials: "include",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                case_status: caseStatus,
                reply: reply
            })
        })
        let response = await fetchResponse.json()
        if (response.status === 200) {
            alert("Case status updated")
            window.location.href = "/admin/control-panel"
        } else {
            alert("Error updating status")
        }
    }

    const deleteReply = async () => {
        let endpoint = APPDATA.server + "/support/delete/" + state._id;
        let fetchResponse = await fetch(endpoint, {
            method: "POST",
            credentials: "include",
            headers: {
                "Content-Type": "application/json"
            }
        })
        let response = await fetchResponse.json()
        if (response.status === 200) {
            alert("Case deleted")
            window.location.href = "/admin/control-panel"
        } else {
            alert("Error deleting case")
        }

    }
    return (
        <div>
            <AdminHeader selected={"Control Panel"} />
            <div className="aspContainer">
                <div className="aspSecondaryContainer">
                    <h1 className="aspHeader">My Support</h1>
                    <div className="aspInnerContainer">

                            <div className="aspRow">
                                <label className="aspLabel">Case ID:</label>
                                <p className="aspItem">{state._id}</p>
                            </div>
                            <div className="aspRow">
                                <label className="aspLabel">Name:</label>
                                <p className="aspItem">{state.name}</p>
                            </div>
                            <div className="aspRow">
                                <label className="aspLabel">Email:</label>
                                <p className="aspItem">{state.email}</p>
                            </div>
                            {/* <p>Date created: {dateCreated}</p> */}

                            <div className="aspRow">
                                <label className="aspLabel">Status:</label>
                                <p className="aspItem">{state.case_status}</p>
                            </div>

                            <label className="aspLabel">Message:</label>
                            <textarea className="aspMessageItem">{state.message}</textarea>
                    </div>
                    <h2 className="aspSubtitle">Admin Control</h2>
                    <div className="aspRow">
                        <label className="aspLabel">Case Status</label>
                        <select className="aspItem" value={caseStatus} onChange={(e) => setCaseStatus(e.target.value)} >
                            <option value="open">Open</option>
                            <option value="pending">Pending</option>
                            <option value="resolved">Resolved</option>
                        </select>
                    </div>
                    
                    <div className="aspSupportRemarks">
                        <label className="aspLabel" style={{ textAlign: "left", width:"100%" }}>Support Message</label>
                        <p className="aspDescription">This message will be emailed to the user</p>
                        <textarea className="supportText" value={reply} onChange={(e) => setReply(e.target.value)} ></textarea>
                    </div>

                    

                    <div className="aspButtonContainer" style={{display:"flex", alignItems:"center",flexDirection:"row", gap: "10px"}}>
                        <button className="aspResolveButton" onClick={() => updateReply()} >Save and Update</button>
                        <button className="aspResolveButton" style={{backgroundColor:"red", color:"white"}} onClick={() => deleteReply()} >Delete Case</button>
                    </div>
                </div>
            </div>

            <Footer />
        </div>
    )
}


export default AdminSupportPage;