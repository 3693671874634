import AdminHeader from "../components/AdminHeader";
import Footer from "../components/Footer";
import { useState, useEffect } from "react";
import { json, Link, useLocation } from "react-router-dom";

import "../css/admin-booking-details-page.css"
import { APPDATA } from "../app-config";

const AdminBookingDetailsPage = ({ }) => {

    const { state } = useLocation();

    // alert(state.statistics_status)


    const [aircraft, setAircraft] = useState(state.aircraft_type)
    const [flightType, setFlightType] = useState(state.flight_type)
    const [flightDate, setFlightDate] = useState(state.flight_date)
    const [startTime, setStartTime] = useState(state.start_time)
    const [endTime, setEndTime] = useState(state.end_time)
    const [remarks, setRemarks] = useState(state.booking_remarks)
    const [paymentFee, setPaymentFee] = useState(state.payment_fee)

    const [instructors, setInstructors] = useState([])
    const [examiners, setExaminers] = useState([])


    const [selectedInstructor, setSelectedInstructor] = useState(state.flight_instructor ? state.flight_instructor : "Not Required")
    const [selectedExaminer, setSelectedExaminer] = useState(state.flight_examiner ? state.flight_examiner : "Not Required")

    const [selectedInstructorData, setSelectedInstructorData] = useState()
    const [selectedExaminerData, setSelectedExaminerData] = useState()

    const [bookingStatus, setBookingStatus] = useState(state.booking_status)
    const [paymentStatus, setPaymentStatus] = useState(state.payment_status)

    const [memberDetails, setMemberDetails] = useState()

    const [fleetData, setFleetData] = useState()


    // declare details
    const [actualStartTime, setActualStartTime] = useState(state.actual_start_time)
    const [actualEndTime, setActualEndTime] = useState(state.actual_end_time)
    const [actualDistance, setActualDistance] = useState(state.distance)

    const [memberFetch, setMemberFetch] = useState(false)

    const loadStaff = async () => {
        let endpoint = APPDATA.server + "/rsfc/staff"
        let fetchResponse = await fetch(endpoint, {
            method: "GET",
            credentials: "include",
            headers: {
                "Content-Type": "application/json",
            }
        })
        let response = await fetchResponse.json()
        if (response.status === 403) {
            window.location.href = "/account"
        } else if (response.status === 401) {
            window.location.href = "/account"
        } else {
            console.log(response)
            setInstructors(response.data.instructors)
            setExaminers(response.data.examiners)
        }
    }

    const loadMemberDetails = async () => {
        let endpoint = APPDATA.server + "/members/" + state.user_id;
        let fetchResponse = await fetch(endpoint, {
            method: "GET",
            credentials: "include",
            headers: {
                "Content-Type": "application/json",
            }
        })
        let response = await fetchResponse.json()
        if (response.status === 403) {
            window.location.href = "/account"
        } else if (response.status === 401) {
            window.location.href = "/account"
        } else {
            console.log("Detail", response)
            setMemberDetails(response.data)
            setMemberFetch(true)
        }
    }


    const fetchFleet = async () => {
        let endpoint = APPDATA.server + "/fleet/"
        let fetchResponse = await fetch(endpoint, {
            method: "GET",
            credentials: "include",
            headers: {
                "Content-Type": "application/json",
            }
        })
        let response = await fetchResponse.json()
        setFleetData(response.data)

    }

    useEffect(() => {
        loadMemberDetails()
        loadStaff()
        fetchFleet()
    }, [])


    useEffect(() => {
        let instructor = state.flight_instructor
        let examiner = state.flight_examiner
        // alert(`Here,${instructor},${examiner}`)
        let instructorIndex = NaN
        let examinerIndex = NaN
        for (let index = 0; index < instructors.length; index++) {
            const element = instructors[index];
            if (element.first_name + " " + element.last_name == instructor) {
                instructorIndex = index
            }
        }
        for (let index = 0; index < examiners.length; index++) {
            const element = examiners[index];
            if (element.first_name + " " + element.last_name == examiner) {
                examinerIndex = index
            }
        }

        if (instructor === "Not Required" || instructor === "not-required" || instructor === "") {
            instructorIndex = "Not Required"
        }
        if (examiner === "Not Required" || examiner === "not-required" || examiner === "") {
            examinerIndex = "Not Required"
        }


        setSelectedInstructorData(instructors[instructorIndex])
        setSelectedExaminerData(examiners[examinerIndex])
        setSelectedInstructor(instructorIndex)
        setSelectedExaminer(examinerIndex)
    }, [instructors, examiners, state.flight_instructor, state.flight_examiner])



    const updateBooking = async () => {
        console.log(selectedExaminer)
        console.log(selectedInstructor)
        let endpoint = APPDATA.server + "/booking/update/" + state._id;
        let fetchResponse = await fetch(endpoint, {
            method: "POST",
            credentials: "include",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                aircraft_type: aircraft,
                flight_type: flightType,
                flight_date: flightDate,
                start_time: startTime,
                end_time: endTime,
                booking_remarks: remarks,
                payment_fee: paymentFee,
                flight_instructor: selectedInstructorData,
                flight_examiner: selectedExaminerData,
                booking_status: bookingStatus,
                payment_status: paymentStatus,
                actual_start_time: actualStartTime,
                actual_end_time: actualEndTime,
                distance: actualDistance,
                user_id: state.user_id,
                statistics_status: state.statistics_status
            })
        })
        let response = await fetchResponse.json()
        if (response.status === 200) {
            alert("Booking updated successfully")
            window.location.href = "/admin/bookings"
        } else if (response.status === 403) {
            window.location.href = "/account"
        } else if (response.status === 401) {
            window.location.href = "/account"
        } else {
            alert("Error updating booking")
        }
    }


    const deleteFlight = async () => {
        let endpoint = APPDATA.server + "/booking/" + state._id;
        let fetchResponse = await fetch(endpoint, {
            method: "DELETE",
            credentials: "include",
            headers: {
                "Content-Type": "application/json"
            }
        })
        let response = await fetchResponse.json()
        if (response.status === 200) {
            alert("Booking deleted successfully")
            window.location.href = "/admin/bookings"
        } else {
            alert("Error deleting booking")
        }
    }


    return (
        <div>
            <AdminHeader selected={"Bookings"} />
            <div className="abdpContainer">
                <div className="abdpInnerContainer">
                    <div className="adbpSplit" style={{ alignItems: 'center' }}>
                        <h1 className="abdpTitle">Booking Details</h1>
                        <Link id="cancelButton" to={"/admin/bookings"}>close</Link>
                    </div>

                    <div className="adbpSplit">
                        <div className="adbpSplitLeft">
                            <h2 className="abdpSubtitle">Pilot Details</h2>
                            <div className="abdpDetails">
                                {memberDetails?<>
                                    <p className="abdpName">{memberDetails.first_name} {memberDetails.last_name} </p>
                                    <p className="abdpLicense">{memberDetails.license_type} (#{memberDetails.license_number})</p>
                                    <p className="abdpStats">{memberDetails.flights} Cycles | {memberDetails.flight_hours} Flying Hours</p>
                                    <p className="abdpStats">Certificate of Expiry: {memberDetails.certificate_of_expiry}</p>
                                    <p className="abdpStats">Next Medical: {memberDetails.medical_date}</p>
                                </>:<>
                                    <p className="abdpName">Unable to find user</p>
                                    {memberFetch?<p>User is deleted, you may want to delete this flight booking</p>:null}
                                </>}
                            </div>

                            <h2 className="abdpSubtitle">Payment Details</h2>
                            <div className="abdpDetails">

                                <div className="abdpSubDetails">
                                    <label className="abdpLabel">Flight Fee</label>
                                    <input className="abdpInput" type="money" value={paymentFee} onChange={(e) => setPaymentFee(e.target.value)} />
                                </div>

                                <div className="abdpSubDetails">
                                    <label className="abdpLabel">Payment Status</label>
                                    <select className="adbpSelection" value={paymentStatus} onChange={(e) => setPaymentStatus(e.target.value)} >
                                        <option selected value="processing">Processing</option>
                                        <option value="requesting-payment">Requesting Payment</option>
                                        <option value="to-check-payment">Check Payment</option>
                                        <option value="paid">Paid</option>
                                        <option value="overdue">Overdue</option>
                                    </select>
                                </div>

                                {/* <div className="abdpButtonContainer">
                                        <button className="abdpButton">Request Payment</button>
                                    </div> */}
                            </div>

                            {state.booking_status === "completed" ? <>
                                <h2 className="abdpSubtitle">Actual Flight Details</h2>
                                <div className="abdpDetails">
                                    <div className="abdpSubDetails">
                                        <label className="abdpLabel">Flight Distance (KM)</label>
                                        <input className="abdpInput" type="money" value={actualDistance} onChange={(e) => setActualDistance(e.target.value)} />
                                    </div>

                                    <div className="abdpSubDetails">
                                        <label className="abdpLabel">Actual Start Time</label>
                                        <input className="abdpInput" type="time" value={actualStartTime} onChange={(e) => setActualStartTime(e.target.value)} />
                                    </div>

                                    <div className="abdpSubDetails">
                                        <label className="abdpLabel">Actual End Time</label>
                                        <input className="abdpInput" type="time" value={actualEndTime} onChange={(e) => setActualEndTime(e.target.value)} />
                                    </div>
                                </div>
                            </> : null}
                        </div>


                        <div className="adbpSplitRight">
                            <h2 className="abdpSubtitle">Flight Details</h2>
                            <div className="abdpDetails">
                                <div className="abdpSubDetails">
                                    <label className="abdpLabel">Aircraft Type</label>
                                    <select className="adbpSelection" value={aircraft} onChange={(e) => setAircraft(e.target.value)} >
                                        {fleetData ? fleetData.map((fleet, index) => {
                                            return <option key={index} value={`${fleet.aircraft_name} (${fleet.aircraft_registration})`}>{fleet.aircraft_name} ({fleet.aircraft_registration})</option>
                                        }) : null}
                                    </select>
                                </div>

                                <div className="abdpSubDetails">
                                    <label className="abdpLabel">Flight Type</label>
                                    <select className="adbpSelection" value={flightType} onChange={(e) => setFlightType(e.target.value)} >
                                        <option selected value="training">Training Flight</option>
                                        <option value="examiniation">Examination Flight</option>
                                        <option value="leisure">Leisure Flight</option>
                                    </select>
                                </div>

                                <div className="abdpSubDetails">
                                    <label className="abdpLabel">Instructor</label>
                                    <select className="adbpSelection" value={selectedInstructor} onChange={(e) => {
                                        setSelectedInstructorData(instructors[e.target.value])
                                        setSelectedInstructor(e.target.value)
                                        console.log(e.target.value)
                                    }} >
                                        <option selected value="not-required">Not Required</option>
                                        {instructors.length > 0 ? instructors.map((instructor, index) => {
                                            return <option value={index}>{instructor.first_name} {instructor.last_name}</option>
                                        }) : null}
                                    </select>
                                </div>

                                <div className="abdpSubDetails">
                                    <label className="abdpLabel">Examiner</label>
                                    <select className="adbpSelection" value={selectedExaminer} onChange={(e) => {
                                        setSelectedExaminerData(examiners[e.target.value])
                                        setSelectedExaminer(e.target.value)
                                        console.log(e.target.value)
                                    }}>
                                        <option selected value="not-required">Not Required</option>
                                        {examiners.length > 0 ? examiners.map((examiner, index) => {
                                            return <option value={index}>{examiner.first_name} {examiner.last_name}</option>
                                        }) : null}
                                    </select>
                                </div>

                                <div className="abdpSubDetails">
                                    <label className="abdpLabel">Booking Status</label>
                                    <select className="adbpSelection" value={bookingStatus} onChange={(e) => setBookingStatus(e.target.value)} >
                                        <option selected value="pending">Pending</option>
                                        <option value="confirmed">Confirmed</option>
                                        <option value="completed">Completed</option>
                                        <option value="cancelled">Cancelled</option>
                                        <option value="rejected">Rejected</option>
                                    </select>
                                </div>

                                <div className="abdpSubDetails">
                                    <label className="abdpLabel">Date</label>
                                    <input className="abdpInput" type="date" value={flightDate} onChange={(e) => setFlightDate(e.target.value)} />
                                </div>
                                <div className="abdpSubDetails">
                                    <label className="abdpLabel">Start Time</label>
                                    <input className="abdpInput" type="time" value={startTime} onChange={(e) => setStartTime(e.target.value)} />
                                </div>
                                <div className="abdpSubDetails">
                                    <label className="abdpLabel">End Time</label>
                                    <input className="abdpInput" type="time" value={endTime} onChange={(e) => setEndTime(e.target.value)} />
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="abdpBookingRemarks">
                        <label className="abdpLabel">Booking Remarks</label>
                        <textarea className="bookingText" value={remarks} onChange={(e) => setRemarks(e.target.value)} ></textarea>
                    </div>
                    <div className="abdpButtonContainer">
                        <div className="abdpButtonContainer1">
                            <button className="abdpDeleteButton" onClick={() => deleteFlight()} >Delete Booking</button>
                            <button className="abdpSaveButton" onClick={() => {
                                updateBooking()
                                console.log("clicked")
                            }}>Save Changes</button>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    );

}

export default AdminBookingDetailsPage;