import Footer from "../components/Footer";
import Header from "../components/Header";
import RSFCChatBot from "../components/RSFCChatBot";
import "../css/experience-page.css"

const ExperiencePage = () => {
    return (
        <div>
            <Header />
            <RSFCChatBot/>
            {/* Header Image container*/}
            <img src='./img/experience1.png' alt="" className="epHeaderImage" />


            {/* Flight Experience */}
            <div className="flyForAnyEvent">
                <div className="epInnerDiv">
                    <h1 className="epTitle">Fly for any event</h1>
                    <p>Not sure if flying is for you? Come experience flying with us and decide if you want to obtain a Private Pilot License for yourself. Give us a call and an experiential flight can be arranged.
                        <br /><br />Seen wedding proposals done by pilots in the air disguised as a technical issue? You can do the same in one of our aircrafts.
                        <br /><br />Flying in our aircraft for photoshoots is also possible. The club is open to any requests for use of our aircrafts. Contact us today for an arrangement for your next big adventure.</p>
                    <h1 className="epTitle">Giving back to the community</h1>
                    <div className="epStory">
                        <div className="story1">
                            <p>The Republic of Singapore Flying Club has conducted charity flights for less privilleged children and have worked with organisations such as Make-A-Wish Foundation to make such events possible.
                                <br /><br /><br />The image on the right shows the story of Ridhwan, a 10-year-old Wish kid who has Pfieffer syndrome.
                                <br /><br /><br />The club is open to help organisations fufil the wishes of their beneficiaties related to aviation to the best of our ability.
                            </p>
                        </div>

                        <div className="epArticle">
                            <img src='./img/experience2.png' alt='' />
                            <p>Wishing Well, Issue 34</p>
                        </div>

                    </div>

                    <div className="epStory2">
                        <img src='./img/experience3.png' alt='' />
                        <p>Another story is about Aisha, a 11-Year-old who has to undergo multiple medical procedures from time to time.
                            <br /><br />The Republic of Singapore Flying Club brought Aisha and her family to fly in our Socata Tampico TB-9C!
                        </p>
                    </div>

                    <div className="epStory3">
                        <p>Introducing Aysiah, she flown on the Tapicao aircraft flown by Captain Lingam. Because of RSFC generosity, she was able to make her flying wish come true.</p>
                        <img src='./img/experience4.png' alt='' />
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    )
}


export default ExperiencePage;

