import { Link, useAsyncError, useLocation, useNavigate } from "react-router-dom";
import AdminHeader from "../components/AdminHeader";
import EventHeader from "../components/AdminEventHeader";
import Footer from "../components/Footer";
import { APPDATA } from "../app-config";
import { useState, useEffect } from "react";
import "../css/admin-event-signup-page.css";
import AdminEventHeader from "../components/AdminEventHeader";
import { IoClose } from "react-icons/io5";


const AdminEventSignupPage = () => {
    const {state} = useLocation();
    console.log(state)
    const [allAnswers, setAllAnswers] = useState([])
    const [questions, setQuestions] = useState("")
    const [eventData, setEventData] = useState()

    return(
    
    <div className="test">
        <AdminHeader/>
        <h1>Event signup details</h1>
                <div className="aespContainer">
                    <div className="aespInnerContainerLeft">
                        <p className="aespItemTitle">{0}</p>
                    </div>
                </div>
            
        
    </div>
    )
    
}



export default AdminEventSignupPage