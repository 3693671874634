import Header from "../components/Header";
import Footer from "../components/Footer";
import EventHeader from "../components/AdminEventHeader";
import { Link,useLocation, useNavigate } from "react-router-dom";
import { useState, useEffect, useSyncExternalStore } from "react";
import "../css/event-detail-page.css"
import { APPDATA } from "../app-config";
import MemberHeader from "../components/MemberHeader";

const EventDetailPage = () => {
    const {state} = useLocation();
    const [cookie, setCookie] = useState(window.sessionStorage.getItem("accessToken"))

    const [title, setTitle] = useState()
    const [eventID, setEventID] = useState()
    const [eventStartDate, setEventStartDate] = useState()
    const [eventEndDate, setEventEndDate] = useState()
    const [price, setPrice] = useState()
    const [type, setType] = useState()
    const [participants, setParticipants] = useState()
    const [description, setDescription] = useState()
    const [uploadFiles, setUploadFiles] = useState()
    const [eventImage, setEventImage] = useState("")
    const [imageID, setImageID] = useState()

    const[events, setEvents] = useState([])

    

const loadEvent = async () => {
    let eventID = window.location.pathname.split("/")[3]
    let endpoint = APPDATA.server + "/events/" + eventID
        let formData = new FormData()
        console.log(endpoint)

    let fetchResponse = await fetch(endpoint,{
        method: "GET",
        credentials: "include",
        headers: {
            "Content-Type": "application/json",
            },
            
        })
    let response = await fetchResponse.json()
    if (response.status === 200){
        console.log(response)
        setEvents(response.data)
        setTitle(response.data.event_title)
        setEventID(window.location.pathname.split("/")[3])
        setEventStartDate(response.data.event_start_date)
        setEventEndDate(response.data.event_end_date)
        setPrice(response.data.event_price)
        setType(response.data.event_type)
        setParticipants(response.data.event_participants)
        setDescription(response.data.event_description)
        setEventImage(response.data.event_image)

    }
    
    
}
const navigate = useNavigate()
useEffect(() => {
    loadEvent()
}, [])

    return ( 
        
        <div>
            {JSON.parse(window.sessionStorage.getItem("accessToken"))?<MemberHeader selected={"Community"}/>:<div  style={{marginBottom:"100px"}}><Header/></div>}
            
            
            <div className="edpContainer">
                <div className="edpInnerContainer">
                    <div className="edpHeader">
                        <h1 className="edpTitle">Event Details</h1>
                        <Link id="newEventButton" to={cookie?"/members/community":"/events"}>cancel</Link>
                    </div>
                        
                        
                        
                        <div className="edpImageContainer">
                            {/* <p>{`${APPDATA.server}${eventImage.toString().replace("public",'')}`}</p> */}
                            <img src= {`${APPDATA.server}${eventImage.toString().replace("public",'')}`} id='eventImage' className="edpEventImage" alt=""/>
                            
                        </div>
                        <div className="edpSplitContainer">
                            <div className="edpSplit">
                                
                                    <div className="edpFieldContainer">
                                        <label className="edpLabel">Title</label>
                                        <label className="edpInput"> {title} </label>  
                                    </div>
                                    <div className="edpFieldContainer">
                                        <label className="edpLabel">Start Date</label>
                                        <label className="edpInput" type="date"> {eventStartDate}</label>
                                    </div>
                                    <div className="edpFieldContainer">
                                        <label className="edpLabel">End Date</label>
                                        <label className="edpInput" type="date">{eventEndDate} </label>
                                    </div>
                                    <div className="edpFieldContainer">
                                        <label className="edpLabel">Price</label>
                                        <label className="edpInput"> ${price}  </label>
                                    </div>
                            </div>
                            <div className="edpSplit2">
                                <div className="edpFieldContainer">
                                    <label className="edpLabel">Maximum participants</label>
                                    <label className="edpInput"> {participants} </label>
                                </div>
                                <div className="edpFieldContainer">
                                    <label className="edpLabel">Type</label>
                                    <label className="edpTypeSelection"> {type} </label>
                                </div>     
                                <div className="edpFieldContainer">
                                    <label className="edpLabel">Description</label>
                                    <label className="eventDescription">{description} </label>
                                </div>
                                  
                                  
                            </div>
                            
                        </div>
                        
                        {JSON.parse(window.sessionStorage.getItem("accessToken"))?<div className="signupButtonContainer">
                            <button className="eventEditButton" onClick={() => {
                                if (window.location.pathname.split("/")[3]){
                                    navigate("/members/event-details/" + window.location.pathname.split("/")[3] + "/signup" , { state: events })
                                }
                               
                                }} >Sign Up</button>
                        </div>:null}

                       
                        
                </div>
            </div>
            <Footer/>
        </div>
        
    );
}



export default EventDetailPage;