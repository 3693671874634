import Footer from "../components/Footer";
import MemberHeader from "../components/MemberHeader";

import "../css/member-flight-log-page.css"

import { useState,useEffect } from "react";
import {APPDATA} from "../app-config"
import { Link, useNavigate } from "react-router-dom";

const MemberFlightLogPage = () => {
    const [flights, setFlights] = useState()
    const [loading, setLoading] = useState(true)
    const [windowWidth, setWindowWidth] = useState(window.innerWidth)

    const navigate = useNavigate();

    const fetchUserFlights = async() =>{
        let endpoint = APPDATA.server+"/booking/"+JSON.parse(window.sessionStorage.getItem("accessToken"))._id
        console.log(endpoint)
        let fetchResponse = await fetch(endpoint,{
            method: "GET",
            credentials: "include",
            headers: {
                "Content-Type": "application/json",
            }

        })
        let response = await fetchResponse.json()
        if (response.status === 403) {
            window.location.href = "/account"
        }else if (response.status === 401) {
            window.location.href = "/account"
        }else{
            console.log(response)

            let temp_flights = []
            for (let i = 0; i < response.data.length; i++) {
                console.log(response.data[i])
                if (response.data[i].booking_status === "completed"&&response.data[i].payment_status === "paid") {
                    temp_flights.push(response.data[i])
                }
            }
            console.log(temp_flights)
            setFlights(temp_flights)
            setLoading(false)
        }
    }

    useEffect(() => {
        fetchUserFlights()
    }, [])
    window.addEventListener("resize", () => {
        setWindowWidth(window.innerWidth)
    })

    return ( 
        <div>
            <MemberHeader selected={"Log"}/>
            <div className="mhpContainer">
                <div className="mhpInnerContainer">
                    <h1 className="mhpTitle">Flight Logs</h1>
                    {/* Require Confirmation */}

                    {windowWidth > 1200 ? <>
                        <div className="abpTable">
                            <div className="abpTableHeader">
                                <p className="abpTableHeaderItem" style={{ width: "10%" }}>S/N</p>
                                <p className="abpTableHeaderItem" style={{ width: "30%" }}>Aircraft Type</p>
                                <p className="abpTableHeaderItem" style={{ width: "30%" }}>Flight Date</p>
                                <p className="abpTableHeaderItem" style={{ width: "30%" }}>Flying Hours</p>
                                <p className="abpTableHeaderItem" style={{ width: "30%" }}>Flight Type</p>
                            </div>
                            {loading ? <p>Loading...</p> : flights.map((booking,index) => {
                                if (booking.booking_status === "completed" && booking.payment_status === "paid") {
                                    let actual_start_time = booking.actual_start_time
                                    let actual_end_time = booking.actual_end_time

                                    {/* calculate the duration */}
                                    let actual_start_time_hours = parseInt(actual_start_time.split(":")[0])
                                    let actual_start_time_minutes = parseInt(actual_start_time.split(":")[1])
                                    let actual_end_time_hours = parseInt(actual_end_time.split(":")[0])
                                    let actual_end_time_minutes = parseInt(actual_end_time.split(":")[1])

                                    let duration_hours = actual_end_time_hours - actual_start_time_hours
                                    let duration_minutes = actual_end_time_minutes - actual_start_time_minutes

                                    if (duration_minutes < 0) {
                                        duration_hours -= 1
                                        duration_minutes += 60
                                    }

                                    let duration = duration_hours + " Hours " + duration_minutes + " Mins"

                                    return (
                                        <div className="abpTableRow">
                                            <p className="abpTableItem" style={{ width: "10%" }}>{index+1}</p>
                                            <p className="abpTableItem" style={{ width: "30%" }}>{booking.aircraft_type}</p>
                                            <p className="abpTableItem" style={{ width: "30%" }}>{booking.flight_date}</p>
                                            <p className="abpTableItem" style={{ width: "30%" }}>{duration}</p>
                                            <p className="abpTableItem" style={{ width: "30%" }}>{booking.flight_type}</p>
                                        </div>
                                    )
                                }
                            })}
                        </div>
                    </> : <>
                        <div className="abpSmallTableContainer">
                            {loading ? <p>Loading...</p> : flights.map((booking,index) => {
                                if (booking.booking_status === "completed" && booking.payment_status === "paid") {
                                    let actual_start_time = booking.actual_start_time
                                    let actual_end_time = booking.actual_end_time

                                    {/* calculate the duration */}
                                    let actual_start_time_hours = parseInt(actual_start_time.split(":")[0])
                                    let actual_start_time_minutes = parseInt(actual_start_time.split(":")[1])
                                    let actual_end_time_hours = parseInt(actual_end_time.split(":")[0])
                                    let actual_end_time_minutes = parseInt(actual_end_time.split(":")[1])

                                    let duration_hours = actual_end_time_hours - actual_start_time_hours
                                    let duration_minutes = actual_end_time_minutes - actual_start_time_minutes

                                    if (duration_minutes < 0) {
                                        duration_hours -= 1
                                        duration_minutes += 60
                                    }

                                    let duration = duration_hours + " Hours " + duration_minutes + " Mins"
                                    return (
                                        <div className="abpSmallTable">
                                            <div className="abpSmallTableRow">
                                                <p className="abpSmallTableItem" >S/N</p>
                                                <p className="abpSmallTableItem" >{index+1}</p>
                                            </div>
                                            <div className="abpSmallTableRow">
                                                <p className="abpSmallTableItem" >Aircraft Type</p>
                                                <p className="abpSmallTableItem" >{booking.aircraft_type}</p>
                                            </div>
                                            <div className="abpSmallTableRow">
                                                <p className="abpSmallTableItem" >Flight Date</p>
                                                <p className="abpSmallTableItem" >{booking.flight_date}</p>
                                            </div>
                                            <div className="abpSmallTableRow">
                                                <p className="abpSmallTableItem" >Flying Hours</p>
                                                <p className="abpSmallTableItem" >{duration}</p>
                                            </div>
                                            <div className="abpSmallTableRow">
                                                <p className="abpSmallTableItem" >Flight Type</p>
                                                <p className="abpSmallTableItem" >{booking.flight_type}</p>
                                            </div>
                                        </div>
                                    )
                                }
                            })}
                        </div>
                    </>}
                </div>
            </div>
            <Footer/>
        </div>
     );
}
 
export default MemberFlightLogPage;