import { createChatBotMessage } from 'react-chatbot-kit';
import Options from "../chat-bot/Options.jsx";
import Button from "../chat-bot/Button.jsx";
import Avatar from "../chat-bot/Avatar.jsx"
import React from "react";

const config = {
  initialMessages: [createChatBotMessage(`Welcome to RSFC! My name is Chocks, how can I help you?`,{widget:'options'})],
  botName: "Chocks",
  widgets: [
    {
      widgetName: "options",
      widgetFunc: (props) => <Options {...props} />,
    }
  ],

  customStyles: {
    botMessageBox: {
      backgroundColor: '#376B7E',
    },
    chatButton: {
      backgroundColor: '#2898ec',
    },
    
  },
  customComponents: {
    // Replaces the default header
   header: () => <div className='react-chatbot-kit-chat-header'> <Avatar></Avatar><div><p  style={{textAlign:"left",margin:0,fontSize:16,fontWeight:"bold"}}>Chocks</p><p style={{textAlign:"left",margin:0,fontSize:15, fontWeight:200}}>Online</p></div></div>,
   button: () => (props) => <Button {...props} />,
   botAvatar: () => (props) => <Avatar {...props} />,
  
 },
};

export default config;