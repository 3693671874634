import "../css/member-event-signup-page.css"
import { APPDATA } from "../app-config";
import { Link, useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";
import Header from "../components/Header";
import Footer from "../components/Footer";
import MemberHeader from "../components/MemberHeader";

import { BsChevronLeft, BsChevronRight } from "react-icons/bs"

const MemberEventSignupPage = () => {
    const [questions, setQuestions] = useState("")
    const [eventData, setEventData] = useState()
    const [answers, setAnswers] = useState()
    const [allAnswers, setAllAnswers] = useState([])
    const [selectedAnswer, setSelectedAnswer] = useState(0)


    useEffect(() => {
        fetchEventData()
    }, [])

    const fetchEventData = async () => {
        let event_id = window.location.pathname.split("/")[3]
        if (event_id) {
            let endpoint = APPDATA.server + "/events/" + event_id

            let fetchResponse = await fetch(endpoint, {
                method: "GET",
                credentials: "include",
                headers: {
                    "Content-Type": "application/json"
                }
            })
            let response = await fetchResponse.json()
            if (response.status === 200) {
                setEventData(response.data)
                setQuestions(response.data.event_questions)
                let answer = []
                for (let i = 0; i < response.data.event_questions.length; i++) {
                    answer.push("")
                }
                setAnswers(answer)
            }
        }


    }

    const uploadEventSignups = async () => {
        
        let event_id = window.location.pathname.split("/")[3]


            let endpoint = APPDATA.server + "/events/event-signup/" + event_id
            let data = {
                event_answers: allAnswers
            }
            let fetchResponse = await fetch(endpoint, {
                method: "POST",
                credentials: "include",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(data)
            })
            let response = await fetchResponse.json()

            if (response.status === 200) {
                window.location.href = "/members/community"
                alert("Event application submitted successfully")
                window.location.href = "/members/community"
            } else {
                alert("Event application failed")
            }
    }



    // useEffect(() => {
    //     if (allAnswers.length){
    //         let current = allAnswers[selectedAnswer-1].split("||")
    //         for (let i = 0; i < current.length; i++) {
    //             document.getElementById("question" + i).value = current[i]
    //         }
    //     }

    // }, [selectedAnswer])



    return (<>
        {eventData ? <div>
            <MemberHeader selected={"Community"} />
            <div className="mespContainer">
                <div className="mespInnerContainer">
                    <div className="mespHeader">
                        <h1 className="mespTitle">Sign Up</h1>
                        <Link id="newEventButton" to={`/events/event-details/${eventData._id}`}>cancel</Link>


                    </div>
                    <p>Sign up if you would like to join: {eventData.event_title}</p>
                    {/* <p>{JSON.stringify(allAnswers)}</p>
                    <p>{JSON.stringify(questions)}</p> */}
                    <div className="mespSplit">
                        <h2>Questions</h2>
                        <div className="mespQuestionControl">
                            <BsChevronLeft className="selectionButton" size={30} onClick={() => {
                                if (selectedAnswer > 0) {
                                    // setSelectedAnswer(selectedAnswer - 1)
                                    let temp = selectedAnswer

                                    for (let i = 0; i < questions.length; i++) {
                                        console.log(allAnswers[temp - 1].split("||")[i])
                                        try {
                                            document.getElementById("question" + i).value = allAnswers[temp - 1].split("||")[i]
                                        }
                                        catch {

                                        }
                                    }

                                    setSelectedAnswer(selectedAnswer - 1)
                                }
                            }} />
                            <p>Response #{selectedAnswer + 1}</p>
                            <BsChevronRight className="selectionButton" size={30} onClick={() => {
                                if (selectedAnswer < allAnswers.length) {
                                    if (selectedAnswer === allAnswers.length - 1) {
                                        for (let i = 0; i < questions.length; i++) {
                                            try {
                                                document.getElementById("question" + i).value = ""
                                            }
                                            catch {

                                            }
                                            setSelectedAnswer(selectedAnswer + 1)
                                        }
                                    } else {
                                        let temp = selectedAnswer
                                        for (let i = 0; i < questions.length; i++) {
                                            try {
                                                document.getElementById("question" + i).value = allAnswers[temp + 1].split("||")[i]
                                            }
                                            catch {

                                            }
                                        }
                                        setSelectedAnswer(selectedAnswer + 1)
                                    }

                                }
                            }} />
                        </div>
                    </div>

                    <div className="mespQuestions">
                        {questions.map((question, index) => {
                            return (
                                <div className="mespQuestion">
                                    <p>{question}</p>
                                    <input type="text" id={`question${index}`} />
                                </div>
                            )
                        })}
                    </div>

                    <div className="mespButtonContainer">
                        <button className="eventEditButton" onClick={() => {
                            let flag = false
                            let temp = []
                            for (let i = 0; i < questions.length; i++) {
                                let answer = document.getElementById(`question${i}`).value
                                if (answer === "") {
                                    flag = true
                                }
                                document.getElementById(`question${i}`).value = ""
                                temp.push(answer)
                            }
                            if (!flag) {
                                setAnswers(temp.join("||"))
                                let allTemp = allAnswers
                                allTemp[selectedAnswer] = temp.join("||")
                                setAllAnswers(allTemp)
                                setSelectedAnswer(allTemp.length)
                            }
                            
                        }}>{selectedAnswer === allAnswers.length ? "Add Participant" : "Save Changes"}</button>
                        <button className="eventEditButton" onClick={() =>{
                            let flag = false
                            let temp = []
                            for (let i = 0; i < questions.length; i++) {
                                let answer = document.getElementById(`question${i}`).value
                                if (answer === "") {
                                    flag = true
                                }
                                document.getElementById(`question${i}`).value = ""
                                temp.push(answer)
                            }
                            if (!flag) {
                                setAnswers(temp.join("||"))
                                let allTemp = allAnswers
                                allTemp[selectedAnswer] = temp.join("||")
                                setAllAnswers(allTemp)
                            }
                             uploadEventSignups()
                        }}>Submit Application</button>
                    </div>
                </div>
            </div>

            <Footer />
        </div> : null}
    </>
    )

}

export default MemberEventSignupPage;