import Footer from "../components/Footer";
import MemberHeader from "../components/MemberHeader";

import { useState, useEffect } from "react";
import "../css/member-home-page.css"
import { APPDATA } from "../app-config";

const MemberHomePage = () => {
    const [username, setUsername] = useState("")
    const [name, setName] = useState("")
    const [email, setEmail] = useState("")
    const [message, setMessage] = useState("")


    useEffect(() => {
        let userData = window.sessionStorage.getItem("accessToken")
        if (userData) {
            let user = JSON.parse(userData)
            setUsername(user.salutation)
            console.log(Object.keys(user))
        }
        
    }, [])

    const submitSupport = async () => {
        if (email && name && message&&email.includes("@")&& email.indexOf("@")<email.lastIndexOf(".")){
          let endpoint = APPDATA.server + "/support/new"
          console.log("hello",{
            name: name,
            email: email,
            message: message,
          })
          const fetchResponse = await fetch(endpoint, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              "Accept": "application/json"
            },
            body: JSON.stringify({
              name: name,
              email: email,
              message: message,
            })
          })
          
          const data = await fetchResponse.json()
          if (data.status === 200) {
            alert("Your Message has been received")
      
          } else {
            alert("Unable to send message to support")
          }
        }else {
          alert("Please fill in the necessary fields")
        }
       
      }
    

    return ( 
        <div>
            <MemberHeader selected={"Home"}/>
            <div className="mhpContainer">
                <div className="mhpInnerContainer">
                    <h1 className="mhpTitle">Hello, Welcome Back {username}!</h1>
                    
                </div>
            </div>

            <div className="mhpSection">
                <div className="mhpInnerSection">
                    <img className="mhpImageHeader" src='./img/membershipHome1.png' alt="" />
                    <div className="mhpFBText">
                        <h1>Flight Booking</h1>
                        <p>Feeling the excitement to fly again? Book your flights today!</p>
                        <div className="mhpButton">
                            <a href="/members/booking">Book Flight</a>
                        </div>
                    </div>
                </div>
            </div>

            <div className="mhpSection">
                <div className="mhpInnerSection">
                    <img  className="mhpImageHeader" src='./img/membershipHome2.png' alt="" />
                    <div className="mhpFBText">
                        <h1>Flight Logs</h1>
                        <p>View all your flights you have flown. A concise view of your progress and images taken during the flight!</p>
                        <div className="mhpButton">
                            <a href="/members/flight-log">Flight Logs</a>
                        </div>
                    </div>
                </div>
            </div>

            <div className="mhpSection">
                <div className="mhpInnerSection">
                    <img className="mhpImageHeader" src='./img/membershipHome3.png' alt="" />
                    <div className="mhpFBText">
                        <h1>Community</h1>
                        <p>Hear from the club on their flying experience, see their flights as well!</p>
                        <div className="mhpButton">
                            <a href="/members/community">Visit Community</a>
                        </div>
                    </div>
                </div>
            </div>

            <div className="mhpSection">
            <div className="mhpInnerSection">
                    <img src='./img/membershipHome4.png' alt="" />
                    <div className="mhpFBText">
                        <h1>Your Profile</h1>
                        <p>Manage your profile visibility and profile. You may choose to edit your name, contact details etc.</p>
                        <div className="mhpButton">
                            <a href="/members/profile/edit">View Profile</a>
                        </div>
                    </div>
                </div>
            </div>

            <div className="questionForms">
            <h1>Members support</h1>
            <div className="forms">
              <h6>Have any questions? Submit a support ticket and our admin will get back to you!</h6>
              <div className="innerForms">
                <p>name</p>
                <input type="text" onChange={(e)=>setName(e.target.value)}/>
                <p>email</p>
                <input type="text" onChange={(e)=>setEmail(e.target.value)}/>
                <p>message</p>
                <textarea name="" id="" cols="30" rows="10" onChange={(e)=>setMessage(e.target.value)} ></textarea>
                <div className="buttonContainer" onClick={submitSupport}  >
                    <a >Send Message</a>
                  </div>
              </div>
            </div>
          </div>

            <Footer/>
        </div>
     );
}
 
export default MemberHomePage;