import Footer from "../components/Footer";
import Header from "../components/Header";
import "../css/about-us.css"
import { AiFillAlipaySquare } from "react-icons/ai";
import React, { useState } from "react";

import { IoSpeedometer, IoPeopleSharp } from "react-icons/io5";
import { TbChevronDown } from 'react-icons/tb';
import { GiPathDistance } from "react-icons/gi";
import RSFCChatBot from "../components/RSFCChatBot";

export default function AboutUsPage() {
    const [stories, setStories] = useState([
        { title: "The Royal Singapore Flying Club at Kallang", caption: "Read more about the origins of RSFC", link: "https://thelongnwindingroad.wordpress.com/2015/01/20/the-royal-singapore-flying-club-at-kallang/", author: "The Long and Winding Road" }
    ])

    let people = [
        { role: "President", names: ["Lingam Paspathy"] },
        { role: "Hon. Secretary", names: ["Toh Hui Ping"] },
        { role: "Hon. Treasurer", names: ["Eric Ong Tiong Tai"] },
        { role: "Committee Members", names: ["Peter Liew", "Shoban Ramakrishnan", "Ngui Le Chen"] },
        { role: "Members, Hon. Auditors", names: ["Erizemen Juseri", "Cho Wai Cheong"] },
    ]


    return (
        <div>
            {/* Header Image */}
            <Header />
            <RSFCChatBot />
            <div className="empty" id="aboutus"></div>
            <div className="Aucontainer">
                <img src="/img/aboutus1.png" alt="" />
                <div className="auInnerContainer">
                    <h1 className="Autitle" style={{ paddingLeft: "0px" }}>About Us</h1>
                    <p className="Auparagraph">Singapore's Oldest Flying Club, formed in 1928. The Republic of Singapore Flying Club has a deep history with many aviators past and present. It's objective is to make flying affordable, allowing more people to fulfil their dreams of being a pilot. Pilots in RSFC fly recreationally, as well as to practise to obtain their Private Pilot License.</p>
                </div>

                {/* Contact Us */}
                <div className="empty" id="contactus"></div>
                <div className="Aucontainer" style={{ marginBottom: "100px" }}>
                    <div className="auInnerContainer">
                        <h1 className="Autitle" style={{ paddingLeft: "0px" }}>Contact Us</h1>
                        <div className="Contactus">
                            <div className="innerContactUs">
                                <h2 className="Cutitle">Location</h2>
                                <p>700 W Camp Rd, Unit 05-01, Singapore <br></br> 797649</p>
                                <h2 className="Cutitle">Hours</h2>
                                <p>Monday, Wednesday, Friday-Sunday: <br></br>9am-5pm <br></br> Tuesday, Thursday: Closed</p>
                                <h2 className="Cutitle">Contact</h2>
                                <p>(+65) 6481 0200 <br></br> manager@singaporeflyingclub.com</p>
                            </div>
                            <iframe id="auMap" src="https://www.google.com/maps/embed?pb=!1m12!1m8!1m3!1d7977.19874695056!2d103.8616226!3d1.4163302!3m2!1i1024!2i768!4f13.1!2m1!1ssingapore%20flying%20club!5e0!3m2!1sen!2ssg!4v1668088480045!5m2!1sen!2ssg" width={"600px"} height={"450px"} allowFullScreen="" loading="lazy" referrerPolicy="no-referrer-when-downgrade"></iframe>
                        </div>
                    </div>
                </div>

                {/* Our People */}
                <div className="auInnerContainer">
                    <div className="empty" id="ourpeople"></div>
                    <div className="empty"></div>
                    <h1 className="Autitle" style={{ paddingLeft: "0px" }} >Our People</h1>
                </div>
                <div className="wrapper" style={{ display: "flex", justifyContent: "center", gap: 30, flexDirection: "row", flexWrap: "wrap" }}>
                    {people.map((data, index) => {
                        return (
                            <div style={{ textAlign: "center" }}>
                                <p style={{ fontWeight: "bold", fontSize: 16 }}>{data.role}</p>
                                {data.names.map((name, index_name) => {
                                    return (
                                        <p>{name}</p>
                                    )
                                })}
                            </div>
                        )
                    })}
                </div>

                {/* Our Fleet */}
                <div className="auInnerContainer">
                    <div className="empty" id="fleet"></div>
                    <div className="empty"></div>
                    <h1 className="Autitle" style={{ paddingLeft: "0px" }} >Our Fleet</h1>
                </div>

                <div className="splitDiv">
                    <img src="./img/home3.png" alt="" />
                    <div className="splitInnerDiv" style={{ flexDirection: "column", alignItems: "center", display: "flex" }}>
                        <h3 className="aircraftName">Tampico TB-9</h3>
                        <div className="splitTextDiv">
                            <div className="innerSplitDiv">
                                <GiPathDistance size={80} color={'black'} />
                                <p>Max Range 556 NM</p>
                            </div>
                            <div className="innerSplitDiv">
                                <IoSpeedometer size={80} color={"black"} />
                                <p>Top Speed 165 knts</p>
                            </div>
                            <div className="innerSplitDiv">
                                <IoPeopleSharp size={80} color={"black"} />
                                <p>Passengers 2-4 pax</p>
                            </div>
                        </div>
                        <div className="Autext4">
                            <p>The Tampico TB-9 is the entry-level model in Aerospatiale's Caribbean series of piston singles which includes the TB-10 Tobago, TB-20 Trinidad, and turbo-charged TB-21 Trinidad TC.</p>
                        </div>

                    </div>
                </div>

                <div className="splitDiv" id="auFlipgrid">
                    <div className="splitInnerDiv" style={{ flexDirection: "column", alignItems: "center", display: "flex" }}>
                        <h3 className="aircraftName">Cessna 172</h3>
                        <div className="splitTextDiv">
                            <div className="innerSplitDiv">
                                <GiPathDistance size={80} color={'black'} />
                                <p>Max Range 435 NM</p>
                            </div>
                            <div className="innerSplitDiv">
                                <IoSpeedometer size={80} color={"black"} />
                                <p>Top Speed 122 knts</p>
                            </div>
                            <div className="innerSplitDiv">
                                <IoPeopleSharp size={80} color={"black"} />
                                <p>Passengers 2-3 pax</p>
                            </div>
                        </div>
                        <div className="Autext4">
                            <p>The Cessna 172 Skyhawk is an American four-seat, single-engine, high wing, fixed-wing aircraft made by the Cessna Aircraft Company.</p>
                        </div>
                    </div>
                    <img src="./img/aboutus5.png" alt="" />
                </div>

                {/* Our History */}
                <div className="auInnerContainer">
                    <h1 className="Autitle" style={{}}>Our History</h1>


                    <div className="Splitimage">
                        <img src="/img/aboutus2.png" alt="" />
                        <p>In April 1928 the Singapore Flying Club came into being after many months of planning. April saw the first flight by Flt. Lt. Carnegie, R.A.F. in a DH 60 Cirrus Moth Seaplane,registration G-EBUJ. There were at the time: 14 Honorary members, 90 Ordinary Members, 8 Subscribing Members and two DH 60 Moths. The Club premises at Tanjong Pagar near Keppel Harbour included a hangar, clubhouse and accommodation for the CFI.</p>
                    </div>

                    <div className="Autext">
                        <p>In 1929 a third seaplane was purchased and that year with three honorary instructors, 658 hours were flown and 17 pilots obtained "A" licenses. The flying was mainly instructional and restricted to the Harbour area, although the spectacular event in that year was the "Round the Island" race won by a Mr. Field with an average speed of 89 mph. <br />
                            <br /> 1930-1933 saw an expansion of the Club to land planes flying from Seletar and an additional Moth and a Cutty Sark Flying Boat registration G-AAVX being added to the fleet. During 1930, 1203 hours were flown and 22 pilots obtained their "A" licence. In 1931, the Club received its Royal Charter from King George V and the "Royal" prefix remained until 1966 when it was relinquished at the request of the Singapore Government. Flying hours in 1931 totalled 1302 hours and 27 members qualified for "A" licences.
                            <br /><br /> In June of 1932 the Club held its first "At Home" which attracted some 250 guests. The three Moth seaplanes and the Cutty Sark were busy giving short joyrides for 15 minutes at $5.00 each. 75 visitors took to the air. The Club's first photographic charter came in June 1933 when Malayan Breweries hired a Moth to photograph their factory in Singapore.</p>
                    </div>

                    <div className="Splitimage">
                        <img src="/img/aboutus3.png" alt="" />
                        <p>The Club went from strength to strength, in 1937 with the opening of Kallang Airport, the Club moved to its new quarters on the airport and that year, with increased activities, flew 542 hours on seaplanes and 1,665 hours on landplanes.</p>
                    </div>


                    <div className="Autext2">
                        <p>By 1937, the Club had 9 aircraft including a DH 87 Hornet Moth and a Miles Magister. In 1938, a Miles Withney Straight augmented these 9 aircraft. By 1939, the last seaplane was dismantled and taken into stock as spares. <br /><br />
                            With the outbreak of war in September 1939, restrictions of varying degrees were imposed. Local flying and night flying ceased altogether for a while but the Club continued and shortly after the outbreak, interest was renewed. The Club undertook training for the Straits Settlement Volunteer Air Force with the majority of its pilots drawn from RSFC members. These pilots were later absorbed into the Royal Air Force Volunteer Reserve. <br /><br />
                            The Malayan Volunteer Air Force was formed in 1939 and was by arrangement operated by the Flying Clubs, using the Club's aircraft and other facilities. The M.V.A.F. carried out various duties in co-operation with the three services including the operation of communication services for the R.A.F. and the Air Transport Auxiliary and the Ferry Pilots organisation. The Club also operated an Elementary Flying Training School on behalf of the Government. During 1939 and 1940 an additional two DH 60s and four DH 49 Moth Minors were acquired. The fleet was then 13 aircraft. In 1940, flying increased to 3300 hours.<br /><br />
                            On The 1st December 1941, the Government requisitioned the Club's aircraft, hangar and equipment. It was not until May 1947 that the Club reformed at Kallang with the purchase of three surplus R.A.F. Service Auster IIIs from funds advanced generously by several of the Club's members. Shortly after this, however, the Government paid the Club compensation for its equipment, which had been requisitioned in 1941. <br /><br />
                            Post war years were a struggle, but with the spirit that the Club had engendered since its inception so long ago, the battle for survival had been waged. The Malayan Emergency (1948 - 1960) enables the Club to offer a useful service to the planting industry by delivering payrolls by air to plantation estates. <br /><br />
                            In 1957, on the 17th February, the Club was again on the move, this time to the new International Airport at Paya Lebar, with both hangar and clubhouse built by its members themselves.</p>
                    </div>

                    <div className="auAircraftOperated">
                        <div className="auInnerLeftDiv">
                            <div className="Aircraftcontainer">
                                <h1>Aircraft Operated</h1>
                                <TbChevronDown size={48} />
                            </div>
                            <ul>
                                <li>2x Cessna 172</li>
                                <li>1x Beagle Pup</li>
                                <li>1x Piper Cherokee 140</li>
                                <li>1x Tiger Moth</li>
                                <li>1x Piper Cherokee 180</li>
                                <li>1x Twin Comanche</li>
                                <li>1 Chipmunk</li>
                            </ul>
                        </div>
                        <img src="/img/aboutus4.png" alt="" />
                    </div>

                    <div className="Autext">
                        <p>Another important contribution made by the Club was that in the early 1960s, the Club carried out flight aptitude tests for pilot trainees of what was to become the Singapore Air Force (Singapore Air Defence Command as it was then called) and Singapore Airlines (Malayan Airways as it was then called).<br /><br />
                            In 1967, shortly after Singapore became a fully independent country, the name of the Club was changed from Royal Singapore Flying Club to Republic of Singapore Flying Club.<br /><br />
                            On 27 June 1974, due to continuing demand for space by commercial civil aviation, it was necessary for the Club to get on the move once again. This time, back to the 1930 - 1933 era at Seletar Airfield where a new Clubhouse was built.<br /><br />
                            Since then, the Republic of Singapore Flying Club has continued to ride out the stormy weather that was encountered over the 30 years in Seletar and is one of the oldest flying club in this part of the world.</p>
                    </div>
                </div>

                {/* Our Stories */}
                <div className="auInnerContainer">
                    <div className="empty" id="stories"></div>
                    <div className="empty"></div>
                    <h1 className="Autitle" style={{ paddingLeft: "0px" }} >Our Stories</h1>
                    <div style={{ display: "flex", flexDirection: "row", alignItems: "flex-start", justifyContent: "left", width: "100%", marginBottom: 10 }}>
                        {stories.map((story, index) => {
                            return (
                                <div
                                    style={{ border: "1px solid black" }}
                                >
                                    <iframe src={story.link} width={400} height={250} frameBorder="0" />
                                    <div style={{ padding: 10 }}>
                                        <p style={{ margin: 0 }}>{story.title}</p>
                                        <p style={{ color: "gray", marginTop: 0, paddingTop: 0 }}>{story.caption}</p>
                                        <p style={{ textAlign: "right" }}>by: {story.author}</p>
                                        <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                                            <button
                                                className="AuButton"
                                                onClick={() => {
                                                    // open link in another tab
                                                    window.open(story.link, "_blank")
                                                }}
                                            >Read more</button>
                                        </div>
                                    </div>
                                </div>
                            )
                        })}
                    </div>

                </div>


            </div>
            <Footer />
        </div>
    )
}


