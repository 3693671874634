import Footer from "../components/Footer";
import Header from "../components/Header"
import RSFCChatBot from "../components/RSFCChatBot";
import "../css/index-404-page.css";

const Index404Page = () => {
    return ( 
        <div style={{display:"flex",flexDirection:"column",justifyContent:"space-between",height:"100vh"}}>
            <Header/>
            <RSFCChatBot/>
            <div className="errorContainer">
                <img src="./img/header.png" alt="" />
                <div className="errorInnerContainer">
                    <h1>404 Page Not Found</h1>
                    <p>Ouh no, looks like you have encountered an error :(</p>
                    <p>If you need any support, please email <a href="mailto:manager@singaporeflyingclub.com">manager@singaporeflyingclub.com</a> for help</p>
                </div>
            </div>
            <Footer/>
        </div>

     );
}
 
export default Index404Page;