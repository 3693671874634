import { Link } from "react-router-dom";
import Footer from "../components/Footer";
import MemberHeader from "../components/MemberHeader";
import { useEffect, useState } from "react";

import "../css/member-new-booking-page.css"
import { APPDATA } from "../app-config";

const MemberNewBookingPage = () => {
    const [aircraft, setAircraft] = useState();
    const [flightType, setFlightType] = useState("training")
    const [flightDate, setFlightDate] = useState()
    const [startTime, setStartTime] = useState()
    const [endTime, setEndTime] = useState()
    const [remarks, setRemarks] = useState("")
    const [fleetData, setFleetData] = useState()

    const submitBooking = async () => {
        console.log(new Date(flightDate + " " + startTime))
        if (new Date(flightDate) > new Date()) {

            let userData = window.sessionStorage.getItem("accessToken")
            let user = JSON.parse(userData)
            let endpoint = APPDATA.server + "/booking/new-booking"
            let data = {
                aircraft_type: aircraft,
                flight_type: flightType,
                flight_date: flightDate,
                start_time: startTime,
                end_time: endTime,
                booking_remarks: remarks,
                user_id: user._id
            }
            let fetchResponse = await fetch(endpoint, {
                method: "POST",
                credentials: "include",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(data)
            })
            let response = await fetchResponse.json()

            if (response.status === 200) {
                window.location.href = "/members/booking"
                alert("Booking submitted successfully")
                window.location.href = "/members/booking"
            } else if (response.status === 403) {
                window.location.href = "/account"
            } else if (response.status === 401) {
                window.location.href = "/account"
            } else if (response.status === 406) {
                alert("Aircraft is not available at the selected time")
            } else if (response.status === 500) {
                alert("Your account is unable to make a booking")
            } else {
                alert("Booking submission failed")
            }
        } else {
            alert("Please book at least 1 day in advance")
        }

    }

    const fetchFleet = async () => {
        let endpoint = APPDATA.server + "/fleet/"
        let fetchResponse = await fetch(endpoint, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
            }
        })
        let response = await fetchResponse.json()
        setFleetData(response.data)
        setAircraft(`${response.data[0].aircraft_name} (${response.data[0].aircraft_registration})`)
        // alert(`${response.data[0].aircraft_name} (${response.data[0].aircraft_registration})`)
    }

    useEffect(() => {
        fetchFleet()
    }, [])

    return (
        <div>
            <MemberHeader selected={"Booking"} />
            <div className="mhpContainer">
                <div className="mhpInnerContainer">
                    <div className="mbpSplit">
                        <h1 className="mhpTitle">New Booking</h1>
                        <Link id="newBookingButton" to={"/members/booking"}>cancel</Link>
                    </div>
                    <h3>Flight Details</h3>
                    <div className="mnbpSplit">
                        <div className="mnbpForms">
                            <div className="mnbpFieldContainer">
                                <label className="mnbpLabel">Aircraft</label>
                                {/* <input className="mnbpInput" type="text" placeholder="Aircraft"/> */}
                                <select name="plane" id="plane" onChange={(e) => setAircraft(e.target.value)} >
                                    {fleetData ? fleetData.map((fleet, index) => {
                                        if (fleet.aircraft_status === "active") {
                                            return <option key={index} value={`${fleet.aircraft_name} (${fleet.aircraft_registration})`}>{fleet.aircraft_name} ({fleet.aircraft_registration})</option>
                                        }
                                    }) : null}
                                </select>
                            </div>



                            <div className="mnbpFieldContainer">
                                <label className="mnbpLabel">Flight type</label>
                                <select name="flightType" id="flightType" onChange={(e) => setFlightType(e.target.value)}>
                                    <option selected value="training">Training Flight</option>
                                    <option value="examiniation">Examination Flight</option>
                                    <option value="leisure">Leisure Flight</option>
                                </select>
                            </div>
                        </div>
                        <div className="mnbpForms">
                            <div className="mnbpFieldContainer">
                                <label className="mnbpLabel">Date</label>
                                <input className="mnbpInput" type="date" value={flightDate} onChange={(e) => setFlightDate(e.target.value)} />
                            </div>
                            <div className="mnbpFieldContainer">
                                <label className="mnbpLabel">Start Time</label>
                                <input className="mnbpInput" type="time" value={startTime} onChange={(e) => setStartTime(e.target.value)} />
                            </div>
                            <div className="mnbpFieldContainer">
                                <label className="mnbpLabel">End Time</label>
                                <input className="mnbpInput" type="time" value={endTime} onChange={(e) => setEndTime(e.target.value)} />
                            </div>
                        </div>

                    </div>
                    <div className="mnbpBookingRemarks">
                        <label className="mnbpLabel">Booking Remarks</label>
                        <textarea className="bookingText" value={remarks} onChange={(e) => setRemarks(e.target.value)} ></textarea>
                    </div>

                    <div className="mnbpButtonContainer">
                        <button className="mnbpButton" onClick={submitBooking}>Submit Booking</button>
                    </div>


                    <div className="mnbpChargesContainer">
                        <h3>Flying Charges</h3>
                        <div className="mnbpInnerContainer">
                            <h1 className="mnbpCharges">PPL Solo - $300 per half hour</h1>
                            <h1 className="mnbpCharges">Dual <span id="smallCharge">(with instructor)</span> - $335 per half hour</h1>
                            <p style={{ fontSize: 25 }}>Rates are pro-rated in 5-minute blocks</p>
                        </div>
                    </div>

                </div>
            </div>
            <Footer />
        </div>
    );
}

export default MemberNewBookingPage;