import AdminHeader from "../components/AdminHeader";
import Footer from "../components/Footer";
import { Link, useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";
import "../css/admin-bookings-page.css"
import { APPDATA } from "../app-config";



const AdminBookingsPage = () => {
    const [bookings, setBookings] = useState([])
    const [loading, setLoading] = useState(true)
    const [windowWidth, setWindowWidth] = useState(window.innerWidth)

    const navigate = useNavigate();
    useEffect(() => {
        fetchBookings()
    }, [])

    const fetchBookings = async (id) => {
        let endpoint = APPDATA.server + "/booking/"
        let fetchResponse = await fetch(endpoint, {
            method: "GET",
            credentials: "include",
            headers: {
                "Content-Type": "application/json",
                
            }
        })
        let response = await fetchResponse.json()

        if (response.status === 403) {
            window.location.href = "/account"
        }else if (response.status === 401) {
            window.location.href = "/account"
        }else{
            console.log(response)
            setBookings(response.data)
            setLoading(false)
        }
    }

    window.addEventListener("resize", () => {
        setWindowWidth(window.innerWidth)
    })

    return (
        <div>
            <AdminHeader selected={"Bookings"} />
            <div className="abpContainer">
                <div className="abpInnerContainer">
                    <h1 className="abpTitle">Flight Bookings</h1>

                    {/* Require Confirmation */}
                    <h2 className="abpSubtitle">Require Confirmation</h2>

                    {windowWidth > 1200 ? <>
                        <div className="abpTable">
                            <div className="abpTableHeader">
                                <p className="abpTableHeaderItem" style={{ width: "30%" }}>Aircraft Type</p>
                                <p className="abpTableHeaderItem" style={{ width: "30%" }}>Flight Date</p>
                                <p className="abpTableHeaderItem" style={{ width: "30%" }}>Booking Time</p>
                                <p className="abpTableHeaderItem" style={{ width: "30%" }}>Flight Type</p>
                                <p className="abpTableHeaderItem" style={{ width: "30%" }}>Booking Status</p>
                                <p className="abpTableHeaderItem" style={{ width: "30%" }}></p>
                            </div>
                            {loading ? <p>Loading...</p> : bookings.map((booking) => {
                                if (booking.booking_status === "pending") {
                                    return (
                                        <div className="abpTableRow">
                                            <p className="abpTableItem" style={{ width: "30%" }}>{booking.aircraft_type}</p>
                                            <p className="abpTableItem" style={{ width: "30%" }}>{booking.flight_date}</p>
                                            <p className="abpTableItem" style={{ width: "30%" }}>{booking.start_time}-{booking.end_time}</p>
                                            <p className="abpTableItem" style={{ width: "30%" }}>{booking.flight_type}</p>
                                            <p className="abpTableItem" style={{ width: "30%" }}>{booking.booking_status}</p>
                                            <div className="abpActionButton" style={{ width: "30%" }}>
                                                <a id="abpViewButton" onClick={() => {
                                                    navigate("/admin/booking/" + booking._id, { state: booking })
                                                }} to={"/admin/booking/" + booking._id}>view</a>
                                            </div>
                                        </div>
                                    )
                                }
                            })}
                        </div>
                    </> : <>
                        <div className="abpSmallTableContainer">
                            {loading ? <p>Loading...</p> : bookings.map((booking) => {
                                if (booking.booking_status === "pending") {
                                    return (
                                        <div className="abpSmallTable">
                                            <div className="abpSmallTableRow">
                                                <p className="abpSmallTableItem" >Aircraft Type</p>
                                                <p className="abpSmallTableItem" >{booking.aircraft_type}</p>
                                            </div>
                                            <div className="abpSmallTableRow">
                                                <p className="abpSmallTableItem" >Flight Date</p>
                                                <p className="abpSmallTableItem" >{booking.flight_date}</p>
                                            </div>
                                            <div className="abpSmallTableRow">
                                                <p className="abpSmallTableItem" >Booking Time</p>
                                                <p className="abpSmallTableItem" >{booking.start_time}-{booking.end_time}</p>
                                            </div>
                                            <div className="abpSmallTableRow">
                                                <p className="abpSmallTableItem" >Flight Type</p>
                                                <p className="abpSmallTableItem" >{booking.flight_type}</p>
                                            </div>
                                            <div className="abpSmallTableRow" style={{ borderBottomWidth: "0px" }}>
                                                <p className="abpSmallTableItem" >Booking Status</p>
                                                <p className="abpSmallTableItem" >{booking.booking_status}</p>
                                            </div>
                                            <div className="abpSmallTableRow" style={{ borderBottomWidth: "0px", justifyContent: 'center' }}>
                                                <div className="abpActionButton" >
                                                    <a id="abpViewButton" onClick={() => {
                                                        navigate("/admin/booking/" + booking._id, { state: booking })
                                                    }} to={"/admin/booking/" + booking._id}>view</a>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                }
                            })}
                        </div>
                    </>}



                    {/* In Progress */}
                    <h2 className="abpSubtitle">Confirm Booking</h2>

                    {windowWidth > 1200 ?
                        <div className="abpTable">
                            <div className="abpTableHeader">
                                <p className="abpTableHeaderItem" style={{ width: "30%" }}>Aircraft Type</p>
                                <p className="abpTableHeaderItem" style={{ width: "30%" }}>Flight Date</p>
                                <p className="abpTableHeaderItem" style={{ width: "30%" }}>Booking Time</p>
                                <p className="abpTableHeaderItem" style={{ width: "30%" }}>Flight Type</p>
                                <p className="abpTableHeaderItem" style={{ width: "30%" }}>Booking Status</p>
                                <p className="abpTableHeaderItem" style={{ width: "30%" }}></p>
                            </div>
                            {loading ? <p>Loading...</p> : bookings.map((booking) => {
                                console.log(booking)
                                if (booking.booking_status.toLowerCase() === "confirmed") {
                                    return (
                                        <div className="abpTableRow">
                                            <p className="abpTableItem" style={{ width: "30%" }}>{booking.aircraft_type}</p>
                                            <p className="abpTableItem" style={{ width: "30%" }}>{booking.flight_date}</p>
                                            <p className="abpTableItem" style={{ width: "30%" }}>{booking.start_time}-{booking.end_time}</p>
                                            <p className="abpTableItem" style={{ width: "30%" }}>{booking.flight_type}</p>
                                            <p className="abpTableItem" style={{ width: "30%" }}>{booking.booking_status}</p>
                                            <div className="abpActionButton" style={{ width: "30%" }}>
                                                <a id="abpViewButton" onClick={() => {
                                                    navigate("/admin/booking/" + booking._id, { state: booking })
                                                }} to={"/admin/booking/" + booking._id}>view</a>
                                            </div>
                                        </div>
                                    )
                                }
                            })}
                        </div>:
                        <div className="abpSmallTableContainer">
                            {loading ? <p>Loading...</p> : bookings.map((booking) => {
                                if (booking.booking_status.toLowerCase() === "confirmed") {
                                    return (
                                        <div className="abpSmallTable">
                                            <div className="abpSmallTableRow">
                                                <p className="abpSmallTableItem" >Aircraft Type</p>
                                                <p className="abpSmallTableItem" >{booking.aircraft_type}</p>
                                            </div>
                                            <div className="abpSmallTableRow">
                                                <p className="abpSmallTableItem" >Flight Date</p>
                                                <p className="abpSmallTableItem" >{booking.flight_date}</p>
                                            </div>
                                            <div className="abpSmallTableRow">
                                                <p className="abpSmallTableItem" >Booking Time</p>
                                                <p className="abpSmallTableItem" >{booking.start_time}-{booking.end_time}</p>
                                            </div>
                                            <div className="abpSmallTableRow">
                                                <p className="abpSmallTableItem" >Flight Type</p>
                                                <p className="abpSmallTableItem" >{booking.flight_type}</p>
                                            </div>
                                            <div className="abpSmallTableRow" style={{ borderBottomWidth: "0px" }}>
                                                <p className="abpSmallTableItem" >Booking Status</p>
                                                <p className="abpSmallTableItem" >{booking.booking_status}</p>
                                            </div>
                                            <div className="abpSmallTableRow" style={{ borderBottomWidth: "0px", justifyContent: 'center' }}>
                                                <div className="abpActionButton" >
                                                    <a id="abpViewButton" onClick={() => {
                                                        navigate("/admin/booking/" + booking._id, { state: booking })
                                                    }} to={"/admin/booking/" + booking._id}>view</a>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                }
                            })}
                        </div>}

                        {/* Require Payment */}
                        <h2 className="abpSubtitle">Require Payment</h2>

                        {windowWidth > 1200 ?
                        <div className="abpTable">
                            <div className="abpTableHeader">
                                <p className="abpTableHeaderItem" style={{ width: "30%" }}>Aircraft Type</p>
                                <p className="abpTableHeaderItem" style={{ width: "30%" }}>Flight Date</p>
                                <p className="abpTableHeaderItem" style={{ width: "30%" }}>Flight Status</p>
                                <p className="abpTableHeaderItem" style={{ width: "30%" }}>Payment Status</p>
                                <p className="abpTableHeaderItem" style={{ width: "30%" }}>Payment Fee</p>
                                <p className="abpTableHeaderItem" style={{ width: "30%" }}></p>
                            </div>
                            {loading ? <p>Loading...</p> : bookings.map((booking) => {
                                console.log(booking)
                                if (booking.booking_status === "completed" && (booking.payment_status === "processing" || booking.payment_status === "requesting-payment" || booking.payment_status === "to-check-payment")) {
                                    return (
                                        <div className="abpTableRow">
                                            <p className="abpTableItem" style={{ width: "30%" }}>{booking.aircraft_type}</p>
                                            <p className="abpTableItem" style={{ width: "30%" }}>{booking.flight_date}</p>
                                            <p className="abpTableItem" style={{ width: "30%" }}>{booking.booking_status}</p>
                                            <p className="abpTableItem" style={{ width: "30%" }}>{booking.payment_status}</p>
                                            <p className="abpTableItem" style={{ width: "30%" }}>${booking.payment_fee}</p>
                                            <div className="abpActionButton" style={{ width: "30%" }}>
                                                <a id="abpViewButton" onClick={() => {
                                                    navigate("/admin/booking/" + booking._id, { state: booking })
                                                }} to={"/admin/booking/" + booking._id}>view</a>
                                            </div>
                                        </div>
                                    )
                                }
                            })}
                        </div>:
                        <div className="abpSmallTableContainer">
                            {loading ? <p>Loading...</p> : bookings.map((booking) => {
                                if (booking.booking_status === "completed" && (booking.payment_status === "processing" || booking.payment_status === "requesting-payment" || booking.payment_status === "to-check-payment")) {
                                    return (
                                        <div className="abpSmallTable">
                                            <div className="abpSmallTableRow">
                                                <p className="abpSmallTableItem" >Aircraft Type</p>
                                                <p className="abpSmallTableItem" >{booking.aircraft_type}</p>
                                            </div>
                                            <div className="abpSmallTableRow">
                                                <p className="abpSmallTableItem" >Flight Date</p>
                                                <p className="abpSmallTableItem" >{booking.flight_date}</p>
                                            </div>
                                            <div className="abpSmallTableRow">
                                                <p className="abpSmallTableItem" >Flight Status</p>
                                                <p className="abpSmallTableItem" >{booking.booking_status}</p>
                                            </div>
                                            <div className="abpSmallTableRow">
                                                <p className="abpSmallTableItem" >Payment Status</p>
                                                <p className="abpSmallTableItem" >{booking.payment_status}</p>
                                            </div>
                                            <div className="abpSmallTableRow" style={{ borderBottomWidth: "0px" }}>
                                                <p className="abpSmallTableItem" >Payment Fee</p>
                                                <p className="abpSmallTableItem" >${booking.payment_fee}</p>
                                            </div>
                                            <div className="abpSmallTableRow" style={{ borderBottomWidth: "0px", justifyContent: 'center' }}>
                                                <div className="abpActionButton" >
                                                    <a id="abpViewButton" onClick={() => {
                                                        navigate("/admin/booking/" + booking._id, { state: booking })
                                                    }} to={"/admin/booking/" + booking._id}>view</a>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                }
                            })}
                        </div>}

                        {/* Past Bookings */}
                        <h2 className="abpSubtitle">Past Bookings</h2>
                        {windowWidth > 1200 ?
                        <div className="abpTable">
                            <div className="abpTableHeader">
                                <p className="abpTableHeaderItem" style={{ width: "30%" }}>Aircraft Type</p>
                                <p className="abpTableHeaderItem" style={{ width: "30%" }}>Flight Date</p>
                                <p className="abpTableHeaderItem" style={{ width: "30%" }}>Booking Time</p>
                                <p className="abpTableHeaderItem" style={{ width: "30%" }}>Flight Type</p>
                                <p className="abpTableHeaderItem" style={{ width: "30%" }}>Booking Status</p>
                                <p className="abpTableHeaderItem" style={{ width: "30%" }}></p>
                            </div>
                            {loading ? <p>Loading...</p> : bookings.map((booking) => {
                                console.log(booking)
                                if (booking.booking_status === "completed" && booking.payment_status === "paid") {
                                    return (
                                        <div className="abpTableRow">
                                            <p className="abpTableItem" style={{ width: "30%" }}>{booking.aircraft_type}</p>
                                            <p className="abpTableItem" style={{ width: "30%" }}>{booking.flight_date}</p>
                                            <p className="abpTableItem" style={{ width: "30%" }}>{booking.start_time}-{booking.end_time}</p>
                                            <p className="abpTableItem" style={{ width: "30%" }}>{booking.flight_type}</p>
                                            <p className="abpTableItem" style={{ width: "30%" }}>{booking.booking_status}</p>
                                            <div className="abpActionButton" style={{ width: "30%" }}>
                                                <a id="abpViewButton" onClick={() => {
                                                    navigate("/admin/booking/" + booking._id, { state: booking })
                                                }} to={"/admin/booking/" + booking._id}>view</a>
                                            </div>
                                        </div>
                                    )
                                }
                            })}
                        </div>:
                        <div className="abpSmallTableContainer">
                            {loading ? <p>Loading...</p> : bookings.map((booking) => {
                                if (booking.booking_status === "completed" && booking.payment_status === "paid") {
                                    return (
                                        <div className="abpSmallTable">
                                            <div className="abpSmallTableRow">
                                                <p className="abpSmallTableItem" >Aircraft Type</p>
                                                <p className="abpSmallTableItem" >{booking.aircraft_type}</p>
                                            </div>
                                            <div className="abpSmallTableRow">
                                                <p className="abpSmallTableItem" >Flight Date</p>
                                                <p className="abpSmallTableItem" >{booking.flight_date}</p>
                                            </div>
                                            <div className="abpSmallTableRow">
                                                <p className="abpSmallTableItem" >Booking Time</p>
                                                <p className="abpSmallTableItem" >{booking.start_time}-{booking.end_time}</p>
                                            </div>
                                            <div className="abpSmallTableRow">
                                                <p className="abpSmallTableItem" >Flight Type</p>
                                                <p className="abpSmallTableItem" >{booking.flight_type}</p>
                                            </div>
                                            <div className="abpSmallTableRow" style={{ borderBottomWidth: "0px" }}>
                                                <p className="abpSmallTableItem" >Booking Status</p>
                                                <p className="abpSmallTableItem" >{booking.booking_status}</p>
                                            </div>
                                            <div className="abpSmallTableRow" style={{ borderBottomWidth: "0px", justifyContent: 'center' }}>
                                                <div className="abpActionButton" >
                                                    <a id="abpViewButton" onClick={() => {
                                                        navigate("/admin/booking/" + booking._id, { state: booking })
                                                    }} to={"/admin/booking/" + booking._id}>view</a>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                }
                            })}
                        </div>}
                    </div>
            </div>
                <Footer />
            </div>

            );
}

            export default AdminBookingsPage;