import { Link, useAsyncError, useLocation, useNavigate } from "react-router-dom";
import AdminHeader from "../components/AdminHeader";
import EventHeader from "../components/AdminEventHeader";
import Footer from "../components/Footer";
import { APPDATA } from "../app-config";
import { useState, useEffect } from "react";
import "../css/admin-edit-event-page.css";
// import AdminEventHeader from "../components/AdminEventHeader";
import { IoClose } from "react-icons/io5";

const AdminEditEventPage = () => {

    const { state } = useLocation();

    const [title, setTitle] = useState(state.title)

    const [eventID, setEventID] = useState()
    const [events, setEvents] = useState()
    const [eventStartDate, setEventStartDate] = useState(state.eventStartDate)
    const [eventEndDate, setEventEndDate] = useState(state.eventEndDate)
    const [price, setPrice] = useState(state.price)
    const [type, setType] = useState(state.type)
    const [participants, setParticipants] = useState(state.participants)
    const [description, setDescription] = useState(state.description)
    const [uploadFiles, setUploadFiles] = useState()
    const [eventImage, setEventImage] = useState("")
    const [imageID, setImageID] = useState()
    const [questions, setQuestions] = useState(state.event_questions.join("||"))
    const [allAnswers, setAllAnswers] = useState([])
    const [isLoading, setIsLoading] = useState(false)
    const [windowWidth, setWindowWidth] = useState(window.innerWidth)
    const [deleteEvents, setDeleteEvents] = useState()
    const [deleteParticipant, setDeleteEntry] = useState()

    const [pageState, setPageState] = useState("edit")
    const navigate = useNavigate();


    const loadEvent = async () => {
        let endpoint = APPDATA.server + "/events/" + state._id;
        console.log(state)
        let formData = new FormData()
        console.log(endpoint)

        let fetchResponse = await fetch(endpoint, {
            method: "GET",
            credentials: "include",
            headers: {
                "Content-Type": "application/json",
            },
        })
        let response = await fetchResponse.json()
        if (response.status === 200) {
            console.log(response)
            setAllAnswers(response.data)
            setTitle(response.data.event_title)
            setEventID(response.data.event_id)
            setEventStartDate(response.data.event_start_date)
            setEventEndDate(response.data.event_end_date)
            setPrice(response.data.event_price)
            setType(response.data.event_type)
            setParticipants(response.data.event_participants)
            setDescription(response.data.event_description)
            setEventImage(response.data.event_image)

        }
    }

    useEffect(() => {
        loadEvent()
    }, [])
    window.addEventListener("resize", () => {
        setWindowWidth(window.innerWidth)
    })

    function EventHeader({ selected, }) {
        if (selected === "Edit Event") {
            //setPageState("edit")
        }
        if (selected === "Sign Ups") {
            // setPageState("signups")
        }
        return (
            <div className="navbar">
                <div className="innerNavbar">
                    <a onClick={() => setPageState("edit")} style={{ color: selected === "Edit Event" ? "white" : "black", backgroundColor: selected === "Edit Event" ? "#1B4965" : "none", background: selected === "Edit Event" ? "" : "none" }} className='navbarItems'>Edit Event</a>
                    <a onClick={() => setPageState("signups")} style={{ color: selected === "Sign Ups" ? "white" : "black", backgroundColor: selected === "Sign Ups" ? "#1B4965" : "none", background: selected === "Sign Ups" ? "" : "none" }} className='navbarItems' >Sign Ups</a>

                </div>
            </div>
        )
    }


    const updateEvent = async () => {

        if (!isNaN(price)) {
            let endpoint = APPDATA.server + "/events/update-event/" + state._id;
            let data = {
                event_id: eventID,
                event_image: eventImage,
                event_start_date: eventStartDate,
                event_end_date: eventEndDate,
                event_title: title,
                event_description: description,
                event_price: price,
                event_type: type,
                event_participants: participants,
                event_questions: questions
            }

            console.log(data)
            if (eventStartDate && eventEndDate && title && description && price && type ) {

                let fetchResponse = await fetch(endpoint, {
                    method: "POST",
                    credentials: "include",
                    headers: {
                        "Content-Type": "application/json",
                        "Authorization": "Bearer " + JSON.parse(window.sessionStorage.getItem("accessToken")).token,
                        "x-access-token": JSON.parse(window.sessionStorage.getItem("accessToken")).token
                    },
                    body: JSON.stringify(data)
                })
                let response = await fetchResponse.json()

                if (response.status === 200) {
                    window.location.href = "/admin/events"
                    alert("Event updated successfully")
                    window.location.href = "/admin/events"
                } else {
                    alert("Event failed to update")
                }
            }else{
                alert("Please fill in all the fields")
            }
        } else {
            alert("Invalid field")
        }
    }

    const uploadImage = async () => {
        let endpoint = APPDATA.server + "/events/upload-image" + state._id;
        let formData = new FormData()
        console.log(endpoint)

        formData.append("image", uploadFiles)
        let fetchResponse = await fetch(endpoint, {
            method: "POST",
            body: formData
        })
        let response = await fetchResponse.json()
        if (response.status === 200) {
            console.log(response.data)
            setEventImage(response.data)
            updateEvent(response.data.file_path)
        } else {
            alert("Error, unable to upload image")
        }
    }

    const deleteEvent = async () => {
        let endpoint = APPDATA.server + "/events/" + state._id + "/delete";
        if (!state._id) return
        console.log(endpoint)
        let fetchResponse = await fetch(endpoint, {
            method: "POST",
            credentials: "include",
            headers: {
                "Content-Type": "application/json",
            },
        })
        let response = await fetchResponse.json()
        if (response.status === 200) {
            window.location.href = "/admin/events"
            alert("Event Deleted")
        } else {

            alert("Unable to delete event")
        }
    }


    const deleteEntry = async (index) => {
        console.log(index)
        let current_answers = allAnswers
        current_answers.splice(index, 1)
        console.log(current_answers)
        let endpoint = APPDATA.server + "/events/" + state._id + "/delete-response";
        let fetchResponse = await fetch(endpoint, {
            method: "POST",
            credentials: "include",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                responses: current_answers
            })
        })
        let response = await fetchResponse.json()
        if (response.status === 200) {
            setAllAnswers(response.data.event_answers ? response.data.event_answers : [])
            alert("Responses Deleted")
        } else {
            alert("Unable to delete response")
        }
    }

    const loadEventSignup = async () => {
        let endpoint = APPDATA.server + "/events/event-signup/" + state._id;
        console.log(state)
        let formData = new FormData()
        console.log(endpoint)

        let fetchResponse = await fetch(endpoint, {
            method: "GET",
            credentials: "include",
            headers: {
                "Content-Type": "application/json",
            },
        })
        let response = await fetchResponse.json()
        if (response.status === 200) {
            console.log(response.data.event_answers)
            setIsLoading(false)
            setAllAnswers(response.data.event_answers ? response.data.event_answers : [])
        }
    }

    useEffect(() => {
        loadEventSignup()
    }, [])




    if (pageState === "edit") {
        return (
            <div>
                <AdminHeader selected={"Events"} />
                <EventHeader selected={"Edit Event"} />
                <div className="aeepContainer">
                    <div className="aeepInnerContainer">
                        <div className="aeepHeader">
                            <h1 className="aeepTitle">Edit event</h1>
                            <Link id="newEventButton" to={"/admin/events"}>cancel</Link>
                        </div>



                        <div className="aeepImageContainer">

                            <img src={`${APPDATA.server}${eventImage.toString().replace("public", '')}`} id='eventImage' className="aeepEventImage" alt="" onClick={() => uploadImage()} />

                        </div>
                        <h2>Event Details</h2>
                        <div className="aeepSplitContainer">

                            <div className="aeepSplit">

                                <div className="aeepFieldContainer">
                                    <label className="aeepLabel">Title</label>
                                    <input className="aeepInput" value={title} onChange={(e) => setTitle(e.target.value)} />
                                </div>
                                <div className="aeepFieldContainer">
                                    <label className="aeepLabel">Start Date</label>
                                    <input className="aeepInput" type="date" value={eventStartDate} onChange={(e) => setEventStartDate(e.target.value)} />
                                </div>
                                <div className="aeepFieldContainer">
                                    <label className="aeepLabel">End Date</label>
                                    <input className="aeepInput" type="date" value={eventEndDate} onChange={(e) => setEventEndDate(e.target.value)} />
                                </div>
                                <div className="aeepFieldContainer">
                                    <label className="aeepLabel">Price</label>
                                    <input className="aeepInput" value={price} onChange={(e) => setPrice(e.target.value)} />
                                </div>
                            </div>
                            <div className="aeepSplit2">
                                <div className="aeepFieldContainer">
                                    <label className="aeepLabel">Maximum participants</label>
                                    <input className="aeepInput" value={participants} onChange={(e) => setParticipants(e.target.value)} />
                                </div>
                                <div className="aeepFieldContainer">
                                    <label className="aeepLabel">Type</label>
                                    <select className="aeepTypeSelection" name="type" value={type} onChange={(e) => setType(e.target.value)} >
                                        <option value="Gathering">Gathering</option>
                                        <option value="Seminar">Seminar</option>
                                        <option value="Class">Class</option>
                                        <option value="Flight">Flight</option>
                                        <option value="Tour">Tour</option>
                                        <option value="Learning Trip">Learning Trip</option>
                                        <option value="Other">Other</option>
                                    </select>
                                </div>
                                <div className="aeepFieldContainer">
                                    <label className="aeepLabel">Description</label>
                                    <textarea className="eventDescription" value={description} onChange={(e) => setDescription(e.target.value)} ></textarea>
                                </div>

                            </div>
                        </div>

                        <h2>Respondees Question</h2>
                        <div className="anepQuestions">

                            {/* <div className="compulsoryQuestion">
                            <label className="anepLabel" style={{ paddingLeft: 10 }}>Question 1</label>
                            <input  className="anepInput" value="Name" onChange={(e) => {
                                let newQuestions = questions.split("||")
                                newQuestions[0] = e.target.value
                                setQuestions(newQuestions.join("||"))}}/>
                        
                            <label className="anepLabel" style={{ paddingLeft: 10 }}>Question 2</label>
                            <input  className="anepInput" value="Contact Number" onChange={(e) => {
                                let newQuestions = questions.split("||")
                                newQuestions[1] = e.target.value
                                setQuestions(newQuestions.join("||"))}}/>
                        </div> */}
                            {!isLoading &&questions.length>0? questions.split("||").map((question, index) => {

                                return (


                                    <div className="anepQuestionContainer">


                                        <div className="anepQuestionLabelContainer">
                                            <label className="anepLabel" style={{ paddingLeft: 10 }}>Question {index + 1}</label>
                                            <IoClose color={"Black"} size={30} onClick={() => {
                                                let newQuestions = questions.split("||")
                                                newQuestions.splice(index, 1)
                                                setQuestions(newQuestions.join("||"))
                                            }} />
                                        </div>

                                        <input
                                            className="anepInput"
                                            value={question}
                                            onChange={(e) => {
                                                let newQuestions = questions.split("||")
                                                newQuestions[index] = e.target.value
                                                setQuestions(newQuestions.join("||"))

                                            }}

                                            onKeyDown={(e) => {
                                                if (e.key === "Enter" && questions[questions.length - 1] !== "|") {

                                                    setQuestions(questions + "||")

                                                }
                                            }}
                                        />
                                    </div>
                                )
                            }) : null}
                        </div>


                    </div>
                    <div className="aeepButtonContainer">
                        <button className="aeepDeleteButton" onClick={() => deleteEvent()}>Delete event</button>
                        <button className="aeepButton" onClick={() => updateEvent()}>Save event</button>
                    </div>
                </div>
                <Footer />
            </div>
        );
    } else {
        let showQuestion = questions.split("||");

        return (

            <div>
                <AdminHeader selected={"Events"} />
                <EventHeader selected={"Sign Ups"} />
                <div className="aeepContainer">
                    <div className="aeepInnerContainer">
                        <div className="aeepHeader">
                            <h1 className="aeepTitle">Sign Ups</h1>
                            <Link id="newEventButton" to={"/admin/events"}>cancel</Link>
                        </div>
                        <p>{allAnswers ? allAnswers.length : "-"} sign ups</p>
                        {windowWidth > 1200 ? <>
                            <div className="aeepTable">
                                <div className="aeepTableHeader">
                                    <p className="aeepTableHeaderItem" style={{ width: "15%" }}>S/N</p>
                                    <p className="aeepTableHeaderItem" style={{ width: "30%" }}>{showQuestion[0]}</p>
                                    <p className="aeepTableHeaderItem" style={{ width: "40%" }}>{showQuestion[1]}</p>
                                    <p className="aeepTableHeaderItem" style={{ width: "40%" }}>{showQuestion[2]}</p>
                                    <p className="aeepTableHeaderItem" style={{ width: "40%" }}>{showQuestion[3]}</p>
                                    <p className="aeepTableHeaderItem" style={{ width: "30%" }}>{ }</p>

                                </div>


                                {allAnswers.length > 0 ?allAnswers.map((allAnswer, index) => {
                                    let event_answers = allAnswer.split("||");
                                    console.log(event_answers)

                                    return (

                                        <div className="aeepTableRow">
                                            <p className="aeepTableItem" style={{ width: "15%" }}>{index + 1}</p>
                                            <p className="aeepTableItem" style={{ width: "30%" }}>{event_answers[0]}</p>
                                            <p className="aeepTableItem" style={{ width: "40%" }}>{event_answers[1]}</p>
                                            <p className="aeepTableItem" style={{ width: "40%" }}>{event_answers[2]}</p>
                                            <p className="aeepTableItem" style={{ width: "40%" }}>{event_answers[3]}</p>
                                            <div className="aeepActionButton" style={{ width: "30%" }}>
                                                <a href className="aeepViewButton" onClick={() => deleteEntry(index)}>Delete</a>
                                            </div>
                                        </div>
                                    )

                                }):null}
                            </div>
                        </>
                            : <>
                                {allAnswers.length >0? allAnswers.map((allAnswer, index) => {
                                    let event_answers = allAnswer.split("||");
                                    console.log(event_answers)

                                    return (

                                        <div className="aeepSmallTableContainer">
                                            <div className="aeepSmallTable">
                                                <div className="aeepSmallRow">
                                                    <p className="aeepSmallItem">S/N</p>
                                                    <p className="aeepSmallItem">{index + 1}</p>
                                                </div>
                                                <div className="aeepSmallRow">
                                                    <p className="aeepSmallItem">{showQuestion[0]}</p>
                                                    <p className="aeepSmallItem">{event_answers[0]}</p>
                                                </div>
                                                <div className="aeepSmallRow">
                                                    <p className="aeepSmallItem">{showQuestion[1]}</p>
                                                    <p className="aeepSmallItem">{event_answers[1]}</p>
                                                </div>
                                                <div className="aeepSmallRow">
                                                    <p className="aeepSmallItem">{showQuestion[2]}</p>
                                                    <p className="aeepSmallItem">{event_answers[2]}</p>
                                                </div>
                                                <div className="aeepSmallRow">
                                                    <p className="aeepSmallItem">{showQuestion[3]}</p>
                                                    <p className="aeepSmallItem">{event_answers[3]}</p>
                                                </div>
                                                <div className="aeepActionButton">

                                                    <a href className="aeepViewButton" onClick={() => deleteEntry(index)}>Delete</a>


                                                </div>
                                            </div>
                                        </div>
                                    )
                                }):null}
                            </>
                        }
                    </div>

                </div>

            </div>

        )
    }


}



export default AdminEditEventPage;