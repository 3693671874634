import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import reportWebVitals from './reportWebVitals';
import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";
import IndexPage from "./screen/IndexPage";
import MembershipPage from "./screen/MembershipPage";
import AboutUsPage from './screen/AboutUsPage';
import AccountPage from './screen/AccountPage';
import MemberHomePage from './screen/MemberHomePage';
import EventsPage from './screen/EventsPage';
import AdminNewEventsPage from './screen/AdminNewEventsPage';
import ExperiencePage from './screen/ExperiencePage';
import MemberCommunityPage from './screen/MemberCommunityPage';
import MemberBookingPage from './screen/MemberBookingPage';
import MemberFlightLogPage from './screen/MemberFlightLogPage';
import MemberNewBookingPage from './screen/MemberNewBookingPage';
import AdminMemberPage from './screen/AdminMemberPage';
import AdminControlPanelPage from './screen/AdminControlPanelPage';
import AdminEventsPage from './screen/AdminEventsPage';
import AdminBookingsPage from './screen/AdminBookingsPage';
import AdminBookingDetailsPage from './screen/AdminBookingDetailsPage';
import MemberFlightPage from './screen/MemberFlightDetail';
import AdminSupportPage from './screen/AdminSupportPage';
import AccountRecoveryPage from './screen/AccountRecoveryPage';
import AdminMemberDetailPage from './screen/AdminMemberDetailPage';
import AdminEditEventPage from './screen/AdminEditEventPage';
import Index404Page from './screen/Index404Page';
import MaintenancePage from './screen/MaintenancePage';
import TestPage from './screen/TestPage';
import MemberProfilePage from './screen/MemberProfilePage';
import MemberEditProfilePage from './screen/MemberEditProfilePage';
import EventDetailPage from './screen/EventDetailPage';
import MemberEventSignupPage from './screen/MemberEventSignupPage';
import AdminNewFleetPage from './screen/AdminNewFleetPage';
import AdminEditFleetPage from './screen/AdminEditFleetPage';
import AdminEventSignupPage from './screen/AdminEventSignupPage';
import AdminApplicationPage from './screen/AdminApplicationPage';
import AdminNewMemberPage from './screen/AdminNewMemberPage';
import MemberCommuityPostPage from './screen/MemberCommuityPostPage';

// setInterval(() => {
//   console.clear()
// }, 1000)

const router = createBrowserRouter([
  {
    path: "/",
    element: <IndexPage/>,
  },
  {
    path: "/membership",
    element: <MembershipPage/>,
  },
  {
    path: "/about-us",
    element: <AboutUsPage/>,
  },
  {
    path: "/account",
    element: <AccountPage/>,
  },
  {
    path: "/change-password/:id",
    element: <AccountRecoveryPage/>
  },
  {
    path: "/members",
    element: <MemberHomePage/>,
  },
  {
    path: "/events",
    element: <EventsPage/>
  },
  {
    path:"/experience",
    element: <ExperiencePage/>
  },
  {
    path:"/members/flight-log",
    element: <MemberFlightLogPage/>
  },
  {
    path:"/members/booking",
    element: <MemberBookingPage/>
  },
  {
    path:"/members/booking/new-booking",
    element: <MemberNewBookingPage/>
  },
  {
    path:"/members/community",
    element: <MemberCommunityPage/>
  },
  {
    path:"/members/community/post",
    element: <MemberCommuityPostPage/>
  },
  {
    path:"/members/booking/:bookingId",
    element: <MemberFlightPage/>
  },
  {
    path:"/members/profile/:id",
    element: <MemberProfilePage/>
  },
  {
    path:"/members/profile/edit",
    element: <MemberEditProfilePage/>
  },
  {
    path:"/admin/members",
    element: <AdminMemberPage/>
  },
  {
    path:"/admin/members/:member_id",
    element: <AdminMemberDetailPage/>
  },
  {
    path:"/admin/members/new-member",
    element: <AdminNewMemberPage/>,
  },
  {
    path:"/admin/bookings",
    element: <AdminBookingsPage/>
  },
  {
    path:"/admin/events",
    element: <AdminEventsPage/>
  },
  {
    path:"/admin/events/new-event",
    element: <AdminNewEventsPage/>
  },
  {
    path:"/admin/control-panel",
    element: <AdminControlPanelPage/>
  },
  {
    path:"/admin",
    element: <AdminMemberPage/>
  },
  {
    path:"/admin/booking/:booking_id",
    element: <AdminBookingDetailsPage/>
  },
  {
    path:"/admin/control-panel/support",
    element: <AdminSupportPage/>
  },
  {
    path:"/admin/control-panel/application",
    element: <AdminApplicationPage/>
  },
  {
    path:"/admin/events/:event_id",
    element: <AdminEditEventPage/>
  },
  {
    path:"/admin/fleet/new",
    element: <AdminNewFleetPage/>
  },
  {
    path:"/admin/fleet/:aircraft_id",
    element: <AdminEditFleetPage/>
  },
  {
    path: "/events/event-details/:event_id",
    element: <EventDetailPage/>
  },
  {
    path: "/admin/events/:event_id/signups",
    element: <AdminEventSignupPage/>
  },
  {
    path: "/members/event-details/:event_id/signup",
    element: <MemberEventSignupPage/>
  },
  {
    path: "/maintenance",
    element: <MaintenancePage/>
  },
  {
    path: "/test",
    element: <TestPage/>
  },
  {
    path: "*",
    element: <Index404Page/>,
  },
]);

ReactDOM.createRoot(document.getElementById("root")).render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
