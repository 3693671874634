import '../css/components.css';
import {Link} from 'react-router-dom';
import { useEffect, useState } from 'react';
import { APPDATA } from '../app-config';


export default function MemberHeader({selected}){

    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    const [userData, setUserData] = useState()
    const [showMenu, setShowMenu] = useState(false)
    const [menuTop, setMenuTop] = useState(0)
    const [menuRight, setMenuRight] = useState(0)

    window.onresize = () => {
        setWindowWidth(window.innerWidth);
        console.log(windowWidth);
    }

    useEffect(() => {
        establishSession()
    }, [])

    const establishSession = async() =>{
        let endpoint = APPDATA.server + "/account/cookie/member"
        const fetchResponse = await fetch(endpoint, {
            method: 'POST',
            credentials: "include",
            headers: {
                'Content-Type': 'application/json',
                "Accept": "application/json",
                // "Authorization": "Bearer " + JSON.parse(window.sessionStorage.getItem("accessToken")).token,
                // "x-access-token": JSON.parse(window.sessionStorage.getItem("accessToken")).token

            },
        })
        const data = await fetchResponse.json()
        console.log("here",data)
        if (data.status !== 200){
            window.location.href = "/account"
            alert("Your Cookie has expired. Please log in again.")
        }else{
            
            let cookie = document.cookie.split('; ').reduce((prev, current) => {
                const [name, ...value] = current.split('=');
                prev[name] = value.join('=');
                return prev;
              }, {});
        }
    }

    document.addEventListener("scroll", (e) => {
        setShowMenu(false)
    })

    window.scroll = () =>{
        setShowMenu(false)
    }

    useEffect(() => {
        // setUserData(getCookie("user")?JSON.parse(getCookie("user")):null);
        setUserData(window.sessionStorage.getItem("accessToken")? JSON.parse(window.sessionStorage.getItem("accessToken")) : null);
    }, [])

    useEffect(() => {
        establishSession();
    }, [userData])


    function getCookie(name) {
        let decodedCookie = decodeURIComponent(document.cookie)
        let ca = decodedCookie.split(';');
        let data;
        for (let i = 0; i < ca.length; i++) {
            let c = ca[i];
            while (c.charAt(0) == ' ') {
                c = c.substring(1);
            }
            if (c.indexOf(name) == 0) {
                data = c.substring(name.length, c.length);
                break

            }

        }

        if (data) {
            data = data.replace("=s:", "").slice(0, data.indexOf("}") - 2)
            return data
        }
    }


    const logout = async () => {
        let endpoint = APPDATA.server + "/account/logout"
        let fetchResponse = await fetch(endpoint, {
            method: "POST",
            credentials: "include",
            headers: {
                "Content-Type": "application/json"
            }
        })
        let response = await fetchResponse.json()
        // delete cookie
        document.cookie = "user=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
        document.cookie = "jwtToken=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
        window.sessionStorage.clear();
        window.location.href = "/"
    }

    return (
        <>
        <div className="header" style={{position:"initial"}}>
            <div className="innerHeader">
                <Link to={"/members"}><img src="/img/logo.png" alt="" className='logo' /></Link>
                <div className="memberHeaderRight">
                    <h1>{windowWidth > 800? "Republic of Singapore Flying Club":"RSFC"}</h1>
                    <div className="headerSplit" onClick={(e) => {
                            setShowMenu(!showMenu)
                            setMenuTop(e.currentTarget.getBoundingClientRect().top + 25)
                            setMenuRight(e.currentTarget.style.right)
                        }}>
                            <img className='profileImage' src={`${APPDATA.server}/profile/${userData ? userData._id : ""}.jpeg`} alt="" />
                            <p className="username">Hello {userData ? userData.salutation : ""}</p>
                        </div>

                        {showMenu ?
                            <div className="Menu" style={{ top: menuTop, right:"0px" }}>
                                <ul onMouseLeave={()=>setShowMenu(false)}>
                                    {["super-admin","admin"].includes(userData.account_type)?<li onClick={()=>{window.location.href="/admin"}}>Admin Dashboard</li>:null}
                                    <li onClick={()=>{window.location.href = "/members/profile/edit"}}>My Profile</li>
                                    <li onClick={()=>logout()}>Logout</li>
                                </ul>
                            </div> : null}
                </div>
            </div>
        </div>
        <div className="navbar">
            <div className="innerNavbar">
                <Link style={{color:selected === "Home"? "white":"black", backgroundColor:selected === "Home"?"#1B4965":"none", background:selected === "Home"? "":"none"}} className='navbarItems' to={"/members"}>Home</Link>
                <Link style={{color:selected === "Log"? "white":"black", backgroundColor:selected === "Log"?"#1B4965":"none", background:selected === "Log"? "":"none"}} className='navbarItems' to={"/members/flight-log"}>Flight Log</Link>
                <Link style={{color:selected === "Booking"? "white":"black", backgroundColor:selected === "Booking"?"#1B4965":"none", background:selected === "Booking"? "":"none"}} className='navbarItems' to={"/members/booking"}>Booking</Link>
                <Link style={{color:selected === "Community"? "white":"black", backgroundColor:selected === "Community"?"#1B4965":"none", background:selected === "Community"? "":"none"}} className='navbarItems' to={"/members/community"}>Community</Link>
            </div>
        </div>
        </>
    );
}