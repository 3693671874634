import AdminHeader from "../components/AdminHeader";
import Footer from "../components/Footer";
import "../css/admin-edit-fleet-page.css"
import { APPDATA } from "../app-config";
import { useLocation } from "react-router-dom";

import React, { useEffect, useState } from 'react';


const AdminEditFleetPage = () => {

    const { state } = useLocation()
    console.log("here", state)

    const [uploadFiles, setUploadFiles] = useState()
    const [imagePath, setImagePath] = useState()
    const [isLoading, setIsLoading] = useState(false)
    const [aircraft_name, setAircraft_name] = useState(state.aircraft_name)
    const [aircraft_model, setAircraft_model] = useState(state.aircraft_model)
    const [aircraft_flight_cycles, setAircraft_flight_cycles] = useState(state.aircraft_flight_cycles)
    const [aircraft_flight_hours, setAircraft_flight_hours] = useState(state.aircraft_flight_hours)
    const [aircraft_age, setAircraft_age] = useState(state.aircraft_age)
    const [aircraft_capacity, setAircraft_capacity] = useState(state.aircraft_capacity)
    const [aircraft_status, setAircraft_status] = useState(state.aircraft_status)
    const [aircraft_downtime, setAircraft_downtime] = useState(state.aircraft_downtime)
    const [aircraft_remarks, setAircraft_remarks] = useState(state.aircraft_remarks)
    const [aircraft_registration, setAircraft_registration] = useState(state.aircraft_registration)
    const [aircraft_description, setAircraft_description] = useState(state.aircraft_description)
    const [range, setRange] = useState(state.range)
    const [speed, setSpeed] = useState(state.speed)
    const [modalPurpose, setModalPurpose] = useState("new")
    const [selectedIndex, setSelectedIndex] = useState()


    const [windowWidth, setWindowWidth] = useState(window.innerWidth)

    const [showModalView, setShowModalView] = useState(false)

    const [startDate, setStartDate] = useState()
    const [endDate, setEndDate] = useState()
    const [startTime, setStartTime] = useState()
    const [endTime, setEndTime] = useState()
    const [remarks, setRemarks] = useState()

    const uploadFile = async (e) => {
        // get user to select file
        var input = document.createElement('input');
        input.type = 'file';
        input.id = "meppFile"
        input.click();

        // get file
        input.onchange = async (e) => {
            var file = e.target.files[0];

            var formData = new FormData();
            formData.append('image', file);

            // upload file
            let endpoint = APPDATA.server + "/fleet/upload-image/" + state._id
            let fetchResponse = await fetch(endpoint, {
                method: "POST",

                credentials: "include",
                headers: {

                },
                body: formData
            })
            let response = await fetchResponse.json()
            if (response.status === 200) {
                window.location.href = "/admin/control-panel"
                alert("Aircraft Image Uploaded")

            } else {
                alert("Profile Image Failed to upload")
            }
        }
    }

    const submitDownTime = async () => {
        let endpoint = APPDATA.server + "/fleet/downtime/" + state._id
        let fetchResponse = await fetch(endpoint, {
            credentials: "include",
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                startDate: startDate,
                endDate: endDate,
                startTime: startTime,
                endTime: endTime,
                remarks: remarks
            })
        })
        let response = await fetchResponse.json()
        if (response.status === 200) {
            setAircraft_downtime(response.data.aircraft_downtime)
            window.location.reload()
            alert("Downtime Added")
        } else {
            alert("Downtime Failed to Add")
        }
    }

    const fetchAircraftDownTime = async () => {
        let endpoint = APPDATA.server + "/fleet/downtime/" + state._id
        let fetchResponse = await fetch(endpoint, {
            credentials: "include",
            method: "GET",
            headers: {
                "Content-Type": "application/json"
            }
        })
        let response = await fetchResponse.json()
        if (response.status === 200) {
            setAircraft_downtime(response.data)
        }
    }

    const deleteDowntime = async (index) => {
        let current = aircraft_downtime
        current.splice(index, 1)
        let endpoint = APPDATA.server + "/fleet/downtime/" + state._id
        let fetchResponse = await fetch(endpoint, {
            credentials: "include",
            method: "DELETE",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                downtime: current
            })
        })
        let response = await fetchResponse.json()
        if (response.status === 200) {
            console.log(response.data)
            setAircraft_downtime(response.data.aircraft_downtime ? response.data.aircraft_downtime : [])
            window.location.reload()
            alert("Downtime Deleted")
        }
    }

    const updateDownTime = async () => {
        let current = aircraft_downtime
        current[selectedIndex] = {
            start_date: startDate,
            end_date: endDate,
            start_time: startTime,
            end_time: endTime,
            remarks: remarks
        }

        console.log("here1", current)
        let endpoint = APPDATA.server + "/fleet/downtime/" + state._id
        let fetchResponse = await fetch(endpoint, {
            credentials: "include",
            method: "PUT",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                downtime: current
            })
        })
        let response = await fetchResponse.json()
        if (response.status === 200) {
            console.log(response.data)
            setAircraft_downtime(response.data.aircraft_downtime)
            window.location.reload()
            alert("Downtime Updated")
        }
    }


    const updateAircraft = async () => {
        let endpoint = APPDATA.server + "/fleet/update/" + state._id
        let data = {
            aircraft_name: aircraft_name,
            aircraft_model: aircraft_model,
            aircraft_flight_cycles: aircraft_flight_cycles,
            aircraft_flight_hours: aircraft_flight_hours,
            aircraft_age: aircraft_age,
            aircraft_capacity: aircraft_capacity,
            aircraft_status: aircraft_status,
            aircraft_remarks: aircraft_remarks,
            aircraft_registration: aircraft_registration,
            aircraft_description: aircraft_description,
            range: range,
            speed: speed,
        }
        let fetchResponse = await fetch(endpoint, {
            method: "POST",
            credentials: "include",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify(data)
        })
        let response = await fetchResponse.json()

        if (response.status === 200) {
            window.location.href = "/admin/control-panel"
            alert("Aircraft updated successfully")
        } else {
            alert("Unable to update aircraft")
        }
    }


    const deleteAircraft = async () => {
        let endpoint = APPDATA.server + "/fleet/delete/" + state._id
        let data = {
            aircraft_id: state._id
        }
        let fetchResponse = await fetch(endpoint, {
            method: "POST",
            credentials: "include",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify(data)
        })
        let response = await fetchResponse.json()

        if (response.status === 200) {
            window.location.href = "/admin/control-panel"
            alert("Aircraft deleted successfully")
        } else {
            alert("Unable to delete aircraft")
        }
    }

    useEffect(() => {
        fetchAircraftDownTime()
    }, [])

    window.addEventListener("resize", () => {
        setWindowWidth(window.innerWidth)
    })

    return (
        <div>
            <AdminHeader selected={"Control Panel"} />
            <div className="anfpContainer">
                <div className="anfpInnerContainer">
                    <h1 className="anfpTitle">Edit Aircraft Data</h1>
                    {/* <p>{`${APPDATA.server}${(state.aircraft_image).toString().replace("public","")}`}</p> */}
                    <h2>Aircraft Image</h2>
                    <div className="aefpImageContainer" onClick={() => uploadFile()}>

                        <img src={`${APPDATA.server}${(state.aircraft_image).toString().replace("public", "")}`} alt="aircraft" className="anepImage" />
                    </div>
                    <h2>Aircraft Details</h2>
                    <div className="anfpFormContainer">
                        <div className="anfpSplitLeft">
                            <div className="anfpForm">
                                <p className="anfpInformation">Aircraft Name</p>
                                <input type="text" className="anfpInput" value={aircraft_name} onChange={(e) => setAircraft_name(e.target.value)} />
                            </div>
                            <div className="anfpForm">
                                <p className="anfpInformation">Aircraft Model</p>
                                <input type="text" className="anfpInput" value={aircraft_model} onChange={(e) => setAircraft_model(e.target.value)} />
                            </div>
                            <div className="anfpForm">
                                <p className="anfpInformation">Aircraft Registration</p>
                                <input type="text" className="anfpInput" value={aircraft_registration} onChange={(e) => setAircraft_registration(e.target.value)} />
                            </div>
                            <div className="anfpForm">
                                <p className="anfpInformation">Aircraft Capacity</p>
                                <input type="text" className="anfpInput" value={aircraft_capacity} onChange={(e) => setAircraft_capacity(e.target.value)} />
                            </div>
                            <div className="anfpForm">
                                <p className="anfpInformation">Aircraft Range (Km)</p>
                                <input type="text" className="anfpInput" value={range} onChange={(e) => setRange(e.target.value)} />
                            </div>
                            <div className="anfpForm">
                                <p className="anfpInformation">Aircraft Speed (Km/H)</p>
                                <input type="text" className="anfpInput" value={speed} onChange={(e) => setSpeed(e.target.value)} />
                            </div>

                        </div>
                        <div className="anfpSplitRight">
                            <div className="anfpForm">
                                <p className="anfpInformation">Flight Cycle</p>
                                <input type="text" className="anfpInput" value={aircraft_flight_cycles} onChange={(e) => setAircraft_flight_cycles(e.target.value)} />
                            </div>
                            <div className="anfpForm">
                                <p className="anfpInformation">Flight Hours</p>
                                <input type="text" className="anfpInput" value={aircraft_flight_hours} onChange={(e) => setAircraft_flight_hours(e.target.value)} />
                            </div>
                            <div className="anfpForm">
                                <p className="anfpInformation">Aircraft Age</p>
                                <input type="text" className="anfpInput" value={aircraft_age} onChange={(e) => setAircraft_age(e.target.value)} />
                            </div>
                            <div className="anfpForm">
                                <p className="anfpInformation">Aircraft Status</p>
                                <select name="" className="anfpInput" value={aircraft_status} onChange={(e) => setAircraft_status(e.target.value)}>
                                    <option value="active">Active</option>
                                    <option value="inactive">Inactive</option>
                                    <option value="under-maintenance">Under Maintenance</option>
                                    <option value="decommissioned">Decommissioned</option>
                                </select>
                            </div>
                        </div>
                    </div>
                    <div className="anfpFormContainer">
                        <div className="anfpSplitLeft">
                            <p className="anfpInformation">Description</p>
                            <textarea name="" id="" cols="30" rows="10" className="anfpRemarks" value={aircraft_description} onChange={(e) => setAircraft_description(e.target.value)} ></textarea>
                        </div>
                        <div className="anfpSplitRight">
                            <p className="anfpInformation">Remarks</p>
                            <textarea name="" id="" cols="30" rows="10" className="anfpRemarks" value={aircraft_remarks} onChange={(e) => setAircraft_remarks(e.target.value)} ></textarea>
                        </div>
                    </div>

                    <div className="aefpSplitContainer">
                        <h2>Aircraft Down Time</h2>
                        <a onClick={() => { setShowModalView(true); setModalPurpose("new"); setSelectedIndex(null) }} className="aefpBlockTime">+ add block time</a>
                    </div>

                    <p>You may configure this section to block out times available for booking. You may choose to use it to perform scheduled maintenance etc</p>
                    <div className="spTable">
                        {windowWidth > 1200 ? <>
                            <div className="spTableHeader">
                                <p className="spTableHeaderItem" style={{ width: "10%" }}>S/N</p>
                                <p className="spTableHeaderItem" style={{ width: "40%" }}>Start</p>
                                <p className="spTableHeaderItem" style={{ width: "40%" }}>End</p>
                                <p className="spTableHeaderItem" style={{ width: "50%" }}>Remarks</p>
                                <p className="spTableHeaderItem" style={{ width: "30%" }}></p>
                            </div>


                            {aircraft_downtime ? aircraft_downtime.map((downtime, index) => {
                                if (new Date(downtime.end_date) > new Date()) {
                                    return (
                                        <div className="spTableRow">
                                            <p className="spTableItem" style={{ width: "10%" }}>{index + 1}</p>
                                            <p className="spTableItem" style={{ width: "40%" }}>{downtime.start_date} {downtime.start_time}</p>
                                            <p className="spTableItem" style={{ width: "40%" }}>{downtime.end_date} {downtime.end_time}</p>
                                            <p className="spTableHeaderItem" style={{ width: "50%" }}>{downtime.remarks}</p>
                                            <div className="spActionButton" style={{ width: "30%" }}>
                                                <a id="mbpViewButton" onClick={() => {
                                                    setStartDate(downtime.start_date)
                                                    setStartTime(downtime.start_time)
                                                    setEndDate(downtime.end_date)
                                                    setEndTime(downtime.end_time)
                                                    setRemarks(downtime.remarks)
                                                    setShowModalView(true)
                                                    setModalPurpose('edit')
                                                    setSelectedIndex(index)
                                                }}>view</a>
                                            </div>
                                        </div>
                                    )
                                }

                            }) : <p>loading...</p>}
                        </> :
                            <>
                                {aircraft_downtime ? aircraft_downtime.map((downtime, index) => {
                                    return (
                                        <div className="spSmallTable">
                                            <div className="spSmallTableRow">
                                                <p className="spSmallTableItem">S/N</p>
                                                <p className="spSmallTableItem">{index + 1}</p>
                                            </div>
                                            <div className="spSmallTableRow">
                                                <p className="spSmallTableItem">Start</p>
                                                <p className="spSmallTableItem">{downtime.start_date} {downtime.start_time}</p>
                                            </div>
                                            <div className="spSmallTableRow">
                                                <p className="spSmallTableItem">End</p>
                                                <p className="spSmallTableItem">{downtime.end_date} {downtime.end_time}</p>
                                            </div>
                                            <div className="spSmallTableRow">
                                                <p className="spSmallTableItem">Remarks</p>
                                                <p className="spSmallTableItem">{downtime.remarks}</p>
                                            </div>
                                            <div className="spActionButton">
                                                <a id="mbpViewButton" onClick={() => {
                                                    setStartDate(downtime.start_date)
                                                    setStartTime(downtime.start_time)
                                                    setEndDate(downtime.end_date)
                                                    setEndTime(downtime.end_time)
                                                    setRemarks(downtime.remarks)
                                                    setShowModalView(true)
                                                    setModalPurpose('edit')
                                                    setSelectedIndex(index)
                                                }} >view</a>
                                            </div>
                                        </div>
                                    )
                                }) : null}

                            </>
                        }

                    </div>


                    {/* Modal View */}
                    {showModalView ? <div className="aefpModal">
                        <div className="aefpModalContainer">
                            <div className="aefpModalHeader">
                                <h1 className="aefpModalTitle">{modalPurpose === "new" ? "Add Block Time" : "Edit Downtime"}</h1>
                                <a href="" className="aefpModalClose" onClick={(e) => { e.preventDefault(); setShowModalView(false) }}>X</a>
                            </div>
                            <div className="aefpModalBody">
                                <div className="aefpModalForm">
                                    <p className="aefpModalInformation">Start Date</p>
                                    <input type="date" className="aefpModalInput" value={startDate} onChange={(e) => setStartDate(e.target.value)} />
                                </div>
                                <div className="aefpModalForm">
                                    <p className="aefpModalInformation">Start Time</p>
                                    <input type="time" className="aefpModalInput" value={startTime} onChange={(e) => setStartTime(e.target.value)} />
                                </div>
                                <div className="aefpModalForm">
                                    <p className="aefpModalInformation">End Date</p>
                                    <input type="date" className="aefpModalInput" value={endDate} onChange={(e) => setEndDate(e.target.value)} />
                                </div>
                                <div className="aefpModalForm">
                                    <p className="aefpModalInformation">End Time</p>
                                    <input type="time" className="aefpModalInput" value={endTime} onChange={(e) => setEndTime(e.target.value)} />
                                </div>
                                <div className="aefpRemarksContainer">
                                    <p className="aefpModalInformation" style={{ width: "100%" }}>Remarks</p>
                                    <textarea name="" id="" cols="30" rows="10" className="aefpModalRemarks" value={remarks} onChange={(e) => setRemarks(e.target.value)} ></textarea>
                                </div>
                            </div>
                            <div className="aefpModalFooter">
                                {modalPurpose === "new" ? <>
                                    <button className="aefpModalButton" onClick={(e) => { e.preventDefault(); setShowModalView(false); }}>Cancel</button>
                                    <button className="aefpModalButton" onClick={(e) => { e.preventDefault(); setShowModalView(false); submitDownTime() }}>Save</button>
                                </> :
                                    <>
                                        <button className="aefpModalButton" onClick={(e) => { e.preventDefault(); setShowModalView(false); deleteDowntime() }}>Delete</button>
                                        <button className="aefpModalButton" onClick={(e) => { e.preventDefault(); setShowModalView(false); updateDownTime() }}>Update</button>
                                    </>}
                            </div>
                        </div>
                    </div> : null}


                    {/* Button Container */}
                    <div className="anfpButtonContainer">
                        <button className="anfpButton" style={{ backgroundColor: "red", color: "white" }} onClick={deleteAircraft}>Delete</button>
                        <button className="anfpButton" onClick={updateAircraft}>Save</button>
                    </div>

                </div>
            </div>
            <Footer />
        </div>
    );
}

export default AdminEditFleetPage;