import { Link, useLocation } from "react-router-dom";
import AdminHeader from "../components/AdminHeader";
import Footer from "../components/Footer";
import { APPDATA } from "../app-config";
import { useState, useEffect } from "react";
import "../css/admin-new-events-page.css";

import { IoClose } from "react-icons/io5";




const AdminNewEventsPage = () => {

    const [title, setTitle] = useState()
    const [eventStartDate, setEventStartDate] = useState()
    const [eventEndDate, setEventEndDate] = useState()
    const [price, setPrice] = useState()
    const [type, setType] = useState("gathering")
    const [participants, setParticipants] = useState()
    const [description, setDescription] = useState()
    const [uploadFiles, setUploadFiles] = useState()
    const [imagePath, setImagePath] = useState()
    const [isLoading, setIsLoading] = useState(false)

    const [questions, setQuestions] = useState("Name||Email||Phone Number")


    const uploadEvent = async (path) => {
        if (!isNaN(price)) {
            let endpoint = APPDATA.server + "/events/new-event"
            let data = {
                event_image: path,
                event_start_date: eventStartDate,
                event_end_date: eventEndDate,
                event_title: title,
                event_description: description,
                event_price: price,
                event_type: type,
                event_participants: participants,
                questions: questions

            }
            let fetchResponse = await fetch(endpoint, {
                method: "POST",
                credentials: "include",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(data)
            })
            let response = await fetchResponse.json()

            if (response.status === 200) {
                window.location.href = "/admin/events"
                alert("Event published successfully")
                window.location.href = "/admin/events"
            } else {
                alert("Event failed to publish")
            }
        } else {
            alert("Invalid field")
        }
    }

    const uploadImage = async () => {
        let endpoint = APPDATA.server + "/events/upload-image"
        let formData = new FormData()
        console.log(endpoint)

        formData.append("image", uploadFiles)
        let fetchResponse = await fetch(endpoint, {
            method: "POST",
            body: formData
        })
        let response = await fetchResponse.json()
        if (response.status === 200) {
            console.log(response.data)
            setImagePath(response.data)
            uploadEvent(response.data.file_path)
        } else {
            alert("Error, unable to upload image")
        }
    }

    useEffect(() => {
        setIsLoading(false)
    }, [questions])



    return (
        <div>
            <AdminHeader selected={"Events"} />
            <div className="anepContainer">
                <div className="anepInnerContainer">
                    <div className="anepHeader">
                        <h1 className="anepTitle">New event</h1>
                        <Link id="newEventButton" to={"/admin/events"}>cancel</Link>
                    </div>


                    <h2>Event Details</h2>
                    <div className="anepImageContainer">
                        <h2 className="anepLabel">Upload Image</h2>
                        <label className="anepLabel">Drag & Drop Image here</label>
                        <input className="anepUploadContent" onChange={(e) => setUploadFiles(e.target.files[0])} type={"file"} name={'uploadFiles'} />
                    </div>
                    <div className="anepSplitContainer">
                        <div className="anepSplit">

                            <div className="anepFieldContainer">
                                <label className="anepLabel">Title</label>
                                <input className="anepInput" value={title} onChange={(e) => setTitle(e.target.value)} />
                            </div>
                            <div className="anepFieldContainer">
                                <label className="anepLabel">Start Date</label>
                                <input className="anepInput" type="date" value={eventStartDate} onChange={(e) => setEventStartDate(e.target.value)} />
                            </div>
                            <div className="anepFieldContainer">
                                <label className="anepLabel">End Date</label>
                                <input className="anepInput" type="date" value={eventEndDate} onChange={(e) => setEventEndDate(e.target.value)} />
                            </div>
                            <div className="anepFieldContainer">
                                <label className="anepLabel">Price</label>
                                <input className="anepInput" value={price} onChange={(e) => setPrice(e.target.value)} />

                            </div>
                        </div>
                        <div className="anepSplit2">
                            <div className="anepFieldContainer">
                                <label className="anepLabel">Maximum participants</label>
                                <input className="anepInput" value={participants} onChange={(e) => setParticipants(e.target.value)} />
                            </div>
                            <div className="anepFieldContainer">
                                <label className="anepLabel">Type</label>
                                <select className="anepTypeSelection" name="type" id={type} onChange={(e) => setType(e.target.value)} >
                                    <option value="Gathering">Gathering</option>
                                    <option value="Seminar">Seminar</option>
                                    <option value="Class">Class</option>
                                    <option value="Flight">Flight</option>
                                    <option value="Tour">Tour</option>
                                    <option value="Learning Trip">Learning Trip</option>
                                    <option value="Other">Other</option>
                                </select>
                            </div>
                            <div className="anepFieldContainer">
                                <label className="anepLabel">Description</label>
                                <textarea className="eventDescription" value={description} onChange={(e) => setDescription(e.target.value)} ></textarea>
                            </div>

                        </div>
                    </div>

                    <h2>Respondees Question</h2>
                    <div className="anepQuestions">
                        {!isLoading  &&questions.length>0? questions.split("||").map((question, index) => {
                            return (
                                <div className="anepQuestionContainer">
                                    <div className="anepQuestionLabelContainer">
                                        <label className="anepLabel" style={{ paddingLeft: 10 }}>Question {index + 1}</label>
                                        <IoClose color={"Black"} size={30} onClick={() => {
                                            let newQuestions = questions.split("||")
                                            newQuestions.splice(index, 1)
                                            setQuestions(newQuestions.join("||"))
                                        }} />
                                    </div>
                                    <input
                                        className="anepInput"
                                        value={question}
                                        onChange={(e) => {
                                            let newQuestions = questions.split("||")
                                            newQuestions[index] = e.target.value
                                            setQuestions(newQuestions.join("||"))

                                        }}

                                        onKeyDown={(e) => {
                                            if (e.key === "Enter"&& questions[questions.length-1] !== "|") {

                                                setQuestions(questions + "||")

                                            }
                                        }}
                                    />
                                </div>
                            )
                        }) : null}
                    </div>




                </div>
                <div className="mfdButtonContainer">
                    <button className="mfdButton" onClick={() => uploadImage()}>Publish new event</button>
                </div>
            </div>
            <Footer />
        </div>
    );
}

export default AdminNewEventsPage;