import '../css/components.css';
import { Link } from 'react-router-dom';
import { useState, useEffect } from 'react';
import { APPDATA } from "../app-config";
import { MdLogout } from 'react-icons/md';
import MenuItem from '@mui/material/MenuItem';
import MenuList from '@mui/material/MenuList';




export default function AdminHeader({ selected }) {

    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    const [userData, setUserData] = useState()
    const [showMenu, setShowMenu] = useState(false)
    const [menuTop, setMenuTop] = useState(0)
    const [menuRight, setMenuRight] = useState(0)

    const [imageHealth, setImageHealth] = useState(true)

    window.onresize = () => {
        setWindowWidth(window.innerWidth);
        console.log(windowWidth);
    }

    document.addEventListener("scroll", (e) => {
        setShowMenu(false)
    })

    window.scroll = () => {
        setShowMenu(false)
    }

    useEffect(() => {
        // setUserData(getCookie("user") ? JSON.parse(getCookie("user")) : null);
        setUserData(window.sessionStorage.getItem("accessToken")? JSON.parse(window.sessionStorage.getItem("accessToken")) : null);
    }, [])

    useEffect(() => {
        establishSession();
        console.log("establishing session")
    }, [userData])


    function getCookie(name) {
        let decodedCookie = decodeURIComponent(document.cookie)
        let ca = decodedCookie.split(';');
        let data;
        for (let i = 0; i < ca.length; i++) {
            let c = ca[i];
            while (c.charAt(0) == ' ') {
                c = c.substring(1);
            }
            if (c.indexOf(name) == 0) {
                data = c.substring(name.length, c.length);
                break

            }

        }

        if (data) {
            data = data.replace("=s:", "").slice(0, data.indexOf("}") - 2)
            return data
        }
    }


    const logout = async () => {
        let endpoint = APPDATA.server + "/account/logout"
        let fetchResponse = await fetch(endpoint, {
            method: "POST",
            credentials: "include",
            headers: {
                "Content-Type": "application/json"
            }
        })
        let response = await fetchResponse.json()
        // delete cookie
        document.cookie = "user=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
        document.cookie = "jwtToken=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
        window.sessionStorage.clear();
        window.location.href = "/"
    }

    const establishSession = async () => {
        if (userData) {


            if (["super-admin", "admin"].includes(userData.account_type)) {
                let endpoint = APPDATA.server + "/account/cookie/admin"
                const fetchResponse = await fetch(endpoint, {
                    method: 'POST',
                    credentials: "include",
                    headers: {
                        'Content-Type': 'application/json',
                        "Accept": "application/json",
                        // "Authorization": "Bearer " + JSON.parse(window.sessionStorage.getItem("accessToken")).token,
                        // "x-access-token": JSON.parse(window.sessionStorage.getItem("accessToken")).token
                    },
                })
                const data = await fetchResponse.json()
                if (data.status === 400) {
                    window.location.href = "/account"
                    alert("Unauthorized Access")
                } else if (data.status !== 200) {
                    window.location.href = "/account"
                    alert("Cookie has expired, you will need to login again")
                }
            } else {
                window.location.href = "/account"
                alert("Unauthorized Access")
            }
        } 
    }

    return (
        <>
            <div className="header" style={{ position: "initial" }}>
                <div className="innerHeader">
                    <Link to={"/admin/members"}><img src="/img/logo.png" alt="" className='logo' /></Link>
                    <div className="memberHeaderRight">
                        <h1>{windowWidth > 800 ? "Republic of Singapore Flying Club" : "RSFC"}</h1>
                        <div className="headerSplit" onClick={(e) => {
                            setShowMenu(!showMenu)
                            setMenuTop(e.currentTarget.getBoundingClientRect().top + 25)
                            setMenuRight(e.currentTarget.style.right)
                        }}>
                            <img className='profileImage' id='profile-image' src={`${APPDATA.server}/profile/${userData ? userData._id : ""}.jpeg`} alt="" />
                            <p className="username">Hello {userData ? userData.salutation : ""}</p>
                        </div>

                        {showMenu ?
                            <div className="Menu" style={{ top: menuTop, right: "0px" }}>
                                <ul onMouseLeave={() => showMenu(false)}>
                                    <li onClick={() => { window.location.href = "/members" }}>Member's Corner</li>
                                    <li onClick={() => logout()}>Logout</li>
                                </ul>
                            </div> : null}
                    </div>
                </div>
            </div>
            <div className="navbar">
                <div className="innerNavbar">
                    <Link style={{ color: selected === "Members" ? "white" : "black", backgroundColor: selected === "Members" ? "#1B4965" : "none", background: selected === "Members" ? "" : "none" }} className='navbarItems' to={"/admin/members"}>Members</Link>
                    <Link style={{ color: selected === "Bookings" ? "white" : "black", backgroundColor: selected === "Bookings" ? "#1B4965" : "none", background: selected === "Bookings" ? "" : "none" }} className='navbarItems' to={"/admin/bookings"}>Bookings</Link>
                    <Link style={{ color: selected === "Events" ? "white" : "black", backgroundColor: selected === "Events" ? "#1B4965" : "none", background: selected === "Events" ? "" : "none" }} className='navbarItems' to={"/admin/events"}>Events</Link>
                    <Link style={{ color: selected === "Control Panel" ? "white" : "black", backgroundColor: selected === "Control Panel" ? "#1B4965" : "none", background: selected === "Control Panel" ? "" : "none" }} className='navbarItems' to={"/admin/control-panel"}>Control Panel</Link>
                </div>
            </div>


        </>
    );
}