

import "./Avatar.css";

const Avatar = (props) => {
    const icon = [
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32" class="react-chatbot-kit-chat-bot-icon">
            <path d="M16 0.052c-2.646 0-3.234 3.719-0.714 4.536l-0.844 3.438h-11.167c-1.813 0-3.276 1.469-3.276 3.276v17.37c0 1.813 1.464 3.276 3.276 3.276h25.448c1.807 0 3.276-1.469 3.276-3.276v-17.37c0-1.807-1.469-3.276-3.276-3.276h-11.203l-0.823-3.432c2.536-0.802 1.958-4.542-0.698-4.542zM5.245 10.51h21.516c1.469 0 2.656 1.188 2.656 2.656v13.49c0 1.469-1.188 2.656-2.656 2.656h-21.516c-1.469 0-2.661-1.188-2.661-2.656v-13.49c0-1.469 1.193-2.656 2.661-2.656zM9.583 13.589c-8.135 0-8.135 12.203 0 12.203 8.13 0 8.13-12.203 0-12.203zM22.573 13.589c-8.135 0-8.135 12.203 0 12.203s8.135-12.203 0-12.203zM9.583 15.016c2.609-0.042 4.755 2.063 4.755 4.677 0 2.609-2.146 4.719-4.755 4.672-6.13-0.104-6.13-9.245 0-9.349zM22.573 15.016c2.615-0.042 4.755 2.063 4.755 4.677 0 2.609-2.141 4.719-4.755 4.672-6.125-0.104-6.125-9.245 0-9.349zM9.583 17.234c-3.276 0-3.276 4.917 0 4.917s3.276-4.917 0-4.917zM22.573 17.234c-3.276 0-3.276 4.917 0 4.917s3.276-4.917 0-4.917zM13.339 25.901v2.88h0.943v-2.88zM14.771 25.901v2.88h0.943v-2.88zM16.208 25.901v2.88h0.943v-2.88zM17.646 25.901v2.88h0.938v-2.88zM19.078 25.943v2.797c0.651-0.151 1.104-0.729 1.109-1.396 0-0.682-0.474-1.25-1.109-1.401zM12.844 25.979c-0.583 0.198-0.974 0.745-0.974 1.359v0.005c0 0.63 0.406 1.167 0.974 1.359z" >

            </path>
        </svg>
    ]

console.log(props)
const Avataricon = icon
    return <div className="bot-avatar">{Avataricon}</div>;
};

export default Avatar;