import AdminHeader from "../components/AdminHeader";
import Footer from "../components/Footer";
import "../css/admin-member-page.css"
import { useState, useEffect } from "react";
import { APPDATA } from "../app-config";
import { Link, useNavigate } from "react-router-dom";

const AdminMemberPage = () => {
    const [members, setMembers] = useState([])
    const [loading, setLoading] = useState(true)
    const [windowWidth, setWindowWidth] = useState(window.innerWidth)
    const [search, setSearch] = useState()

    const [fullMember, setFullMember] = useState([])
    const navigate = useNavigate();

    useEffect(() => {
        let userData = window.sessionStorage.getItem("accessToken")
        let user = JSON.parse(userData)
        if (userData) {
            if (user.account_type === "admin" || user.account_type === "super-admin") {
                fetchMembers()
            } else {
                window.location.href = "/"
            }
        } else {
            window.location.href = "/"
        }

    }, [])

    const fetchMembers = async () => {
        let endpoint = APPDATA.server + "/members"
        let fetchResponse = await fetch(endpoint, {
            method: "GET",
            credentials: "include",
            withCredentials: true,
            headers: {
                "Content-Type": "application/json",
                // "Authorization": "Bearer " + JSON.parse(window.sessionStorage.getItem("accessToken")).token,
                // "x-access-token": JSON.parse(window.sessionStorage.getItem("accessToken")).token
            }
        })
        let response = await fetchResponse.json()
        console.log(response)
        if (response.status === 403) {
            window.location.href = "/account"
        } else if (response.status === 401) {
            window.location.href = "/account"
        } else {
            console.log(response)
            setMembers(response.data)
            setLoading(false)
            setFullMember(response.data)
        }
    }

    window.addEventListener("resize", () => {
        setWindowWidth(window.innerWidth)
    })

    useEffect(() => {
        if (search) {
            let filteredMembers = members.filter(member => {
                return JSON.stringify(member).toLowerCase().includes(search.toLowerCase())
            })
            setMembers(filteredMembers)
        } else {
            setMembers(fullMember)
        }
    }, [search])



    return (
        <div>
            <AdminHeader selected={"Members"} />
            <div className="ampContainer">
                <div className="ampInnerContainer">
                    <div className="ampSplit">
                        <h1 className="ampTitle">RSFC Members</h1>
                        <Link id="newMemberButton" to={"/admin/members/new-member"}>+ add member</Link>
                    </div>
                    <input style={{ marginBottom: "20px" }} type="search" placeholder="Search Member... (E.g.) John" onChange={(e) => setSearch(e.target.value)} />

                    {windowWidth > 1200 ? <>
                        <div className="ampTable">
                            <div className="ampTableHeader">
                                <p className="ampTableHeaderItem" style={{ width: "10%" }}>S/N</p>
                                <p className="ampTableHeaderItem" style={{ width: "30%" }}>Name</p>
                                <p className="ampTableHeaderItem" style={{ width: "30%" }}>Title</p>
                                <p className="ampTableHeaderItem" style={{ width: "30%" }}>Instructor</p>
                                <p className="ampTableHeaderItem" style={{ width: "30%" }}>Examiner</p>
                                <p className="ampTableHeaderItem" style={{ width: "30%" }}>Date Joined</p>
                                <p className="ampTableHeaderItem" style={{ width: "30%" }}></p>
                            </div>
                            {loading ? <p>Loading...</p> : members.map((member, index) => {
                                let date = new Date(member.date_joined)
                                let dateJoined = date.getDate() + "/" + (date.getMonth() + 1) + "/" + date.getFullYear()

                                return (
                                    <div className="ampTableRow">
                                        <p className="ampTableItem" style={{ width: "10%" }}>{index + 1}</p>
                                        <p className="ampTableItem" style={{ width: "30%" }}>{member.first_name} {member.last_name}</p>
                                        <p className="ampTableItem" style={{ width: "30%" }}>{member.title}</p>
                                        <p className="ampTableItem" style={{ width: "30%" }}>{member.instructor ? member.instructor : "-"}</p>
                                        <p className="ampTableItem" style={{ width: "30%" }}>{member.examiner ? member.examiner : "-"}</p>
                                        <p className="ampTableItem" style={{ width: "30%" }}>{dateJoined}</p>
                                        <div className="ampActionButton" style={{ width: "30%" }}>
                                            <a id="ampViewButton" onClick={() => {
                                                navigate("/admin/members/" + member._id, { state: member })
                                            }} to={"/admin/members/" + member._id}>view</a>
                                        </div>
                                    </div>
                                )

                            })}
                        </div>
                    </> : <>
                        <div className="ampSmallTableContainer">
                            {loading ? <p>Loading...</p> : members.map((member, index) => {
                                let date = new Date(member.date_joined)
                                let dateJoined = date.getDate() + "/" + (date.getMonth() + 1) + "/" + date.getFullYear()

                                return (
                                    <div className="ampSmallTable">
                                        <div className="ampSmallTableRow">
                                            <p className="ampSmallTableItem">S/N</p>
                                            <p className="ampSmallTableItem">{index + 1}</p>
                                        </div>
                                        <div className="ampSmallTableRow">
                                            <p className="ampSmallTableItem">Name</p>
                                            <p className="ampSmallTableItem">{member.first_name} {member.last_name}</p>
                                        </div>
                                        <div className="ampSmallTableRow">
                                            <p className="ampSmallTableItem">Title</p>
                                            <p className="ampSmallTableItem">{member.title}</p>
                                        </div>
                                        <div className="ampSmallTableRow">
                                            <p className="ampSmallTableItem">Instructor</p>
                                            <p className="ampSmallTableItem">{member.instructor ? member.instructor : "-"}</p>
                                        </div>
                                        <div className="ampSmallTableRow">
                                            <p className="ampSmallTableItem">Examiner</p>
                                            <p className="ampSmallTableItem">{member.examiner ? member.examiner : "-"}</p>
                                        </div>
                                        <div className="ampSmallTableRow" style={{ borderBottomWidth: "0px" }}>
                                            <p className="ampSmallTableItem">Date Joined</p>
                                            <p className="ampSmallTableItem">{dateJoined}</p>
                                        </div>
                                        <div className="ampSmallTableRow" style={{ borderBottomWidth: "0px", justifyContent: "center" }}>
                                            <div className="ampActionButton">
                                                <a id="ampViewButton" onClick={() => {
                                                    navigate("/admin/members/" + member._id, { state: member })
                                                }} to={"/admin/members/" + member._id}>view</a>
                                            </div>
                                        </div>
                                    </div>
                                )
                            })}
                        </div>
                    </>}
                </div>
            </div>
            <Footer />
        </div>
    );
}

export default AdminMemberPage;