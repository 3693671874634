import { useState, useEffect } from "react";

import AdminHeader from "../components/AdminHeader";
import "../css/admin-member-detail-page.css";
import "../css/member-edit-profile-page.css";

import { Link, useNavigate, useLocation } from "react-router-dom";
import Footer from "../components/Footer";
import { APPDATA } from "../app-config";
import MemberHeader from "../components/MemberHeader";

const MemberEditProfilePage = () => {

    const [state, setState] = useState({})

    if (state) {
        let date_joined = new Date(parseInt(state.date_joined))
        let date_of_birth = new Date(parseInt(state.date_of_birth))
        //convert to yyyy-mm-dd if single digit, make it double digit
        var date_joined_day = date_joined.getDate() < 10 ? "0" + date_joined.getDate() : date_joined.getDate()
        var date_joined_month = date_joined.getMonth() < 10 ? "0" + (date_joined.getMonth() + 1) : (date_joined.getMonth() + 1)
        var date_joined_year = date_joined.getFullYear()
        var date_joined_string = date_joined_year + "-" + date_joined_month + "-" + date_joined_day

        // var date_of_birth_day = date_of_birth.getDate() < 10 ? "0" + date_of_birth.getDate() : date_of_birth.getDate()
        // var date_of_birth_month = date_of_birth.getMonth() < 10 ? "0" + (date_of_birth.getMonth() + 1) : (date_of_birth.getMonth() + 1)
        // var date_of_birth_year = date_of_birth.getFullYear()
        // var date_of_birth_string = date_of_birth_year + "-" + date_of_birth_month + "-" + date_of_birth_day


    }




    const [firstName, setFirstName] = useState(state.first_name)
    const [lastName, setLastName] = useState(state.last_name)
    const [salutation, setSalutation] = useState(state.salutation)
    const [email, setEmail] = useState(state.email)
    const [phone, setPhone] = useState(state.contact_number)
    const [address, setAddress] = useState(state.address)
    const [username, setUsername] = useState(state.username)
    const [accountType, setAccountType] = useState(state.account_type)
    const [membershipeType, setMembershipeType] = useState(state.membership_type)
    const [dateJoined, setDateJoined] = useState(date_joined_string)
    const [dateOfBirth, setDateOfBirth] = useState(state.date_of_birth)
    const [examiner, setExaminer] = useState(state.examiner)
    const [instructor, setInstructor] = useState(state.instructor)
    const [flightHours, setFlightHours] = useState(state.flight_hours)
    const [flights, setFlights] = useState(state.flights)
    const [title, setTitle] = useState(state.title)
    const [licenseType, setLicenseType] = useState(state.license_type)
    const [licenseNumber, setLicenseNumber] = useState(state.license_number)
    const [accountRemarks, setAccountRemarks] = useState(state.account_remarks)
    const [userID, setUserID] = useState()

    const [submitDocuments, setSubmitDocuments] = useState()

    const [showModalView, setShowModalView] = useState(false)
    const [applicationType, setApplicationType] = useState("medical")
    const [applicationDate, setApplicationDate] = useState("")

    const [applicationDocuments, setApplicationDocuments] = useState([])

    const submitDocument = async() => {  
        let endpoint = APPDATA.server +"/application/submit-documents"
        let formData = new FormData()
        formData.append("user_id", userID)
        formData.append("application_type", applicationType)
        formData.append("application_date", applicationDate)
        
        for (let i = 0; i < applicationDocuments.length; i++) {
            formData.append("documents", applicationDocuments[i])
        }

        let fetchResponse = await fetch(endpoint, {
            method: "POST",
            credentials: "include",
            body: formData,
        })
        let response = await fetchResponse.json()
        if (response.status === 200){
            alert("Application has been submitted")
            setShowModalView(false)
            window.location.reload()
        }else{
            alert("Error submitting application")
        }
    }

    const navigate = useNavigate()

    const submitChanges = async () => {
        let endpoint = APPDATA.server + "/account/update/" + userID
        let fetchResponse = await fetch(endpoint, {
            method: "POST",
            credentials: "include",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                first_name: firstName,
                last_name: lastName,
                salutation: salutation,
                email: email,
                contact_number: phone,
                address: address,
                username: username,
                account_type: accountType,
                membership_type: membershipeType,
                date_joined: dateJoined,
                date_of_birth: dateOfBirth,
                examiner: examiner,
                instructor: instructor,
                flight_hours: flightHours,
                flights: flights,
                title: title,
                license_type: licenseType,
                license_number: licenseNumber,
                account_remarks: accountRemarks
            })
        })
        let response = await fetchResponse.json()
        console.log(response)
        if (response.status === 200) {
            alert("Changes Saved")
        } else {
            alert("Changes Failed")
        }
    }


    const fetchMemberDetails = async () => {
        let endpoint = APPDATA.server + "/members/" + JSON.parse(sessionStorage.getItem("accessToken"))._id
        let fetchResponse = await fetch(endpoint, {
            method: "GET",
            credentials: "include",
            headers: {
                "Content-Type": "application/json",
            }
        })
        let response = await fetchResponse.json()
        if (response.status === 200) {
            console.log(response)
            setFirstName(response.data.first_name)
            setLastName(response.data.last_name)
            setSalutation(response.data.salutation)
            setEmail(response.data.email)
            setPhone(response.data.contact_number)
            setAddress(response.data.address)
            setUsername(response.data.username)
            setAccountType(response.data.account_type)

            setMembershipeType(response.data.membership_type)
            setDateJoined(response.data.date_joined)
            setDateOfBirth(response.data.date_of_birth)
            setExaminer(response.data.examiner)
            setInstructor(response.data.instructor)
            setFlightHours(response.data.flight_hours)
            setFlights(response.data.flights)
            setTitle(response.data.title)
            setLicenseType(response.data.license_type)
            setLicenseNumber(response.data.license_number)
            setAccountRemarks(response.data.account_remarks)
            setUserID(response.data._id)

        }
    }


    useEffect(() => {
        fetchMemberDetails()
    }, [])

    const uploadFile = async (e) => {
        // get user to select file
        var input = document.createElement('input');
        input.type = 'file';
        input.id = "meppFile"
        input.click();

        // get file
        input.onchange = async (e) => {
            var file = e.target.files[0];
            console.log(file)
            var formData = new FormData();
            formData.append('image', file);

            // upload file
            let endpoint = APPDATA.server + "/members/upload/" + userID
            let fetchResponse = await fetch(endpoint, {
                method: "POST",
                headers: {
                    "Authorization": "Bearer " + JSON.parse(sessionStorage.getItem("accessToken")).token,
                    "x-access-token": JSON.parse(sessionStorage.getItem("accessToken")).token
                },
                body: formData
            })
            let response = await fetchResponse.json()
            console.log(response)
            if (response.status === 200) {
                window.location.reload()
                alert("Profile Image Uploaded")

            } else {
                alert("Profile Image Failed to upload")
            }
        }
    }

    return (
        <div>
            <MemberHeader selected={"Home"} />
            <div className="amdpContainer">
                <div className="amdpInnerContainer">
                    <div className="mppTopProfile" style={{ marginTop: 50 }}>
                        <img src={userID ? `${APPDATA.server}/profile/${userID}.jpeg` : ""} id='profileImage' className="meppProfileImage" alt="" onClick={() => uploadFile()} />
                    </div>
                    <p style={{fontWeight:"bold",textAlign:"center",fontSize:"20px"}}>{flights} flights | {flightHours} hours</p>

                    <h2>Name</h2>
                    <div className="amdpMemberInfoSplit">
                        <div className="amdpMemberInfoLeft">
                            <div className="amdpMemberInfo">
                                <p className="amdpMemberInfoTitle">First Name</p>
                                <input type="text" className="amdpMemberField" value={firstName} onChange={(e) => setFirstName(e.target.value)} />
                            </div>
                        </div>

                        <div className="amdpMemberInfoRight">
                            <div className="amdpMemberInfo">
                                <p className="amdpMemberInfoTitle">Last Name</p>
                                <input type="text" className="amdpMemberField" value={lastName} onChange={(e) => setLastName(e.target.value)} />
                            </div>
                        </div>
                    </div>
                    <div className="amdpMemberInfoSplit">
                        <div className="amdpMemberInfoLeft">
                            <div className="amdpMemberInfo">
                                <p className="amdpMemberInfoTitle">Preferred Name</p>
                                <input type="text" className="amdpMemberField" value={salutation} onChange={(e) => setSalutation(e.target.value)} />
                            </div>
                        </div>
                        <div className="amdpMemberInfoLeft">
                            <div className="amdpMemberInfo">
                                <p className="amdpMemberInfoTitle">Username</p>
                                <input type="text" className="amdpMemberField" value={username} onChange={(e) => setUsername(e.target.value)} />
                            </div>
                        </div>
                    </div>

                    <div className="amdpMemberInfoSplit">
                        <div className="amdpMemberInfoLeft">
                            <div className="amdpMemberInfo">
                                <p className="amdpMemberInfoTitle">Email Address</p>
                                <input type="text" className="amdpMemberField" value={email} onChange={(e) => setEmail(e.target.value)} />
                            </div>
                        </div>

                        <div className="amdpMemberInfoRight">
                            <div className="amdpMemberInfo">
                                <p className="amdpMemberInfoTitle">Contact Number</p>
                                <input type="text" className="amdpMemberField" value={phone} onChange={(e) => setPhone(e.target.value)} />
                            </div>
                        </div>
                    </div>

                    <div className="amdpMemberInfoSplit">
                        <div className="amdpMemberInfoLeft">
                            <div className="amdpMemberInfo">
                                <p className="amdpMemberInfoTitle">Date Of Birth</p>
                                <input type="date" className="amdpMemberField" value={dateOfBirth} onChange={(e) => setDateOfBirth(e.target.value)} />
                            </div>
                        </div>

                    </div>
                    <div className="residentialAddress">
                        <p className="amdpMemberInfoTitle">Residential Address</p>
                        <textarea type="text" className="amdpMemberField" style={{ width: "100%" }} value={address} onChange={(e) => setAddress(e.target.value)} />
                    </div>


                    {/* Modal View */}
                    {showModalView ? <div className="aefpModal">
                        <div className="aefpModalContainer">
                            <div className="aefpModalHeader">
                                <h1 className="aefpModalTitle">Submit Document</h1>
                                
                                <a href="" className="aefpModalClose" onClick={(e) => { e.preventDefault(); setShowModalView(false) }}>X</a>
                            </div>
                            <p>Upload an documents you have to renew your Pilot License. RSFC reserves the right to reject any application. You will need a valid Certificate of Expiry (COE) and Proof of Medical Checkup to make flight bookings</p>
                            <p>Typically, you will need both a COE and Medical Checkup</p>
                            <ul style={{color:"black",textAlign:"left",listStyle:"unset",marginLeft:"20px"}}>
                                <li style={{color:"black",textAlign:"left"}}>If you are 50 years old and above, you are required to go for a Medical Review every year, else it will be once every 2 years</li>
                                <li style={{color:"black",textAlign:"left"}}>COE Renewal is every year as long as you have met the minimum hours each year</li>
                            </ul>
                            <div className="aefpModalUploadForm">
                                <p>Upload Documents here</p>
                                <input type="file" multiple onChange={(e)=>{setApplicationDocuments(e.target.files);console.log(e.target.files)}} style={{border:"none",textAlign:"center",alignItems:"center",justifyContent:"center",display:"flex",flexDirection:"column"}} />
                            </div>

                            <div className="aefpModalBody">
                                <div className="aefpModalForm">
                                    <p className="aefpModalInformation">Application Type</p>
                                    <select className="aefpModalInput" value={applicationType} onChange={(e) => setApplicationType(e.target.value)} name="" id="">
                                        <option value="medical">Medical Renewal</option>
                                        <option value="license">License Renewal</option>
                                    </select>
                                </div>
                                <div className="aefpModalForm">
                                    <p className="aefpModalInformation">{applicationType === "medical" ? "Next Medical Appointment" : "Certicate of Expiry Date"}</p>
                                    <input type="date" className="aefpModalInput" value={applicationDate} onChange={(e) => setApplicationDate(e.target.value)} />
                                </div>
                            </div>
                            <div className="aefpModalFooter">
                                <button className="aefpModalButton" onClick={(e) => { e.preventDefault(); submitDocument() }}>Submit</button>
                            </div>
                        </div>
                    </div> : null}


                    <div className="amdpButtonContainer">
                        <button className="amdpButton" style={{ backgroundColor: "green" }} onClick={()=>setShowModalView(true)}>Submit Documents</button>
                        <button className="amdpButton" style={{ backgroundColor: "green" }} onClick={submitChanges}>Save Changes</button>
                    </div>

                </div>
            </div>
            <Footer />
        </div>
    );
}

export default MemberEditProfilePage;