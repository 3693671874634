import '../css/components.css';
import {Link} from 'react-router-dom';
import { useState,useEffect } from 'react';
import { APPDATA } from "../app-config";
import {GiHamburgerMenu} from 'react-icons/gi';

export default function Header() {
    const [showHamburger, setShowHamburger] = useState(false)
    const [windowWidth, setWindowWidth] = useState(window.innerWidth)
    const [headerImage, setHeaderImage] = useState("/logo.png")

    const [showMiniNav, setShowMiniNav] = useState(false)
    const [announcement, setAnnouncement] = useState()
    const [announcementStatus, setAnnouncementStatus] = useState()

    // window.onresize = ()=>{
    //     console.log(window.innerWidth)
    //     if (window.innerWidth < 1000){
    //         setShowHamburger(true)
    //     }else{
    //         setShowHamburger(false)
    //     }
    //     setWindowWidth(window.innerWidth)
    // }

    // window.onscroll = ()=>{
    //     setShowMiniNav(false)
    // }

    // useEffect(() => {
    
    //   return () => {
        
    //   }
    // }, [])



    // const loadWebsiteContent = async() => {
    //     let endpoint = APPDATA.server + "/rsfc/website"
    //     let fetchResponse = await fetch(endpoint, {
    //       method: "GET",
    //       headers: {
    //         "Content-Type": "application/json",
    //         "Accept": "application/json"
    //       }
    //     })
    //     let response = await fetchResponse.json()
    //     if (fetchResponse.status === 200) {
          
    //       setAnnouncement(response.data.announcement)
    //       setAnnouncementStatus(response.data.announcement_status)
    //       if (response.data.maintenance_status ){
    //         window.location.href = "/maintenance"
    //       }
          
    //     }
    //   }
    
    //   useEffect(() => {
    //     if (window.location.pathname !== "/maintenance"){
    //         loadWebsiteContent()
    //     }
      
    //     return () => {
    
    //     }
    //   }, [])
    
    //   useEffect(() => {
    //     setHeaderImage(`${APPDATA.server}/logo.png`)
    //     console.log(`${APPDATA.server}/logo.png`)
    //   },[])

    if (windowWidth < 1000){
        return(
            <>
                <div className="mini-header">
                    <Link to={"/"}><img src={headerImage} alt="" className='logo' /></Link>
                    <GiHamburgerMenu color='white' size={40} className="hamburger" onClick={()=>setShowMiniNav(!showMiniNav)}/>
                </div>
                {showMiniNav?<div className="mini-nav">
                    <Link className='navigationButtons' style={{marginRight:0}} to={"/membership"}>Membership</Link>
                    {/* <Link className='navigationButtons' style={{marginRight:0}} to={"/events"}>Events</Link> */}
                    <Link className='navigationButtons' style={{marginRight:0}} to={"/experience"}>Experience</Link>
                    <Link className='navigationButtons' style={{marginRight:0}} to={"/about-us"}>About Us</Link>
                    {/* <Link to={"/account"} className='loginbutton' style={{width:"60vw",textAlign:"center"}}>Login</Link> */}
                </div>:null}
            </>
        )
    }else{
        return (
            <>
                {announcementStatus?<div className="announcement">
                    <div className="innerAnnouncementDiv">
                        <p className='announcementText'>{announcement}</p>
                        <button className='minimiseButton' onClick={()=>setAnnouncementStatus(false)}>Close</button>
                    </div>
                </div>:null}
                <div className="header">
                    <div className="innerHeader">
                        <Link to={"/"}><img src={headerImage} alt="" className='logo' /></Link>
                        <div className="headerRight">
                            <Link className='navigationButtons' to={"/about-us"}>About Us</Link>
                            <Link className='navigationButtons' to={"/membership"}>Membership</Link>
                            {/* <Link className='navigationButtons' to={"/events"}>Events</Link> */}
                            <Link className='navigationButtons' to={"/experience"}>Experience</Link>
                            
                            {/* <Link to={"/account"} className='loginbutton'>Login</Link> */}
                        </div>
                    </div>
                </div>
            </>
        );
    }
    
    
}