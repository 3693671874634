import React from "react";

import "./Options.css";

const Options = (props) => {
  const options = [
    {
        text: "What is RSFC?",
        handler: props.actionProvider.handleRSFC,
        id: 1,
    },
    { 
        text: "What are the types of Membership?", 
        handler: props.actionProvider.handleTypeMembership, 
        id: 2,
    },
    { 
        text: "Is flying safe?", 
        handler: props.actionProvider.handleSafe,
        id: 3,
    },
  ];
  const buttonsMarkup = options.map((option) => (
    <button key={option.id} onClick={option.handler} className="option-button">
      {option.text}
    </button>
  ));

  return <div className="options-container">{buttonsMarkup}</div>;
};



export default Options;