
import { APPDATA } from '../app-config';
import { useEffect,useState } from 'react';


const TestPage = () => {

    

    const fetchData = async () =>{
        let endpoint = APPDATA.server+"/rsfc/flight"
        let fetchResponse = await fetch(endpoint,{
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        })
        let response = await fetchResponse.json()
        console.log(response)
    }

    useEffect(() => {
        fetchData()
    }, [])

    return ( 
        <div>

        </div>
     );
}


export default TestPage;