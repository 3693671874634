import { useState, useEffect } from "react";

import AdminHeader from "../components/AdminHeader";
import "../css/admin-new-member-page.css";

import { Link, useNavigate, useLocation } from "react-router-dom";
import Footer from "../components/Footer";
import { APPDATA } from "../app-config";
import Switch from "react-switch";

const AdminNewMemberPage = () => {



    const [firstName, setFirstName] = useState()
    const [lastName, setLastName] = useState()
    const [salutation, setSalutation] = useState()
    const [email, setEmail] = useState()
    const [phone, setPhone] = useState()
    const [address, setAddress] = useState()
    const [username, setUsername] = useState()
    const [accountType, setAccountType] = useState("user")
    const [membershipeType, setMembershipeType] = useState("associate")
    const [dateJoined, setDateJoined] = useState()
    const [dateOfBirth, setDateOfBirth] = useState()
    const [examiner, setExaminer] = useState()
    const [instructor, setInstructor] = useState()
    const [flightHours, setFlightHours] = useState()
    const [flights, setFlights] = useState()
    const [title, setTitle] = useState()
    const [licenseType, setLicenseType] = useState()
    const [licenseNumber, setLicenseNumber] = useState()
    const [accountRemarks, setAccountRemarks] = useState()
    const [instructorRated, setInstructorRated] = useState()
    const [examinerRated, setExaminerRated] = useState()
    const [certificateOfExpiry, setCertificateOfExpiry] = useState()
    const [medicalDate, setMedicalDate] = useState()
    const [accountStatus, setAccountStatus] = useState("active")
    const [password, setPassword] = useState()




    const submitChanges = async () => {
        if (email && firstName && lastName && password && accountType && membershipeType && accountStatus) {
            let endpoint = APPDATA.server + "/account/new"
            let fetchResponse = await fetch(endpoint, {
                credentials: "include",
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({
                    first_name: firstName,
                    last_name: lastName,
                    salutation: salutation,
                    email: email,
                    contact_number: phone,
                    address: address,
                    username: username,
                    account_type: accountType,
                    membership_type: membershipeType,
                    date_joined: dateJoined,
                    date_of_birth: dateOfBirth,
                    examiner: examiner,
                    instructor: instructor,
                    flight_hours: flightHours,
                    flights: flights,
                    title: title,
                    license_type: licenseType,
                    license_number: licenseNumber,
                    instructor_rated: instructorRated,
                    examiner_rated: examinerRated,
                    account_remarks: accountRemarks,
                    certificate_of_expiry: certificateOfExpiry,
                    medical_date: medicalDate,
                    account_status: accountStatus,
                    password: password
                })
            })
            let response = await fetchResponse.json()
            console.log(response)
            if (response.status === 200) {
                window.location.href = "/admin/members"
                alert("New member added successfully")
            }else if (response.status === 400) {
                alert("Unable to create account as email already exists")
            } else {
                alert("Unable to add new member")
            }
        }else{
            alert("Please fill in all required fields")
        }
    }

    return (
        <div>
            <AdminHeader selected={"Members"} />
            <div className="amdpContainer">
                <div className="amdpInnerContainer">
                    <h1 className="amdpTitle">New Member</h1>
                    <p className="amdpSubtitle">Compulsory Fields</p>
                    <div className="amdpMemberInfoSplit">
                        <div className="amdpMemberInfoLeft">
                            <div className="amdpMemberInfo">
                                <p className="amdpMemberInfoTitle">First Name</p>
                                <input type="text" className="amdpMemberField" value={firstName} onChange={(e) => setFirstName(e.target.value)} />
                            </div>
                        </div>

                        <div className="amdpMemberInfoRight">
                            <div className="amdpMemberInfo">
                                <p className="amdpMemberInfoTitle">Last Name</p>
                                <input type="text" className="amdpMemberField" value={lastName} onChange={(e) => setLastName(e.target.value)} />
                            </div>
                        </div>
                    </div>
                    <div className="amdpMemberInfoSplit">
                        <div className="amdpMemberInfoLeft">
                            <div className="amdpMemberInfo">
                                <p className="amdpMemberInfoTitle">Email Address</p>
                                <input type="text" className="amdpMemberField" value={email} onChange={(e) => setEmail(e.target.value)} />
                            </div>
                        </div>

                        <div className="amdpMemberInfoRight">
                            <div className="amdpMemberInfo">
                                <p className="amdpMemberInfoTitle">Password</p>
                                <input type="text" className="amdpMemberField" value={password} onChange={(e) => setPassword(e.target.value)} />
                            </div>
                        </div>
                    </div>

                    <div className="amdpMemberInfoSplit">
                        <div className="amdpMemberInfoLeft">
                            <div className="amdpMemberInfo">
                                <p className="amdpMemberInfoTitle">Account Type</p>
                                <select className="amdpMemberField" value={accountType} onChange={(e) => setAccountType(e.target.value)} name="" id="">
                                    <option value="user">User</option>
                                    <option value="admin">Admin</option>
                                    <option value="super-admin">Super Admin</option>
                                </select>
                            </div>
                        </div>

                        <div className="amdpMemberInfoRight">
                            <div className="amdpMemberInfo">
                                <p className="amdpMemberInfoTitle">Membership</p>
                                <select className="amdpMemberField" value={membershipeType} onChange={(e) => setMembershipeType(e.target.value)} name="" id="">
                                    <option value="associate">Associate Member</option>
                                    <option value="social">Social Member</option>
                                    <option value="ordinary">Ordinary Member</option>
                                </select>
                            </div>
                        </div>
                    </div>

                    <p className="amdpSubtitle">Optional Fields</p>
                    <h2>Name</h2>
                    <div className="amdpMemberInfoSplit">
                        <div className="amdpMemberInfoLeft">
                            <div className="amdpMemberInfo">
                                <p className="amdpMemberInfoTitle">First Name</p>
                                <input type="text" className="amdpMemberField" value={firstName} onChange={(e) => setFirstName(e.target.value)} />
                            </div>
                        </div>

                        <div className="amdpMemberInfoRight">
                            <div className="amdpMemberInfo">
                                <p className="amdpMemberInfoTitle">Last Name</p>
                                <input type="text" className="amdpMemberField" value={lastName} onChange={(e) => setLastName(e.target.value)} />
                            </div>
                        </div>
                    </div>
                    <div className="amdpMemberInfoSplit">
                        <div className="amdpMemberInfoLeft">
                            <div className="amdpMemberInfo">
                                <p className="amdpMemberInfoTitle">Preferred Name</p>
                                <input type="text" className="amdpMemberField" value={salutation} onChange={(e) => setSalutation(e.target.value)} />
                            </div>
                        </div>
                    </div>

                    <h2>Contact Details</h2>
                    <div className="amdpMemberInfoSplit">
                        <div className="amdpMemberInfoLeft">
                            <div className="amdpMemberInfo">
                                <p className="amdpMemberInfoTitle">Email Address</p>
                                <input type="text" className="amdpMemberField" value={email} onChange={(e) => setEmail(e.target.value)} />
                            </div>
                        </div>

                        <div className="amdpMemberInfoRight">
                            <div className="amdpMemberInfo">
                                <p className="amdpMemberInfoTitle">Contact Number</p>
                                <input type="text" className="amdpMemberField" value={phone} onChange={(e) => setPhone(e.target.value)} />
                            </div>
                        </div>
                    </div>
                    <div className="residentialAddress">
                        <p className="amdpMemberInfoTitle">Residential Address</p>
                        <textarea type="text" className="amdpMemberField" style={{ width: "100%" }} value={address} onChange={(e) => setAddress(e.target.value)} />
                    </div>


                    <h2>Personal Details</h2>
                    <div className="amdpMemberInfoSplit">
                        <div className="amdpMemberInfoLeft">
                            <div className="amdpMemberInfo">
                                <p className="amdpMemberInfoTitle">Date Of Birth</p>
                                <input type="date" className="amdpMemberField" value={dateOfBirth} onChange={(e) => setDateOfBirth(e.target.value)} />
                            </div>
                        </div>

                        <div className="amdpMemberInfoRight">
                            <div className="amdpMemberInfo">
                                <p className="amdpMemberInfoTitle">Title</p>
                                <input type="text" className="amdpMemberField" value={title} onChange={(e) => setTitle(e.target.value)} />
                            </div>
                        </div>
                    </div>
                    <div className="amdpMemberInfoSplit">
                        <div className="amdpMemberInfoLeft">
                            <div className="amdpMemberInfo">
                                <p className="amdpMemberInfoTitle">Flight Cycles</p>
                                <input type="text" className="amdpMemberField" value={flights} onChange={(e) => setFlights(e.target.value)} />
                            </div>
                        </div>

                        <div className="amdpMemberInfoRight">
                            <div className="amdpMemberInfo">
                                <p className="amdpMemberInfoTitle">Flying Hours</p>
                                <input type="text" className="amdpMemberField" value={flightHours} onChange={(e) => setFlightHours(e.target.value)} />
                            </div>
                        </div>
                    </div>
                    <div className="amdpMemberInfoSplit">
                        <div className="amdpMemberInfoLeft">
                            <div className="amdpMemberInfo">
                                <p className="amdpMemberInfoTitle">Instructor</p>
                                <input type="text" className="amdpMemberField" value={instructor} onChange={(e) => setInstructor(e.target.value)} />
                            </div>
                        </div>

                        <div className="amdpMemberInfoRight">
                            <div className="amdpMemberInfo">
                                <p className="amdpMemberInfoTitle">Examiner</p>
                                <input type="text" className="amdpMemberField" value={examiner} onChange={(e) => setExaminer(e.target.value)} />
                            </div>
                        </div>
                    </div>

                    <h2>License Details</h2>
                    <div className="amdpMemberInfoSplit">
                        <div className="amdpMemberInfoLeft">
                            <div className="amdpMemberInfo">
                                <p className="amdpMemberInfoTitle">License Type</p>
                                <select className="amdpMemberField" value={licenseType} onChange={(e) => setLicenseType(e.target.value)} name="" id="">
                                    <option value="">No License</option>
                                    <option value="spl">Student Pilot License</option>
                                    <option value="ppl">Private Pilot License</option>
                                    <option value="cpl">Commercial Pilot License</option>
                                    <option value="atpl">Airline Transport Pilot License</option>
                                    <option value="mcpl">Multi-Crew Pilot License</option>
                                </select>
                            </div>
                        </div>

                        <div className="amdpMemberInfoRight">
                            <div className="amdpMemberInfo">
                                <p className="amdpMemberInfoTitle">License Number</p>
                                <input type="text" className="amdpMemberField" value={licenseNumber} onChange={(e) => setLicenseNumber(e.target.value)} />
                            </div>
                        </div>
                    </div>

                    <div className="amdpMemberInfoSplit">
                        <div className="amdpMemberInfoLeft">
                            <div className="amdpMemberInfo">
                                <p className="amdpMemberInfoTitle">Certificate of Expiry Date</p>
                                <input type="date" className="amdpMemberField" value={certificateOfExpiry} onChange={(e) => setCertificateOfExpiry(e.target.value)} />
                            </div>
                        </div>

                        <div className="amdpMemberInfoRight">
                            <div className="amdpMemberInfo">
                                <p className="amdpMemberInfoTitle">Next Medical Due</p>
                                <input type="date" className="amdpMemberField" value={medicalDate} onChange={(e) => setMedicalDate(e.target.value)} />
                            </div>
                        </div>
                    </div>

                    <h2>Account Details</h2>
                    <div className="amdpMemberInfoSplit">
                        <div className="amdpMemberInfoLeft">
                            <div className="amdpMemberInfo">
                                <p className="amdpMemberInfoTitle">Account Type</p>
                                <select className="amdpMemberField" value={accountType} onChange={(e) => setAccountType(e.target.value)} name="" id="">
                                    <option value="user">User</option>
                                    <option value="admin">Admin</option>
                                    <option value="super-admin">Super Admin</option>
                                </select>
                            </div>
                        </div>

                        <div className="amdpMemberInfoRight">
                            <div className="amdpMemberInfo">
                                <p className="amdpMemberInfoTitle">Membership</p>
                                <select className="amdpMemberField" value={membershipeType} onChange={(e) => setMembershipeType(e.target.value)} name="" id="">
                                    <option value="associate">Associate Member</option>
                                    <option value="social">Social Member</option>
                                    <option value="ordinary">Ordinary Member</option>
                                </select>
                            </div>
                        </div>
                    </div>

                    <div className="amdpMemberInfoSplit">
                        <div className="amdpMemberInfoLeft">
                            <div className="amdpMemberInfo">
                                <p className="amdpMemberInfoTitle">Instructor Rated</p>
                                <Switch checked={instructorRated} onChange={(checked, event) => { setInstructorRated(checked); console.log(checked) }} />
                            </div>
                        </div>

                        <div className="amdpMemberInfoRight">
                            <div className="amdpMemberInfo">
                                <p className="amdpMemberInfoTitle">Examiner Rated</p>
                                <Switch checked={examinerRated} onChange={(checked, event) => { setExaminerRated(checked); console.log(checked) }} />
                            </div>
                        </div>
                    </div>

                    <div className="amdpMemberInfoSplit">
                        <div className="amdpMemberInfoLeft">
                            <div className="amdpMemberInfo">
                                <p className="amdpMemberInfoTitle">Date Joined</p>
                                <input type="date" className="amdpMemberField" value={dateJoined} onChange={(e) => setDateJoined(e.target.value)} />
                            </div>
                        </div>
                        <div className="amdpMemberInfoRight">
                            <div className="amdpMemberInfo">
                                <p className="amdpMemberInfoTitle">Account Status</p>
                                <select className="amdpMemberField" value={accountStatus} onChange={(e) => setAccountStatus(e.target.value)} name="" id="">
                                    <option value="active">Active</option>
                                    <option value="banned">Suspended</option>
                                    <option value="disabled">Disabled</option>
                                </select>
                            </div>
                        </div>
                    </div>

                    <div className="residentialAddress">
                        <p className="amdpMemberInfoTitle">Account Remarks</p>
                        <textarea type="text" className="amdpMemberField" style={{ width: "100%" }} value={accountRemarks} onChange={(e) => setAccountRemarks(e.target.value)} />
                    </div>





                    <div className="amdpButtonContainer">
                        <button className="amdpButton" style={{ backgroundColor: "green" }} onClick={submitChanges}>Create User</button>
                    </div>

                </div>
            </div>
            <Footer />
        </div>
    );
}

export default AdminNewMemberPage;