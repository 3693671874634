import AdminHeader from "../components/AdminHeader";
import Footer from "../components/Footer";
import "../css/admin-new-fleet-page.css"
import { APPDATA } from "../app-config";

import React,{useEffect,useState} from 'react';


const AdminNewFleetPage = () => {
    const [uploadFiles, setUploadFiles] = useState()
    const [imagePath, setImagePath] = useState()
    const [isLoading, setIsLoading] = useState(false)
    const [aircraft_name, setAircraft_name] = useState()
    const [aircraft_model, setAircraft_model] = useState()
    const [aircraft_flight_cycles, setAircraft_flight_cycles] = useState()
    const [aircraft_flight_hours, setAircraft_flight_hours] = useState()
    const [aircraft_age, setAircraft_age] = useState()
    const [aircraft_capacity, setAircraft_capacity] = useState()
    const [aircraft_status, setAircraft_status] = useState("active")
    const [aircraft_downtime, setAircraft_downtime] = useState()
    const [aircraft_remarks, setAircraft_remarks] = useState()
    const [aircraft_registration, setAircraft_registration] = useState()
    const [aircraft_description, setAircraft_description] = useState()
    const [range, setRange] = useState()
    const [speed, setSpeed] = useState()

    const uploadImage = async () => {
        let endpoint = APPDATA.server + "/fleet/upload-image"
        let formData = new FormData()

        formData.append("image", uploadFiles)
        let fetchResponse = await fetch(endpoint, {
            method: "POST",
            credentials: "include",
            body: formData
        })
        let response = await fetchResponse.json()
        if (response.status === 200) {

            setImagePath(response.data)
            postAircraft(response.data.file_path)
        } else {
            alert("Error, unable to upload image")
        }
    }

    const postAircraft = async (path) => {
        let endpoint = APPDATA.server + "/fleet/new"
        let data = {
            aircraft_name: aircraft_name,
            aircraft_model: aircraft_model,
            aircraft_flight_cycles: aircraft_flight_cycles,
            aircraft_flight_hours: aircraft_flight_hours,
            aircraft_age: aircraft_age,
            aircraft_capacity: aircraft_capacity,
            aircraft_status: aircraft_status,
            aircraft_downtime: aircraft_downtime,
            aircraft_remarks: aircraft_remarks,
            aircraft_registration: aircraft_registration,
            aircraft_description: aircraft_description,
            range: range,
            speed: speed,
            aircraft_image: path
        }
        let fetchResponse = await fetch(endpoint, {
            method: "POST",
            credentials: "include",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify(data)
        })
        let response = await fetchResponse.json()

        if (response.status === 200) {
            window.location.href = "/admin/control-panel"
            alert("Aircraft added successfully")
        } else {
            alert("Unable to add aircraft")
        }
    }


    return ( 
        <div>
            <AdminHeader selected={"Control Panel"}/>
            <div className="anfpContainer">
                <div className="anfpInnerContainer">
                    <h1 id="anfpTitle" className="anfpTitle">New Aircraft</h1>
                    <div className="anepImageContainer">
                        <h2 className="anepLabel">Upload Image</h2>
                        <label className="anepLabel">Drag & Drop Image here</label>
                        <input className="anepUploadContent" onChange={(e) => setUploadFiles(e.target.files[0])} type={"file"} name={'uploadFiles'} />
                    </div>
                    <div className="anfpFormContainer">
                        <div className="anfpSplitLeft">
                            <div className="anfpForm">
                                <p className="anfpInformation">Aircraft Name</p>
                                <input type="text" className="anfpInput" value={aircraft_name} onChange={(e)=>setAircraft_name(e.target.value)}/>
                            </div>
                            <div className="anfpForm">
                                <p className="anfpInformation">Aircraft Model</p>
                                <input type="text" className="anfpInput" value={aircraft_model} onChange={(e)=>setAircraft_model(e.target.value)}/>
                            </div>
                            <div className="anfpForm">
                                <p className="anfpInformation">Aircraft Registration</p>
                                <input type="text" className="anfpInput" value={aircraft_registration} onChange={(e)=>setAircraft_registration(e.target.value)}/>
                            </div>
                            <div className="anfpForm">
                                <p className="anfpInformation">Aircraft Capacity</p>
                                <input type="text" className="anfpInput" value={aircraft_capacity} onChange={(e)=>setAircraft_capacity(e.target.value)}/>
                            </div>
                            <div className="anfpForm">
                                <p className="anfpInformation">Aircraft Range (Km)</p>
                                <input type="text" className="anfpInput" value={range} onChange={(e)=>setRange(e.target.value)}/>
                            </div>
                            <div className="anfpForm">
                                <p className="anfpInformation">Aircraft Speed (Km/H)</p>
                                <input type="text" className="anfpInput" value={speed} onChange={(e)=>setSpeed(e.target.value)}/>
                            </div>

                        </div>
                        <div className="anfpSplitRight">
                        <div className="anfpForm">
                                <p className="anfpInformation">Flight Cycle</p>
                                <input type="text" className="anfpInput" value={aircraft_flight_cycles} onChange={(e)=>setAircraft_flight_cycles(e.target.value)}/>
                            </div>
                            <div className="anfpForm">
                                <p className="anfpInformation">Flight Hours</p>
                                <input type="text" className="anfpInput" value={aircraft_flight_hours} onChange={(e)=>setAircraft_flight_hours(e.target.value)}/>
                            </div>
                            <div className="anfpForm">
                                <p className="anfpInformation">Aircraft Age</p>
                                <input type="text" className="anfpInput" value={aircraft_age} onChange={(e)=>setAircraft_age(e.target.value)}/>
                            </div>
                            <div className="anfpForm">
                                <p className="anfpInformation">Aircraft Status</p>
                                <select name="" className="anfpInput" value={aircraft_status} onChange={(e)=>setAircraft_status(e.target.value)}>
                                    <option value="active">Active</option>
                                    <option value="inactive">Inactive</option>
                                    <option value="under-maintenance">Under Maintenance</option>
                                    <option value="decommissioned">Decommissioned</option>
                                </select>
                            </div>
                        </div>
                    </div>
                
                    <p className="anfpInformation">Description</p>
                    <textarea name="" id="" cols="30" rows="10" className="anfpRemarks" value={aircraft_description} onChange={(e)=>setAircraft_description(e.target.value)} ></textarea>
                    <div className="anfpButtonContainer">
                        <button className="anfpButton" onClick={uploadImage}>Save</button>
                    </div>
                    
                </div>
            </div>
            <Footer/>
        </div>
    );
}
 
export default AdminNewFleetPage;