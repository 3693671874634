import AdminHeader from '../components/AdminHeader';
import "../css/admin-application-page.css";
import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { APPDATA } from '../app-config';
import Footer from '../components/Footer';


const AdminApplicationPage = () => {


    const { state } = useLocation()
    const [selectedDocument, setSelectedDocument] = useState(state.application_docmuents ? state.application_documents[0] : {file_path: "", file_type: ""})
    const [userID, setUserID] = useState(state.user_id)
    const [applicationID, setApplicationID] = useState(state.application_id)
    const [applicationStatus, setApplicationStatus] = useState(state.application_status)
    const [applicationType, setApplicationType] = useState(state.application_type)
    const [applicationDate, setApplicationDate] = useState(state.application_date)
    const [applicationDocuments, setApplicationDocuments] = useState(state.application_documents)

    const updateApplication = async () => {
        let endpoint = APPDATA.server + "/application/" + applicationID
        let data = {
            "user_id": userID,
            "application_status": applicationStatus,
            "application_type": applicationType,
            "application_date": applicationDate,
            "application_documents": applicationDocuments
        }
        let response = await fetch(endpoint, {
            method: "PUT",
            credentials: "include",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify(data)
        })
        let result = await response.json()
        if (result.status === 200) {
            window.location.href = "/admin/control-panel"
            alert("Application updated successfully")
        } else {
            alert("Error updating application")
        }
    }

    const deleteApplication = async () => {
        let endpoint = APPDATA.server + "/application/delete/" + applicationID
        let response = await fetch(endpoint, {
            method: "POST",
            credentials: "include",
            headers: {
                "Content-Type": "application/json"
            }
        })
        let result = await response.json()
        if (result.status === 200) {
            window.location.href = "/admin/control-panel"
            alert("Application deleted successfully")
        } else {
            alert("Error deleting application")
        }
    }

    useEffect(() => {
        console.log(selectedDocument)
        console.log(`${APPDATA.server}${(selectedDocument.file_path).toString().replace("public", "")}`)
    }, [selectedDocument])

    return (
        <div>
            <AdminHeader selected={"Control Panel"} />
            <div className="aapContainer">
                <div className="aapInnerContainer">
                    <h1 className="aapTitle">Application Approval</h1>
                    <div className="aapSplit">
                        <div className="aapLeft">
                            {selectedDocument ?
                                <>
                                    {selectedDocument.file_type.split("/")[0] === "application" ?
                                        <iframe width={"100%"} height={"700px"} src={`${APPDATA.server}${(selectedDocument.file_path).toString().replace("public", "")}`} className="aapPDF"></iframe>
                                        : null}
                                </> :<p>Hello</p>}
                            {selectedDocument ?
                                <>
                                    {selectedDocument.file_type.split("/")[0] === "image" || selectedDocument.file_type.split("/")[1] === "png"?
                                        <img src={`${APPDATA.server}${(selectedDocument.file_path).toString().replace("public", "")}`} className="aapImage" width={"100%"} />
                                        : null}
                                </> : null}
                        </div>
                        <div className="aapRight">
                            <p>Uploaded Documents</p>
                            {applicationDocuments.map((document, index) => {
                                return (
                                    <div className="aapDocument" onClick={() => setSelectedDocument(document)}>
                                        <p>{document.file_name}</p>
                                    </div>
                                )
                            })}

                            <p>Application Details</p>
                            <div className="aapDetails">
                                <div className="aapForm">
                                    <p>Application ID</p>
                                    <input className='aapInput' type="text" value={applicationID} disabled />
                                </div>
                                <div className="aapForm">
                                    <p>Application Type</p>
                                    <input className='aapInput' type="text" value={applicationType} disabled />
                                </div>
                                <div className="aapForm">
                                    <p>{applicationType === "medical" ? "Next Medical Due" : "Certificate of Expiry Date"}</p>
                                    <input className='aapInput' type="date" value={applicationDate} onChange={(e) => setApplicationDate(e.target.value)} />
                                </div>
                                <div className="aapForm">
                                    <p>Application Status</p>
                                    <select className='aapInput' name="" value={applicationStatus} onChange={(e) => setApplicationStatus(e.target.value)} id="">
                                        <option value="pending">Pending</option>
                                        <option value="approved">Approved</option>
                                        <option value="rejected">Rejected</option>
                                    </select>
                                </div>

                                <div className="aapButtonContainer">
                                    <button className="aapButton" onClick={updateApplication}>Save</button>
                                    <button className="aapButton" style={{backgroundColor:"red"}} onClick={deleteApplication}>Delete</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    );
}

export default AdminApplicationPage;