import { Link,useLocation } from "react-router-dom";
import Footer from "../components/Footer";
import MemberHeader from "../components/MemberHeader";
import "../css/member-flight-detail.css";
import { APPDATA } from "../app-config";
import { useState,useEffect } from "react";
import Switch from "react-switch";


const MemberCommuityPostPage = ({}) => {


    const {state} = useLocation();


    const [header, setHeader] = useState("")
    const [uploadFiles, setUploadFiles] = useState()
    const [imagePath, setImagePath] = useState("")
    const [visibility, setVisibility] = useState(true)
    const [remarks, setRemarks] = useState()

    const uploadImage = async () => {
        let endpoint = APPDATA.server + "/community/upload-image"
        let formData = new FormData()
        formData.append("image",uploadFiles)
        let fetchResponse = await fetch(endpoint,{
            method:"POST",
            credentials: "include",
            headers:{
            },
            body:formData
        })
        let response = await fetchResponse.json()
        if (response.status === 200){
            console.log(response.data)
            setImagePath(response.data)
            uploadCommunityPost(response.data.file_path)
        }else{
            alert("Error, unable to upload image")
        }
    }


    const uploadCommunityPost = async (path) => {
        if (header && remarks){
            let endpoint = APPDATA.server + "/community/upload-post"
            let fetchResponse = await fetch(endpoint,{
                method:"POST",
                credentials: "include",
                headers:{
                    "Content-Type":"application/json",
                    "Accept":"application/json",
                },
                body:JSON.stringify({
                    user_id:JSON.parse(window.sessionStorage.getItem("accessToken"))._id,
                    header:header,
                    image:path,
                    remarks:remarks,
                    visibility:visibility
                })
            })
            let response = await fetchResponse.json()
            if (response.status === 200){
                window.location.href = "/members/community"
                alert("Success, post published")
            }else if (response.status === 403){
                window.location.href = "/account"
            }else if (response.status === 401){
                window.location.href = "/account"
            }else{
                alert("Error, unable to upload post")
            }
        }else{
            alert("Error, unable to post")
        }
        
    }


    return ( 
        <div>
            <MemberHeader selected={"Booking"}/>
            <div className="mfdContainer">
                <div className="mfdInnerContainer">
                        <h2>Community Post</h2>
                        <div className="mfdSplit" style={{alignItems:"flex-start"}}>
                            <div className="mfdSplitLeft">
                                <div className="mfdInputContainer">
                                    <p className="mfdItemTitle">Post Heading</p>
                                    <input className="mfdInput" value={header} onChange={(e)=>setHeader(e.target.value)} placeholder="Write something about your flight experience!"/>
                                </div>
                                <div className="mfdInputContainer">
                                    <p className="mfdItemTitle">Flight Experience</p>
                                    <textarea className="mfdInput" value={remarks} onChange={(e)=>setRemarks(e.target.value)} placeholder="Write something about your flight experience!"/>
                                </div>
                            </div>
                            <div className="mfdSplitRight">
                                <div className="mfdInputContainer">
                                    <p className="mfdItemTitle">Upload Image</p>
                                    <input className="mfdUploadContent" onChange={(e)=>setUploadFiles(e.target.files[0])} type={"file"} name={'uploadFiles'}/>
                                </div>
                                <div className="mfdInputContainer">
                                    <p className="mfdItemTitle">Community Post Visibility</p>
                                    <div style={{display:"flex",flexDirection:"row",alignItems:"center",gap:"10px",marginTop:"0px"}}>
                                        <Switch checked={visibility} onChange={(checked,event)=>{setVisibility(checked);console.log(checked)}} />
                                        {visibility?<p>Post is visible to the Public</p>:<p>Post is for you only</p>}
                                    </div>
                                </div>
                            </div>
                            
                        </div>

                        
                        <div className="mfdButtonContainer">
                            <button className="mfdButton" onClick={()=>{
                                if ( header === "" || remarks === ""){
                                    alert("Please fill in all the fields")
                                }else{
                                    uploadImage()
                                }
                            }}>Publish</button>
                        </div>
                    

                </div>
            </div>
            <Footer/>
        </div>
     );
}
 
export default MemberCommuityPostPage;