import React from 'react';
import { APPDATA } from '../app-config';
import ActionProvider from './ActionProvider';

const MessageParser = ({ children, actions }) => {
  const parse = (message) => {
    const lowercase = message.toLowerCase();
    const trimmed = lowercase.trim();

    //Use trimmed from now onwards


    const postChat = async (message) => {
      let endpoint = APPDATA.server + "/chatbot/chat";
      let data = {
        code:message,
      };
      let fetchResponse = fetch(endpoint, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      });
      let response = await fetchResponse.json()


    }

    // greetings
    console.log(trimmed);
    // if (trimmed.includes('hello') || trimmed.includes('hi') || trimmed.includes('hey')) {
    //   actions.handleGreeting();
    //   // parse(trimmed.replaceAll("hello", ""));
    // }

    if (trimmed.includes('cost') && trimmed.includes('membership') || 
      trimmed.includes('cost') && trimmed.includes('member') ||
      trimmed.includes('membership') && trimmed.includes('fee') ||
      trimmed.includes ('free') && trimmed.includes('member') ||
      trimmed.includes("free")||
      trimmed.includes("fee")||
      trimmed.includes ('free') && trimmed.includes('join')) {
      actions.handleCostMembership();
      postChat('CB1')
      // parse(trimmed.replaceAll("register", ""));
    }

    else if (trimmed.includes('can i upgrade my membership at anytime') ||
      trimmed.includes('can i') && trimmed.includes('upgrade') ||
      trimmed.includes("upgrade")||
      trimmed.includes('membership') && trimmed.includes('upgrade')) {
      actions.handleMemberUpgrade();
      postChat('CB2')
      // parse(trimmed.replaceAll("airport", ""));
    }    

    else if (trimmed.includes('age')||
      trimmed.includes("old")||
      trimmed.includes("age") &&  trimmed.includes("start flying")) {
      actions.handleAge();
      postChat('CB4')
      // parse(trimmed.replaceAll("age", ""));
    }

    else if (trimmed.includes('hourly rate') || 
      trimmed.includes('cost per hour') ||
      trimmed.includes('flying cost') ||
      trimmed.includes('flight cost') ||
      trimmed.includes('rates') ||
      trimmed.includes("rate")||
      trimmed.includes("cost")||
      trimmed.includes('flight rate')) {
      actions.handleFlightRate();
      postChat('CB27')
    // parse(trimmed.replaceAll("airport", ""));
    }

    else if ((trimmed.includes('price') && trimmed.includes('license')) ||
      trimmed.includes('price') && trimmed.includes('licence') ||
      trimmed.includes('how much') && trimmed.includes('license') ||
      trimmed.includes('how much') && trimmed.includes('licence') ||
      trimmed.includes('how much') && trimmed.includes('private pilot licence') ||
      trimmed.includes('how much') && trimmed.includes('private pilot license') ||
      trimmed.includes('how much') && trimmed.includes('ppl') ||
      trimmed.includes('cost') && trimmed.includes('license') ||
      trimmed.includes('cost') && trimmed.includes('licence') ||
      trimmed.includes('cost')) {
      actions.handleCostLicense();
      postChat('CB5')
      // parse(trimmed.replaceAll("cost", "").replaceAll("price",""));
    }

    else if (trimmed.includes('someone')||
      trimmed.includes('person')||
      trimmed.includes('friend')||
      trimmed.includes('anyone')||
      trimmed.includes('family')||
      trimmed.includes('friends')) {
      actions.handlePeople();
      postChat('CB6')
      // parse(trimmed.replaceAll("someone", "").replaceAll("person","").replaceAll("friend","").replaceAll("friends",""));
    }    

    else if (trimmed.includes('what') && trimmed.includes('airport') ||
      trimmed.includes('airport')) {
      actions.handleAirport();
      postChat('CB7')
      // parse(trimmed.replaceAll("airport", ""));
    }

    else if (trimmed.includes('where can I register if i would want to get my license') ||
      trimmed.includes("register")) {
      actions.handleRegisterLicense();
      postChat('CB8')
      // parse(trimmed.replaceAll("register", ""));
    }

    else if ((trimmed.includes('long') && trimmed.includes('private pilot license')) || 
      (trimmed.includes('long') && trimmed.includes('ppl')) ||
      (trimmed.includes('duration') && trimmed.includes('ppl')) ||
      (trimmed.includes('long') && trimmed.includes('training'))) {
      actions.handlePilotLicense();
      postChat('CB9')
      // parse(trimmed.replaceAll("register", ""));
    }

    else if (trimmed.includes('flight') && trimmed.includes('last') || 
      trimmed.includes('duration')&& trimmed.includes('class') ||
      trimmed.includes('duration')&& trimmed.includes('training') ||
      trimmed.includes('duration') ||
      trimmed.includes('duration')&& trimmed.includes('flight')) {
      actions.handleDuration();
      postChat('CB10')
      // parse(trimmed.replaceAll("register", ""));
    }

    else if (trimmed.includes('operating hours') ||
      trimmed.includes('operating hour') ||
      trimmed.includes('opening hour') ||
      trimmed.includes('opening hours')) {
      actions.handleOperatingHours();
      postChat('CB11')
      // parse(trimmed.replaceAll("airport", ""));
    }

    else if (trimmed.includes('contact')||
      trimmed.includes("email")||
      trimmed.includes("phone")||
      trimmed.includes("call")||
      trimmed.includes('reach the club')&& trimmed.includes('more information')) {
      actions.handleContact();
      postChat('CB12')
      // parse(trimmed.replaceAll("airport", ""));
    }

    else if (trimmed.includes('location') || 
      (trimmed.includes('located'))||
      (trimmed.includes('club') && trimmed.includes('location')) ||
      (trimmed.includes('how do i get to the club'))||
      (trimmed.includes('where is the club'))||
      (trimmed.includes('directions'))||
      (trimmed.includes('get to') && trimmed.includes('rsfc')) ||
      (trimmed.includes('club') && trimmed.includes('located'))) {
      actions.handleLocation();
      postChat('CB13')
      // parse(trimmed.replaceAll("airport", ""));
    }

    else if ((trimmed.includes('types') && trimmed.includes('membership')) ||
     (trimmed.includes('member') && trimmed.includes('types'))) {
      actions.handleTypeMembership();
      postChat('CB14')
      // parse(trimmed.replaceAll("register", ""));
    }

    else if (trimmed.includes('types of events') || 
      trimmed.includes('event')||
      trimmed.includes('events')||
      trimmed.includes('type') && trimmed.includes('events')||
      trimmed.includes('type') && trimmed.includes('activities')||
      trimmed.includes('event types')) {
      actions.handleEventType();
      postChat('CB15')
      // parse(trimmed.replaceAll("airport", ""));
    }

    else if (trimmed.includes('where are your aircrafts based at') ||
      trimmed.includes('aircrafts') && trimmed.includes('based at') || 
      trimmed.includes('aircrafts') && trimmed.includes('keep') ||
      trimmed.includes('aeroplanes') && trimmed.includes('keep') ||
      trimmed.includes('airplanes') && trimmed.includes('keep') ||
      trimmed.includes('fleet') && trimmed.includes('keep') ||
      trimmed.includes('aircrafts') && trimmed.includes('parked at')) {
      actions.handleAircraft();
      postChat('CB16')
      // parse(trimmed.replaceAll("airport", ""));
    }

    else if (trimmed.includes('fleet') || 
      trimmed.includes('plane')||
      trimmed.includes('planes')||
      trimmed.includes('aircraft')||
      trimmed.includes('aircrafts')||
      trimmed.includes('airplane')||
      trimmed.includes('aeroplane')||
      trimmed.includes('aeroplanes')||
      trimmed.includes('own') && trimmed.includes('aircraft')|| 
      trimmed.includes('size') && trimmed.includes('aircraft')||
      trimmed.includes('fly') && trimmed.includes('aircraft')||
      trimmed.includes('fly') && trimmed.includes('aeroplane')||
      trimmed.includes('fly') && trimmed.includes('plane')||
      trimmed.includes('use') && trimmed.includes('plane')||
      trimmed.includes('use') && trimmed.includes('aeroplane')||
      trimmed.includes('use') && trimmed.includes('aircraft')) {
      actions.handleWhatAircraft();
      postChat('CB17')
      // parse(trimmed.replaceAll("airport", ""));
    }

    else if (trimmed.includes('who runs the club') || 
      trimmed.includes('owns') || 
      trimmed.includes('own') || 
      trimmed.includes('operates') || 
      trimmed.includes('operate') || 
      trimmed.includes('who owns the club') || 
      trimmed.includes('who operates the club')) {
      actions.handleClub();
      postChat('CB18')
      // parse(trimmed.replaceAll("airport", ""));
    }

    else if (trimmed.includes('booking rules')||
      trimmed.includes('rules')) {
      actions.handleBookingRules();
      postChat('CB26')
      // parse(trimmed.replaceAll("airport", ""));
    }

    else if (trimmed.includes('how does a member reserve an aircraft') || 
      trimmed.includes('how does a member schedule for a flight') ||
      trimmed.includes('how') && trimmed.includes('book a flight') ||
      trimmed.includes ('booking') ||
      trimmed.includes ('booking system') ||
      trimmed.includes('is there a booking system for the flights')) {
      actions.handleFlightBooking();
      postChat('CB19')
      // parse(trimmed.replaceAll("airport", ""));
    }

    else if (trimmed.includes('what are the requirements')||
      trimmed.includes('requirements') ||
      trimmed.includes('requirement') ||
      trimmed.includes('qualifications') ||
      trimmed.includes('ppl') && trimmed.includes('requirements')){
      actions.handleRequirements();
      postChat('CB20')
      // parse(trimmed.replaceAll("airport", ""));
    }

    else if (trimmed.includes('what are the benefits of joining the flying club')||
      trimmed.includes('benefits') && trimmed.includes('joining') ||
      trimmed.includes('benefits') && trimmed.includes('flying') ||
      trimmed.includes('benefit') && trimmed.includes('joining')) {
      actions.handleBenefits();
      postChat('CB21')
      // parse(trimmed.replaceAll("airport", ""));
    }

    else if (trimmed.includes('instructor')||
      trimmed.includes('training')||
      trimmed.includes('train')||
      trimmed.includes('instructor') && trimmed.includes('assigned') ||
      trimmed.includes('who') && trimmed.includes('train')) {
      actions.handleTraining();
      postChat('CB22')
      // parse(trimmed.replaceAll("airport", ""));
    }

    else if (trimmed.includes('when was the club established')||
      trimmed.includes('club') && trimmed.includes('start') ||
      trimmed.includes('club') && trimmed.includes('old') ||
      trimmed.includes('club') && trimmed.includes('established')) {
      actions.handleEstablish();
      postChat('CB23')
      // parse(trimmed.replaceAll("airport", ""));
    }    

    else if (trimmed.includes('how far in advance can i book a flight')||
      trimmed.includes('in advance') && trimmed.includes('book')) {
      actions.handleAdvanceBooking();
      postChat('CB24')
      // parse(trimmed.replaceAll("airport", ""));
    }

    else if (trimmed.includes('pet')) {
      actions.handlePet();
      postChat('CB25')
    // parse(trimmed.replaceAll("airport", ""));
    }

    else if (trimmed.includes('how do i pay for my membership') ||
      trimmed.includes('pay') && trimmed.includes('member') ||
      trimmed.includes('pay') && trimmed.includes('membership')) { 
      actions.handlePayMember();
      postChat('CB28')
    // parse(trimmed.replaceAll("airport", ""));
    }

    else if (trimmed.includes('what is different about your club compared to other flying clubs') ||
      trimmed.includes('different about') && trimmed.includes('club') ||
      trimmed.includes('special about') && trimmed.includes('club') ||
      trimmed.includes('compared to') && trimmed.includes('club')) {
      actions.handleDifferent();
      postChat('CB30')
    // parse(trimmed.replaceAll("airport", ""));
    }

    else if (trimmed.includes('when do i pay for my flight') ||
      trimmed.includes('fees') && trimmed.includes('due') ||
      trimmed.includes('pay') && trimmed.includes('flight')) {
      actions.handlePay();
      postChat('CB31')
    // parse(trimmed.replaceAll("airport", ""));
    }

    else if (trimmed.includes('payment method') ||
      trimmed.includes('pay')) { 
      actions.handlePayment();
      postChat('CB29')
    // parse(trimmed.replaceAll("airport", ""));
    }

    else if (trimmed.includes('what if i have health issues') ||
      trimmed.includes('health issue')||
      trimmed.includes('health')||
      trimmed.includes('health issues')) {
      actions.handleHealth();
      postChat('CB32')
    // parse(trimmed.replaceAll("airport", ""));
    }

    else if (trimmed.includes('do i need any flight experience') ||
      trimmed.includes('flight experience')||
      trimmed.includes('flight experience') && trimmed.includes('need')) {
      actions.handleExperience();
      postChat('CB33')
    // parse(trimmed.replaceAll("airport", ""));
    }

    else if (trimmed.includes('glasses') ||
      trimmed.includes('spectacles')) {
      actions.handleGlasses();
      postChat('CB34')
    // parse(trimmed.replaceAll("airport", ""));
    }

    else if (trimmed.includes('question') ||
      trimmed.includes('question') &&trimmed.includes('not listed')) {
      actions.handleQuestion();
      postChat('CB35')
    // parse(trimmed.replaceAll("airport", ""));
    }

    else if (trimmed.includes('safe') ||
      trimmed.includes("fly")||
      trimmed.includes('safe') &&trimmed.includes('to fly')) {
      actions.handleSafe();
      postChat('CB36')
    // parse(trimmed.replaceAll("airport", ""));
    }

    else if (trimmed.includes('school') ||
      trimmed.includes('attend') &&trimmed.includes('school') ||
      trimmed.includes('license') &&trimmed.includes('school')) {
      actions.handleSchool();
      postChat('CB37')
    // parse(trimmed.replaceAll("airport", ""));
    }

    else if (trimmed.includes('medical') ||
      trimmed.includes('medical') &&trimmed.includes('requirements')) {
      actions.handleMedical();
      postChat('CB38')
    // parse(trimmed.replaceAll("airport", ""));
    }

    else if (trimmed.includes('regularly')||
      trimmed.includes('frequent')||
      trimmed.includes('frequently')||
      trimmed.includes('regular')) {
      actions.handleRegular();
      postChat('CB39')
    // parse(trimmed.replaceAll("airport", ""));
    }

    else if (trimmed.includes('fast')||
      trimmed.includes('speed')) {
      actions.handleFast();
      postChat('CB40')
    // parse(trimmed.replaceAll("airport", ""));
    }

    else if (trimmed.includes('number of members')||
      trimmed.includes('how many') && trimmed.includes('members')) {
      actions.handleMember();
      postChat('CB41')
    // parse(trimmed.replaceAll("airport", ""));
    }

    else if (trimmed.includes('timeslot') ||
      trimmed.includes('timeslots')) {
      actions.handleTimeslot();
      postChat('CB42')
    }

    else if (trimmed.includes('password')) {
      actions.handlePassword();
      postChat('CB43')
    }

    else if (trimmed.includes('upcoming events')||
    trimmed.includes('upcoming event')) {
      actions.handleUpcomingEvent();
      postChat('CB44')
    }

    else if (trimmed.includes('flight plan')){
      actions.handleFlightPlan();
      postChat('CB45')
    }

    else if (trimmed.includes('im interested to join the club, how do i apply') || 
      trimmed.includes('i have interest in joining the club, what do i do now') || 
      trimmed.includes('i am interested, how do i apply') || 
      trimmed.includes('i am interested') ||
      trimmed.includes('i am interested, what do i do now') || 
      trimmed.includes('im interested, how do i apply') || 
      trimmed.includes('im interested, what do i do now') || 
      trimmed.includes('how to apply') ||
      trimmed.includes('apply') ||
      trimmed.includes('how to join') ||
      trimmed.includes('join') ||
      trimmed.includes ('membership') ||
      trimmed.includes ('member') ||
      trimmed.includes('how') && trimmed.includes('apply') ||
      trimmed.includes('member') && trimmed.includes('apply') ||
      trimmed.includes('member') && trimmed.includes('sign up') ||
      trimmed.includes('how') && trimmed.includes('sign up') ||
      trimmed.includes('where') && trimmed.includes('sign up') ||
      trimmed.includes('how') && trimmed.includes('join') ||
      trimmed.includes('im interested, what do i do now')){
      actions.handleClubInterest();
      postChat('CB46')
      // parse(trimmed.replaceAll("airport", ""));
    }

    else if (trimmed.includes('what is rsfc')||
      trimmed.includes('rsfc') ||
      trimmed.includes("what's rsfc")||
      trimmed.includes("what is republic of singapore flying club")||
      trimmed.includes("what is the republic of singapore flying club")||
      trimmed.includes("what is the rsfc")||
      trimmed.includes("what's the rsfc")||
      trimmed.includes("about rsfc")||
      trimmed.includes("what") &&  trimmed.includes("rsfc")||
      trimmed.includes("whats rsfc")){
      actions.handleRSFC();
      postChat('CB3')
      // parse(trimmed.replaceAll( "what is rsfc" , "").replaceAll("what's rsfc","").replaceAll("what is the rsfc","").replaceAll("what's the rsfc","").replaceAll("whats rsfc",""));
    }

    else if (trimmed.includes('hello') || trimmed.includes('hi') || trimmed.includes('hey')) {
      actions.handleGreeting();
      // parse(trimmed.replaceAll("hello", ""));
    }

    else actions.handleCannotUnderstand();
  };

  return (
    <div>
      {React.Children.map(children, (child) => {
        return React.cloneElement(child, {
          parse: parse,
        
          actions: {
            handleGreeting: ActionProvider.handleGreeting,
            handleRSFC: ActionProvider.handleRSFC,
            handleAge: ActionProvider.handleAge,
            handleCostLicense: ActionProvider.handleCostLicense,
            handleAirport: ActionProvider.handleAirport,
            handlePeople: ActionProvider.handlePeople,
            handleRegisterLicense: ActionProvider.handleRegisterLicense,
            handlePilotLicense: ActionProvider.handlePilotLicense,
            handleDuration: ActionProvider.handleDuration,
            handleCostMembership: ActionProvider.handleCostMembership,
            handleOperatingHours: ActionProvider.handleOperatingHours,
            handleContact: ActionProvider.handleContact,
            handleLocation: ActionProvider.handleLocation,
            handleTypeMembership: ActionProvider.handleTypeMembership,
            handleEventType: ActionProvider.handleEventType,
            handleAircraft: ActionProvider.handleAircraft,
            handleClub: ActionProvider.handleClub,
            handleClubInterest: ActionProvider.handleClubInterest,
            handleFlightBooking: ActionProvider.handleFlightBooking,
            handleCannotUnderstand: ActionProvider.handleCannotUnderstand,
            handleWhatAircraft: ActionProvider.handleWhatAircraft,
            handleRequirements: ActionProvider.handleRequirements,
            handleBenefits: ActionProvider.handleBenefits,
            handleTraining: ActionProvider.handleTraining,
            handleEstablish: ActionProvider.handleEstablish,
            handleMemberUpgrade: ActionProvider.handleMemberUpgrade,
            handleAdvanceBooking: ActionProvider.handleAdvanceBooking,
            handlePet: ActionProvider.handlePet,
            handleBookingRules: ActionProvider.handleBookingRules,
            handleFlightRate: ActionProvider.handleFlightRate,
            handlePayment: ActionProvider.handlePayment,
            handlePay: ActionProvider.handlePay,
            handleDifferent: ActionProvider.handleDifferent,
            handleHealth: ActionProvider.handleHealth,
            handleExperience: ActionProvider.handleExperience,
            handleGlasses: ActionProvider.handleGlasses,
            handleQuestion: ActionProvider.handleQuestion,
            handleSafe: ActionProvider.handleSafe,
            handleSchool: ActionProvider.handleSchool,
            handleMedical: ActionProvider.handleMedical,
            handleRegular: ActionProvider.handleRegular,
            handleFast: ActionProvider.handleFast,
            handleMember: ActionProvider.handleMember,
            handleTimeslot: ActionProvider.handleTimeslot, 
            handlePassword: ActionProvider.handlePassword,
            handlePayMember: ActionProvider.handlePayMember,
            handleFlightPlan: ActionProvider.handleFlightPlan,
            ...actions
          },
        });
      })}
    </div>
  );
};

export default MessageParser;