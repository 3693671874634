import Footer from "../components/Footer";
import Header from "../components/Header";
import { useState, useEffect } from 'react';
import "../css/account-page.css"
import { APPDATA } from "../app-config";
import RSFCChatBot from "../components/RSFCChatBot";


const AccountPage = () => {
    const [pageState, setPageState] = useState('login')


    const [username, setUsername] = useState("")
    const [password, setPassword] = useState("")
    const [email, setEmail] = useState("")
    const [contactnumber, setContactnumber] = useState("")
    const [firstname, setFirstname] = useState("")
    const [lastname, setLastname] = useState("")
    const [preferredTitle, setPreferredTitle] = useState("")
    const [dateOfBirth, setDateOfBirth] = useState(new Date())
    const [address, setAddress] = useState("")

    const [password1, setPassword1] = useState("")
    const [password2, setPassword2] = useState("")

    const [usernameEmail, setUsernameEmail] = useState("")
    const [userData, setUserData] = useState()

    useEffect(() => {
        setUserData(getCookie("user")?JSON.parse(getCookie("user")):null);
    }, [])
    function getCookie(name) {
        let decodedCookie = decodeURIComponent(document.cookie)
        let ca = decodedCookie.split(';');
        let data;
        for (let i = 0; i < ca.length; i++) {
            let c = ca[i];
            while (c.charAt(0) == ' ') {
                c = c.substring(1);
            }
            if (c.indexOf(name) == 0) {
                data = c.substring(name.length, c.length);
                break

            }

        }
        if (data) {
            data = data.replace("=s:", "").slice(0, data.indexOf("}") - 2)
            return data
        }


    }

    useEffect(() => {
        establishAdminSession();
        establishMemberSession()
    }, [userData])

    const establishAdminSession = async () => {
        if (["super-admin", "admin"].includes(userData.account_type)) {
            let endpoint = APPDATA.server + "/account/cookie/admin"
            const fetchResponse = await fetch(endpoint, {
                method: 'POST',
                credentials: "include",
                headers: {
                    'Content-Type': 'application/json',
                    "Accept": "application/json",
                },
            })
            const data = await fetchResponse.json()
            if (data.status === 200) {
                window.location.href = "/admin"
            }
        }
    }

    const establishMemberSession = async () => {
        if (["user"].includes(userData.account_type)) {
            let endpoint = APPDATA.server + "/account/cookie/member"
            const fetchResponse = await fetch(endpoint, {
                method: 'POST',
                credentials: "include",
                headers: {
                    'Content-Type': 'application/json',
                    "Accept": "application/json",
                },
            })
            const data = await fetchResponse.json()
            if (data.status === 200) {
                window.location.href = "/members"
            }
        }
    }


    const submitLogin = async () => {
        let endpoint = APPDATA.server + "/account/login"
        const fetchResponse = await fetch(endpoint, {
            method: 'POST',
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json',
                "Accept": "application/json",
            },
            body: JSON.stringify({
                username: username,
                password: password
            })
        })
        const data = await fetchResponse.json()
        if (data.status === 200) {
            var userData = data.payload
            // set cookie received


            await window.sessionStorage.setItem("accessToken", JSON.stringify(userData))
            if (userData.account_type == "super-admin" || userData.account_type == "admin") {
                window.location.href = "/admin"
            } else {
                window.location.href = "/members"
            }
        }else if (data.status === 404){
            alert("Your account has been disabled. Please contact the administrator for more information.")
        } else {
            alert("Incorrect credentials")
        }
    }

    const submitCreateAccount = async () => {
        if (password1 === password2) {
            let endpoint = APPDATA.server + "/account/create-account"
            const fetchResponse = await fetch(endpoint, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    "Accept": "application/json"
                },
                body: JSON.stringify({
                    password1: password1,
                    password2: password2,
                    email: email,
                    contactnumber: contactnumber,
                    firstname: firstname,
                    lastname: lastname,
                    preferredTitle: preferredTitle,
                    dateOfBirth: dateOfBirth,
                    address: address
                })
            })
            const data = await fetchResponse.json()
            console.log(data)
            if (data.status === 200) {
                alert("Account Created")
            } else {
                alert("Account Creation Failed")
            }
        } else {
            alert("Passwords do not match")
        }
    }

    const submitChangePassword = async () => {
        let endpoint = APPDATA.server + "/account/change-password"
        const fetchResponse = await fetch(endpoint, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                "Accept": "application/json"
            },
            body: JSON.stringify({
                usernameEmail: usernameEmail,
            })
        })
        const data = await fetchResponse.json()
        alert("We have sent you information on resetting your password to your inbox")
    }

    function check(e) {
        if (e.key === 'Enter') {
            submitLogin()
        }
    };

    if (pageState === 'login') {
        return (
            <div>
                <Header />
                <RSFCChatBot />
                <div className="apContainer">
                    <div className="apInnerContainer">
                        <h1 className="apTitle">Hello, Welcome Back!</h1>
                        <h2 className="apSubtitle">Login to your account</h2>

                        <div className="apForms">
                            <div className="apFormContainer">
                                <h3 className="apFormTitle">Username</h3>
                                <input id="username" type="text" value={username} style={{ width: "100%" }} onKeyDown={(e) => check(e)} onSubmitCapture={submitLogin} onChange={(e) => setUsername(e.target.value)} className="apTextField" placeholder="Enter your username" />
                                <h3 className="apFormTitle">Password</h3>
                                <input id="password" type="password" value={password} style={{ width: "100%" }} onKeyDown={(e) => check(e)} onSubmitCapture={submitLogin} onChange={(e) => setPassword(e.target.value)} className="apTextField" placeholder="Enter your password" />

                                <div className="apSubButtonContainer">
                                    <a className="apSubButton" onClick={() => setPageState("register")}>Register membership</a>
                                    <a className="apSubButton" onClick={() => setPageState("forget")}>Forget Password</a>
                                </div>
                                <div className="apButtonContainer">
                                    <a id="apSubmitButton" onClick={submitLogin}>Login</a>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
                <Footer />
            </div>
        );
    } else if (pageState === 'forget') {
        return (
            <div>
                <Header />
                <div className="apContainer">
                    <div className="apInnerContainer">
                        <h1 className="apTitle">Forget password?</h1>
                        <h2 className="apSubtitle">Don't worry, we got you!</h2>

                        <div className="apForms">
                            <div className="apFormContainer">
                                <h3 className="apFormTitle">Email/Username</h3>
                                <input type="text" value={usernameEmail} style={{ width: "100%" }} onChange={(e) => setUsernameEmail(e.target.value)} className="apTextField" placeholder="Enter your email or username" />
                                <div className="apSubButtonContainer">
                                    <a className="apSubButton" onClick={() => setPageState("login")}>Have an account? Login!</a>
                                    {/* <a className="apSubButton" onClick={()=>setPageState("forget")}>Forget Password</a> */}
                                </div>
                                <div className="apButtonContainer">
                                    <a id="apSubmitButton" onClick={submitChangePassword}>Request Change Password</a>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
                <Footer />
            </div>
        )
    } else {
        return (
            <div>
                <Header />
                <div className="apContainer">
                    <div className="apInnerContainer">
                        <h1 className="apTitle">Come join us, It's Free</h1>
                        <h2 className="apSubtitle">Create an account</h2>

                        <div className="apForms">
                            <div className="apFormContainer">
                                <div className="apFormFloat">
                                    <div className="apIndyComponents">
                                        <h3 className="apFormTitle">First Name</h3>
                                        <input type="text" value={firstname} className="apTextField" onChange={(e) => setFirstname(e.target.value)} placeholder="e.g. John" />
                                    </div>
                                    <div className="apIndyComponents">
                                        <h3 className="apFormTitle">Last Name</h3>
                                        <input type="text" value={lastname} className="apTextField" onChange={(e) => setLastname(e.target.value)} placeholder="e.g. Tan" />
                                    </div>
                                </div>
                                <div className="apFormFloat">
                                    <div className="apIndyComponents">
                                        <h3 className="apFormTitle">Preferred Name</h3>
                                        <input type="text" value={preferredTitle} className="apTextField" onChange={(e) => setPreferredTitle(e.target.value)} placeholder="e.g. Mr Tan" />
                                    </div>
                                    <div className="apIndyComponents">
                                        <h3 className="apFormTitle">Contact Number</h3>
                                        <input type="tel" value={contactnumber} className="apTextField" onChange={(e) => setContactnumber(e.target.value)} placeholder="e.g. 8123 4567" />
                                    </div>
                                </div>
                                <div className="apFormFloat">
                                    <div className="apIndyComponents">
                                        <h3 className="apFormTitle">Email</h3>
                                        <input type="email" value={email} className="apTextField" onChange={(e) => setEmail(e.target.value)} placeholder="e.g. johntan@gmail.com" />
                                    </div>
                                    <div className="apIndyComponents">
                                        <h3 className="apFormTitle">Date of Birth</h3>
                                        <input type="date" value={dateOfBirth} className="apTextField" onChange={(e) => setDateOfBirth(e.target.value)} placeholder="e.g. 23/01/2000" />
                                    </div>
                                </div>
                                <div className="apFormFloat">
                                    <div className="apIndyComponents">
                                        <h3 className="apFormTitle">Password</h3>
                                        <input type="password" value={password1} onChange={(e) => setPassword1(e.target.value)} className="apTextField" placeholder="password" />
                                    </div>
                                    <div className="apIndyComponents">
                                        <h3 className="apFormTitle">Re-enter password</h3>
                                        <input type="password" value={password2} onChange={(e) => setPassword2(e.target.value)} className="apTextField" placeholder="enter the same password" />
                                    </div>
                                </div>
                                <h3 className="apFormTitle">Mailing Address</h3>
                                <textarea type="text" className="apTextField" onChange={(e) => setAddress(e.target.value)} value={address} style={{ padding: 10, width: "100%", minHeight: "200px" }} placeholder="e.g Block 123 Hover Street Singapore 782832" />
                                <div className="apSubButtonContainer">
                                    <a className="apSubButton" onClick={() => setPageState("login")}>Have an account? Login!</a>
                                    {/* <a className="apSubButton" onClick={()=>setPageState("forget")}>Forget Password</a> */}
                                </div>
                                <div className="apButtonContainer">
                                    <a id="apSubmitButton" onClick={submitCreateAccount}>Create an Account</a>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
                <Footer />
            </div>
        )
    }
}

export default AccountPage;