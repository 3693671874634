import { Link,useLocation } from "react-router-dom";
import Footer from "../components/Footer";
import MemberHeader from "../components/MemberHeader";
import "../css/member-flight-detail.css";
import { APPDATA } from "../app-config";
import { useState,useEffect } from "react";
import Switch from "react-switch";


const MemberFlightPage = ({}) => {

    const {state} = useLocation();

    const [bookingDetail, setBookingDetail] = useState()
    const [pageState, setPageState] = useState("details")

    const [userID, setUserID] = useState(JSON.parse(window.sessionStorage.getItem("accessToken"))._id)

    const [startTime, setStartTime] = useState(state.start_time)
    const [endTime, setEndTime] = useState(state.end_time)
    const [remarks, setRemarks] = useState()
    const [distance, setDistance] = useState(0)

    const [header, setHeader] = useState("")
    const [uploadFiles, setUploadFiles] = useState()
    const [imagePath, setImagePath] = useState("")
    const [visibility, setVisibility] = useState(true)

    const submitPostFlight = async () => {
        let endpoint = APPDATA.server + "/booking/post-flight"
        let fetchResponse = await fetch(endpoint,{
            method:"POST",
            credentials: "include",
            headers:{
                "Content-Type":"application/json",
                "Accept":"application/json",
            },
            body:JSON.stringify({
                booking_id:state._id,
                start_time:startTime,
                end_time:endTime,
                distance:distance,
            })
        })
        let response = await fetchResponse.json()
        if (response.status === 200){
            // setPageState("success")
            alert("Flight Completed")
            window.location.href = "/members/booking"
        }else if (response.status === 403){
            window.location.href = "/account"
        }else if (response.status === 401){
            window.location.href = "/account"
        }else{
            alert("Error, unable to submit post flight")
        }
    }

    const uploadImage = async () => {
        let endpoint = APPDATA.server + "/community/upload-image"
        let formData = new FormData()
        formData.append("booking_id",state._id)
        formData.append("image",uploadFiles)
        let fetchResponse = await fetch(endpoint,{
            method:"POST",
            credentials: "include",
            headers:{
            },
            body:formData
        })
        let response = await fetchResponse.json()
        if (response.status === 200){
            console.log(response.data)
            setImagePath(response.data)
            uploadCommunityPost(response.data.file_path)
        }else{
            alert("Error, unable to upload image")
        }
    }

    const submitPaymentMade = async() => {
        let endpoint = APPDATA.server + "/booking/payment-made/" + state._id
        let fetchResponse = await fetch(endpoint,{
            method:"POST",
            credentials: "include",
            headers:{
                "Content-Type":"application/json",
                "Accept":"application/json",
            },
        })
        let response = await fetchResponse.json()
        if (response.status === 200){
        
            window.location.href = "/members/booking"
        }else if (response.status === 403){
            window.location.href = "/account"
        }else if (response.status === 401){
            window.location.href = "/account"
        }else{
            // alert("Error, unable to submit payment made")
        }
    }

    const uploadCommunityPost = async (path) => {
        if (header && remarks){
            let endpoint = APPDATA.server + "/community/upload-post"
            let fetchResponse = await fetch(endpoint,{
                method:"POST",
                credentials: "include",
                headers:{
                    "Content-Type":"application/json",
                    "Accept":"application/json",
                },
                body:JSON.stringify({
                    user_id:state.user_id,
                    booking_id:state._id,
                    header:header,
                    image:path,
                    remarks:remarks,
                    visibility:visibility
                })
            })
            let response = await fetchResponse.json()
            if (response.status === 200){
                console.log("posted")
                submitPostFlight()
            }else if (response.status === 403){
                window.location.href = "/account"
            }else if (response.status === 401){
                window.location.href = "/account"
            }else{
                alert("Error, unable to upload post")
            }
        }else{
            submitPostFlight()
        }
        
    }


    return ( 
        <div>
            <MemberHeader selected={"Booking"}/>
            <div className="mfdContainer">
                <div className="mfdInnerContainer">
                    <div className="mfdSplit">
                        <h1 className="mfdTitle">Flight Detail</h1>
                        <Link id="newBookingButton" to={"/members/booking"}>back</Link>
                    </div>

                    {pageState === "details"?<>
                        <h2>Flight Details</h2>

                        <div className="mfdSplit" style={{alignItems:"flex-start"}}>
                            <div className="mfdSplitLeft">
                                <div className="mfdItems">
                                    <p className="mfdItemTitle">Aircraft</p>
                                    <p className="mfdItemContent">{state.aircraft_type}</p>
                                </div>

                                <div className="mfdItems">
                                    <p className="mfdItemTitle">Flight Type</p>
                                    <p className="mfdItemContent">{state.flight_type}</p>
                                </div>

                                <div className="mfdItems">
                                    <p className="mfdItemTitle">Instructor</p>
                                    <p className="mfdItemContent">{state.flight_instructor?state.flight_instructor:"not-required"}</p>
                                </div>
                                <div className="mfdItems">
                                    <p className="mfdItemTitle">Examiner</p>
                                    <p className="mfdItemContent">{state.flight_examiner?state.flight_examiner:"not-required"}</p>
                                </div>
                            </div>

                            <div className="mfdSplitRight">
                                <div className="mfdItems">
                                    <p className="mfdItemTitle">Flight Date</p>
                                    <p className="mfdItemContent">{state.flight_date}</p>
                                </div>

                                <div className="mfdItems">
                                    <p className="mfdItemTitle">Flight Time</p>
                                    <p className="mfdItemContent">{state.start_time}-{state.end_time}</p>
                                </div>
                            </div>


                        </div>

                        {state.user_id === userID?<>
                        <h2>Payment Details</h2>
                        <p>To make the payment, please transfer via PayNow to 8123 7280</p>
                        <div className="mfdItems">
                            <p className="mfdItemTitle">Payment Status</p>
                            <p className="mfdItemContent">{state.payment_status}</p>
                        </div>

                        <div className="mfdItems">
                            <p className="mfdItemTitle">Payment Fee</p>
                            <p className="mfdItemContent">${state.payment_status === "requesting-payment"|| state.payment_status === "paid"?state.payment_fee:"-"}</p>
                        </div>
                        {state.payment_status ==="requesting-payment" && state.booking_status === "completed"?<div className="mfdButtonContainer">
                            <button className="mfdButton" onClick={()=>submitPaymentMade()} >Payment Made</button>
                        </div>:null}

                        {state.booking_status !=="completed" && state.booking_status !== "pending"?<div className="mfdButtonContainer">
                            <button className="mfdButton" style={{backgroundColor:"red",color:"white"}}>Request Flight Cancellation</button>
                            <button className="mfdButton" onClick={()=>setPageState("complete-flight")}>Completed Flight</button>
                        </div>:null}

                        {state.booking_status !=="completed"&& state.booking_status !== "confirmed"?<div className="mfdButtonContainer">
                            {/* <button className="mfdButton" style={{backgroundColor:"red",color:"white"}}>Request Flight Cancellation</button> */}
                            <p>For any flight cancellation or queries regarding your booking, do create a new support ticket</p>
                        </div>:null}
                        </>:null}



                    </>:null}
                    {pageState === "complete-flight"?
                    <>
                        <h2>Post-Flight Details</h2>

                        <div className="mfdSplit" style={{alignItems:"flex-start"}}>
                            <div className="mfdSplitLeft">
                                <div className="mfdItems">
                                    <p className="mfdItemTitle">Chock Off Time (Start Time)</p>
                                    <input className="mfdItemContent" value={startTime} onChange={(e)=>setStartTime(e.target.value)} type="time"/>
                                </div>
                                <div className="mfdItems">
                                    <p className="mfdItemTitle">Chock On Time (End Time)</p>
                                    <input className="mfdItemContent" value={endTime} onChange={(e)=>setEndTime(e.target.value)} type="time"/>
                                </div>
                            </div>
                            <div className="mdfSplitRight">
                                <div className="mfdItems">
                                    <p className="mfdItemTitle">Distance Travelled</p>
                                    <input className="mfdItemContent" value={distance} onChange={(e)=>setDistance(e.target.value)}/>
                                </div>
                            </div>
                            
                        </div>

                        <h2>Community Post</h2>
                        <div className="mfdSplit" style={{alignItems:"flex-start"}}>
                            <div className="mfdSplitLeft">
                                <div className="mfdInputContainer">
                                    <p className="mfdItemTitle">Post Heading</p>
                                    <input className="mfdInput" value={header} onChange={(e)=>setHeader(e.target.value)} placeholder="Write something about your flight experience!"/>
                                </div>
                                <div className="mfdInputContainer">
                                    <p className="mfdItemTitle">Flight Experience</p>
                                    <textarea className="mfdInput" value={remarks} onChange={(e)=>setRemarks(e.target.value)} placeholder="Write something about your flight experience!"/>
                                </div>
                            </div>
                            <div className="mfdSplitRight">
                                <div className="mfdInputContainer">
                                    <p className="mfdItemTitle">Upload Image</p>
                                    <input className="mfdUploadContent" onChange={(e)=>setUploadFiles(e.target.files[0])} type={"file"} name={'uploadFiles'}/>
                                </div>
                                <div className="mfdInputContainer">
                                    <p className="mfdItemTitle">Community Post Visibility</p>
                                    <div style={{display:"flex",flexDirection:"row",alignItems:"center",gap:"10px",marginTop:"0px"}}>
                                        <Switch checked={visibility} onChange={(checked,event)=>{setVisibility(checked);console.log(checked)}} />
                                        {visibility?<p>Post is visible to the Public</p>:<p>Post is for you only</p>}
                                    </div>
                                </div>
                            </div>
                            
                        </div>

                        
                        <div className="mfdButtonContainer">
                            <button className="mfdButton" onClick={()=>{
                                if (startTime === "" || endTime === "" || distance === "" || header === "" || remarks === ""){
                                    alert("Please fill in all the fields")
                                }else if (new Date(endTime)<new Date(startTime)){
                                    alert("End time cannot be earlier than start time")
                                }else if (distance < 0){
                                    alert("Distance cannot be negative")
                                }else{
                                    submitPostFlight()
                                }
                            }}>Submit Post Flight</button>
                        </div>
                    </>
                    :null}
                    
                    

                </div>
            </div>
            <Footer/>
        </div>
     );
}
 
export default MemberFlightPage;