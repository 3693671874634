import React from 'react';

const ActionProvider = ({ createChatBotMessage, setState, children }) => {

  const handleGreeting = () => {
    const phrases = ["Hello there!","Good day!","Welcome to RSFC!","How can I help you?"];


    const botMessage = createChatBotMessage(phrases[Math.floor(Math.random() * phrases.length)],{widget:'options'});
    setState((prev) => ({
      ...prev,
      messages: [...prev.messages, botMessage],
    }));
  }

  const handleRSFC = () => {
    const botMessage = createChatBotMessage("RSFC or Republic of Singapore Flying Club is a non-profit organisation that aims to promote the sport of flying in Singapore. We are a community of pilots and aviation enthusiasts who share a common passion for flying. We are also a registered flying school with the Civil Aviation Authority of Singapore (CAAS) and a member of the Singapore Flying Club Association (SFCA).");
    setState((prev) => ({
      ...prev,
      messages: [...prev.messages, botMessage],
    }));
  }

  const handleAge = () => {
    const botMessage = createChatBotMessage("You can start flight training at any age. However, the minimum age to apply for your Student Pilot License is 16 Years old.");
    setState((prev) => ({
      ...prev,
      messages: [...prev.messages, botMessage],
    }));
  }

  const handleCostLicense = () => {
    const botMessage = createChatBotMessage("For the cost, it varies between students, it costs approximately $15000.");
    setState((prev) => ({
      ...prev,
      messages: [...prev.messages, botMessage],
    }));
  }

  const handleAirport = () => {
    const botMessage = createChatBotMessage("For all flight training, we will be using Seletar Airport (WSSL). It has a single runway, and it is widely used for general aviation in Singapore.");
    setState((prev) => ({
      ...prev,
      messages: [...prev.messages, botMessage],
    }));
  }

  const handlePeople = () => {
    const botMessage = createChatBotMessage("It depends on your instructor. Up there, we want to ensure flight safety. If your instructor is confident that you can handle, why not?");
    setState((prev) => ({
      ...prev,
      messages: [...prev.messages, botMessage],
    }));
  }

  const handleRegisterLicense = () => {
    const botMessage = createChatBotMessage("You will have to register with CAAS in Singapore and from there you may apply to be a member with our club.");
    setState((prev) => ({
      ...prev,
      messages: [...prev.messages, botMessage],
    }));
  }

  const handlePilotLicense = () => {
    const botMessage = createChatBotMessage("Usually on average it takes 8 months to 20 months. It depends on the student's commitment and capability.");
    setState((prev) => ({
      ...prev,
      messages: [...prev.messages, botMessage],
    }));
  }

  const handleDuration = () => {
    const botMessage = createChatBotMessage("It depends on the number of hours you book for your flight.");
    setState((prev) => ({
      ...prev,
      messages: [...prev.messages, botMessage],
    }));
  }

    
  const handleCostMembership = () => {
    const botMessage = createChatBotMessage("You can sign up for a free account to view what our pilots post on the community page!");
    const botMessage2 = createChatBotMessage("You could also join us as a Associate or Ordinary member to learn how to fly. Prices are on average about SGD250 per month.");
    setState((prev) => ({
      ...prev,
      messages: [...prev.messages, botMessage, botMessage2],
    }));
  }

  const handleOperatingHours = () => {
    const botMessage = createChatBotMessage("Operating Hours: Monday, Wednesday, Friday-Sunday: 9am-5pm");
    const botMessage2 = createChatBotMessage("Tuesday, Thursday: Closed");
    setState((prev) => ({
      ...prev,
      messages: [...prev.messages, botMessage, botMessage2],
    }));
  }

  const handleContact = () => {
    const botMessage = createChatBotMessage("Contact Details: (+65) 6481 0200, manager@singaporeflyingclub.com");
    setState((prev) => ({
      ...prev,
      messages: [...prev.messages, botMessage],
    }));
  }

  const handleLocation = () => {
    const botMessage = createChatBotMessage("Location: 700 W Camp Rd, Unit 05-01, Singapore 797649");
    const botMessage2 = createChatBotMessage("Bus Services: 103, 117, 117M");
    setState((prev) => ({
      ...prev,
      messages: [...prev.messages, botMessage, botMessage2],
    }));
  }

  const handleTypeMembership = () => {
    const botMessage = createChatBotMessage("Types of Membership: Ordinary Member, Social Member, Associate Member");
    setState((prev) => ({
      ...prev,
      messages: [...prev.messages, botMessage],
    }));
  }

  const handleEventType = () => {
    const botMessage = createChatBotMessage("There are 7 types of Events: Gathering, Seminar, Class, Flight, Tour, Learning Trip, Others which members can sign up for.");
    setState((prev) => ({
      ...prev,
      messages: [...prev.messages, botMessage],
    }));
  }

  const handleAircraft = () => {
    const botMessage = createChatBotMessage("Our Aircrafts are based at Seletar Airport (WSSL).");
    setState((prev) => ({
      ...prev,
      messages: [...prev.messages, botMessage],
    }));
  }

  const handleWhatAircraft = () => {
    const botMessage = createChatBotMessage("Currently, the club has a Tampico TB-9 and a pending order for a Cessna 172 ");
    setState((prev) => ({
      ...prev,
      messages: [...prev.messages, botMessage],
    }));
  }

  const handleClub = () => {
    const botMessage = createChatBotMessage("Our Club is entirely run by several members. The club consist of the President, Lingam Paspathy, Hon. Treasurer, Patrick Low and other committee members.");
    setState((prev) => ({
      ...prev,
      messages: [...prev.messages, botMessage],
    }));
  }

  const handleClubInterest = () => {
    const botMessage = createChatBotMessage("You can head over to our Membership Page to apply as a Member!");
    setState((prev) => ({
      ...prev,
      messages: [...prev.messages, botMessage],
    }));
  }

  const handleFlightBooking = () => {
    const botMessage = createChatBotMessage("We have an online booking system that allows members to make flight bookings and see which time slots are available.");
    setState((prev) => ({
      ...prev,
      messages: [...prev.messages, botMessage],
    }));
  };

  const handleRequirements = () => {
    const botMessage = createChatBotMessage("To obtain a PPL, you must be at least 17 years old be able to speak and understand the English Language used for radio-telephony communications and meet at least Class 2 medical requirements.");
    setState((prev) => ({
      ...prev,
      messages: [...prev.messages, botMessage],
    }));
  };

  const handleBenefits = () => {
    const botMessage = createChatBotMessage("The benefits of flying at RSFC are: 1. We are a not-for-profit organization so all revenues stay in the Club and goes toward equipment acquisitions and improvements.");
    const botMessage2 = createChatBotMessage("2. Our instructors have vast experience in flying.");
    const botMessage3 = createChatBotMessage("3. We hold frequent gatherings to discuss on flight topics.");
    setState((prev) => ({
      ...prev,
      messages: [...prev.messages, botMessage, botMessage2, botMessage3],
    }));
  };

  const handleTraining = () => {
    const botMessage = createChatBotMessage("The Republic of Singapore Flying Club has several experienced Flight Instructors. Our admin will assign your instructor based on their availability. ");
    setState((prev) => ({
      ...prev,
      messages: [...prev.messages, botMessage],
    }));
  };

  const handleEstablish = () => {
    const botMessage = createChatBotMessage("The club was established in April 1928 after many months of planning. April saw the first flight by Flt. Lt. Carnegie, R.A.F. in a DH 60 Cirrus Moth Seaplane,registration G-EBUJ. There were at the time: 14 Honorary members, 90 Ordinary Members, 8 Subscribing Members and two DH 60 Moths.");
    setState((prev) => ({
      ...prev,
      messages: [...prev.messages, botMessage],
    }));
  };

  const handleMemberUpgrade = () => {
    const botMessage = createChatBotMessage("If you would like to upgrade your membership, we will apply any previously paid registration fees toward the upgraded membership. There may be a waiting period to upgrade your membership.");
    setState((prev) => ({
      ...prev,
      messages: [...prev.messages, botMessage],
    }));
  };

  const handleAdvanceBooking = () => {
    const botMessage = createChatBotMessage("There is no restriction on how far you can book in advance.");
    setState((prev) => ({
      ...prev,
      messages: [...prev.messages, botMessage],
    }));
  };

  const handlePet = () => {
    const botMessage = createChatBotMessage("!!!NO PETS ALLOWED ON BOARD!!!");
    setState((prev) => ({
      ...prev,
      messages: [...prev.messages, botMessage],
    }));
  };

  const handleBookingRules = () => {
    const botMessage = createChatBotMessage("There are no booking rules. Booking flight slots are based on first come first serve basis. ");
    setState((prev) => ({
      ...prev,
      messages: [...prev.messages, botMessage],
    }));
  };

  const handleFlightRate = () => {
    const botMessage = createChatBotMessage("You can sign up as a Ordinary Member to view our flight rates!");
    setState((prev) => ({
      ...prev,
      messages: [...prev.messages, botMessage],
    }));
  };

  const handlePayment = () => {
    const botMessage = createChatBotMessage("The only payment method available is PayNow.");
    setState((prev) => ({
      ...prev,
      messages: [...prev.messages, botMessage],
    }));
  };

  const handlePay = () => {
    const botMessage = createChatBotMessage("After your flight, an email will be sent to you, to request for payment via PayNow.");
    setState((prev) => ({
      ...prev,
      messages: [...prev.messages, botMessage],
    }));
  };

  const handleDifferent = () => {
    const botMessage = createChatBotMessage("Our club has a long history as compared to other flying clubs. Besides that, we are a non-profit flying club that provide affordable flying rates in Singapore.");
    setState((prev) => ({
      ...prev,
      messages: [...prev.messages, botMessage],
    }));
  };

  const handleHealth = () => {
    const botMessage = createChatBotMessage("All pilots must pass an CAAS medical assessment before being able to solo or receive a pilot certificate.  It is wisest to ensure you can pass the physical before incurring flight costs and finding out later you are medically disqualified. A PPL requires a valid medical assessment of class 2. ");
    setState((prev) => ({
      ...prev,
      messages: [...prev.messages, botMessage],
    }));
  };

  const handleExperience = () => {
    const botMessage = createChatBotMessage("Absolutely not, as long as you are interested, we will teach you everything!");
    setState((prev) => ({
      ...prev,
      messages: [...prev.messages, botMessage],
    }));
  };

  const handleGlasses = () => {
    const botMessage = createChatBotMessage("People wearing spectacles can become a Pilot as long as your glasses or contact lenses correct your vision to the required degree. Your medical exam will confirm whether your eyes meet the required degree of sight.");

    setState((prev) => ({
      ...prev,
      messages: [...prev.messages, botMessage],
    }));
  };

  const handleQuestion = () => {
    const botMessage = createChatBotMessage("Please send your question to manager@singaporeflyingclub.com");

    setState((prev) => ({
      ...prev,
      messages: [...prev.messages, botMessage],
    }));
  };

  const handleSafe = () => {
    const botMessage = createChatBotMessage("Yes, flying is safe. Well-built and well-maintained aircraft flown by a competent and prudent pilot make flying as safe or safer than many other forms of transportation.");

    setState((prev) => ({
      ...prev,
      messages: [...prev.messages, botMessage],
    }));
  };

  const handleSchool = () => {
    const botMessage = createChatBotMessage("You can start training for your PPL whlist in school, but if you are thinking of pursuing a career in aviation, you need to aim at qualifying as a Commercial Pilot as early as possible. ");
    const botMessage2 = createChatBotMessage("Having 2 Training flights a week whlist in school is adequate. Keeping in mind that the more regular one flies, the quicker the learning process.");

    setState((prev) => ({
      ...prev,
      messages: [...prev.messages, botMessage, botMessage2],
    }));
  };

  const handleMedical = () => {
    const botMessage = createChatBotMessage("If you are applying for any of the CAAS Flight Crew licences, you must hold a valid Medical Assessment of the relevant class.");
    const botMessage2 = createChatBotMessage("Medical Assessment Class 1, License Type: ATPL, CPL, MPL, PPL-IR");
    const botMessage3 = createChatBotMessage("Medical Assessment Class 2, License Type: PPL, SPL");

    setState((prev) => ({
      ...prev,
      messages: [...prev.messages, botMessage, botMessage2, botMessage3],
    }));
  };

  const handleRegular = () => {
    const botMessage = createChatBotMessage("The club is open 5 days a week so you can book as many sessions as you want.");

    setState((prev) => ({
      ...prev,
      messages: [...prev.messages, botMessage],
    }));
  };

  const handleFast = () => {
    const botMessage = createChatBotMessage("Our Tampico TB-9 has a top speed of 165 knots while our Cessna 172 has a top speed of 122 knots.");

    setState((prev) => ({
      ...prev,
      messages: [...prev.messages, botMessage],
    }));
  };

  const handleMember = () => {
    const botMessage = createChatBotMessage("Currently, our club has around 100 members.");

    setState((prev) => ({
      ...prev,
      messages: [...prev.messages, botMessage],
    }));
  };

  const handleTimeslot = () => {
    const botMessage = createChatBotMessage("To check for the available flight slots, you can head over to our members page to find out!");

    setState((prev) => ({
      ...prev,
      messages: [...prev.messages, botMessage],
    }));
  }

  const handlePassword = () => {
    const botMessage = createChatBotMessage("To reset your password, you can head over to the login portal and click on, Forget Password.");

    setState((prev) => ({
      ...prev,
      messages: [...prev.messages, botMessage],
    }));
  }

  const handlePayMember = () => {
    const botMessage = createChatBotMessage("For more payment information, you can contact us at manager@singaporeflyingclub.com for more information.");

    setState((prev) => ({
      ...prev,
      messages: [...prev.messages, botMessage],
    }));
  }

  const handleUpcomingEvent = () => {
    const botMessage = createChatBotMessage("To check for upcoming events, head over to the community page under member's corner.");

    setState((prev) => ({
      ...prev,
      messages: [...prev.messages, botMessage],
    }));
  }

  const handleFlightPlan = () => {
    const botMessage = createChatBotMessage("To file for a file plan, you can refer to https://www.caas.gov.sg/e-services-forms/e-services/aeronautical-information-management-system.");

    setState((prev) => ({
      ...prev,
      messages: [...prev.messages, botMessage],
    }));
  }


  const handleCannotUnderstand = () => {
    const phrases = ["I did not quite get that, please try asking again", "Maybe you could try rephrasing your question"]
    
    const botMessage = createChatBotMessage(phrases[Math.floor(Math.random() * phrases.length)]);
    setState((prev) => ({
      ...prev,
      messages: [...prev.messages, botMessage],
    }));
  };



  return (
    <div>
      {React.Children.map(children, (child) => {
        return React.cloneElement(child, {
          actions: {
            handleGreeting,
            handleRSFC,
            handleAge,
            handleCostLicense,
            handleAirport,
            handlePeople,
            handleRegisterLicense,
            handlePilotLicense,
            handleDuration,
            handleCostMembership,
            handleOperatingHours,
            handleContact,
            handleLocation,
            handleTypeMembership,
            handleEventType,
            handleAircraft,
            handleWhatAircraft,
            handleClub,
            handleClubInterest,
            handleFlightBooking,
            handleRequirements,
            handleCannotUnderstand,
            handleBenefits,
            handleTraining,
            handleEstablish,
            handleMemberUpgrade,
            handleAdvanceBooking,
            handlePet,
            handleBookingRules,
            handleFlightRate,
            handlePayment,
            handlePay,
            handleDifferent,
            handleHealth,
            handleExperience,
            handleGlasses,
            handleQuestion,
            handleSafe,
            handleSchool,
            handleMedical,
            handleRegular,
            handleFast,
            handleMember,
            handleTimeslot,
            handlePassword,
            handlePayMember,
            handleUpcomingEvent,
            handleFlightPlan,
          },
        });
      })}
    </div>
  );
};

export default ActionProvider;