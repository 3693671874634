import { useState, useEffect } from "react";

import AdminHeader from "../components/AdminHeader";
import "../css/admin-member-detail-page.css";

import { Link, useNavigate, useLocation } from "react-router-dom";
import Footer from "../components/Footer";
import { APPDATA } from "../app-config";
import Switch from "react-switch";

const AdminMemberDetailPage = () => {

    const { state } = useLocation()
    console.log(state)

    let date_joined = new Date(parseInt(state.date_joined))
    let date_of_birth = new Date(parseInt(state.date_of_birth))
    //convert to yyyy-mm-dd if single digit, make it double digit
    var date_joined_day = date_joined.getDate() < 10 ? "0" + date_joined.getDate() : date_joined.getDate()
    var date_joined_month = date_joined.getMonth() < 10 ? "0" + (date_joined.getMonth() + 1) : (date_joined.getMonth() + 1)
    var date_joined_year = date_joined.getFullYear()
    var date_joined_string = date_joined_year + "-" + date_joined_month + "-" + date_joined_day

    var date_of_birth_day = date_of_birth.getDate() < 10 ? "0" + date_of_birth.getDate() : date_of_birth.getDate()
    var date_of_birth_month = date_of_birth.getMonth() < 10 ? "0" + (date_of_birth.getMonth() + 1) : (date_of_birth.getMonth() + 1)
    var date_of_birth_year = date_of_birth.getFullYear()
    var date_of_birth_string = date_of_birth_year + "-" + date_of_birth_month + "-" + date_of_birth_day





    const [firstName, setFirstName] = useState(state.first_name)
    const [lastName, setLastName] = useState(state.last_name)
    const [salutation, setSalutation] = useState(state.salutation)
    const [email, setEmail] = useState(state.email)
    const [phone, setPhone] = useState(state.contact_number)
    const [address, setAddress] = useState(state.address)
    const [username, setUsername] = useState(state.username)
    const [accountType, setAccountType] = useState(state.account_type)
    const [membershipeType, setMembershipeType] = useState(state.membership_type)
    const [dateJoined, setDateJoined] = useState(date_joined_string)
    const [dateOfBirth, setDateOfBirth] = useState(date_of_birth_string)
    const [examiner, setExaminer] = useState(state.examiner)
    const [instructor, setInstructor] = useState(state.instructor)
    const [flightHours, setFlightHours] = useState(state.flight_hours)
    const [flights, setFlights] = useState(state.flights)
    const [title, setTitle] = useState(state.title)
    const [licenseType, setLicenseType] = useState(state.license_type)
    const [licenseNumber, setLicenseNumber] = useState(state.license_number)
    const [accountRemarks, setAccountRemarks] = useState(state.account_remarks)
    const [instructorRated, setInstructorRated] = useState(state.instructor_rated)
    const [examinerRated, setExaminerRated] = useState(state.examiner_rated)
    const [certificateOfExpiry, setCertificateOfExpiry] = useState(state.certificate_of_expiry)
    const [medicalDate, setMedicalDate] = useState(state.medical_date)
    const [accountStatus, setAccountStatus] = useState(state.account_status)
    
    
    const [userBooking, setUserBooking] = useState([])



    const navigate = useNavigate()


    const submitPasswordChange = async () => {
        let endpoint = APPDATA.server + "/account/change-password"
        let fetchResponse = await fetch(endpoint, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                usernameEmail: email,
            })
        })
        let response = await fetchResponse.json()
        if (response.status == 200) {
            alert("Password Change request has been made")
        }

    }
    const deleteUser = () => {
        let endpoint = APPDATA.server + "/account/delete"
        fetch(endpoint, {
            method: "POST",
            credentials: "include",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                user_id: state._id,
            })
        }).then(response => response.json()).then(data => {
            if (data.status == 200) {
                alert("User has been deleted")
                navigate("/admin/members")
            }
        })
    }

    

    const submitChanges = async () => {
        let endpoint = APPDATA.server + "/account/update/" + state._id
        let fetchResponse = await fetch(endpoint, {
            credentials: "include",
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                first_name: firstName,
                last_name: lastName,
                salutation: salutation,
                email: email,
                contact_number: phone,
                address: address,
                username: username,
                account_type: accountType,
                membership_type: membershipeType,
                date_joined: dateJoined,
                date_of_birth: dateOfBirth,
                examiner: examiner,
                instructor: instructor,
                flight_hours: flightHours,
                flights: flights,
                title: title,
                license_type: licenseType,
                license_number: licenseNumber,
                instructor_rated: instructorRated,
                examiner_rated: examinerRated,
                account_remarks: accountRemarks,
                certificate_of_expiry: certificateOfExpiry,
                medical_date: medicalDate,
                account_status: accountStatus
            })
        })
        let response = await fetchResponse.json()
        console.log(response)
        if (response.status === 200) {
            alert("Changes Saved")
        } else {
            alert("Changes Failed")
        }
    }

    const fetchUserBooking = async () => {
        let endpoint = APPDATA.server + "/booking/" + state._id
        let fetchResponse = await fetch(endpoint, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + JSON.parse(sessionStorage.getItem("accessToken")).token,
                "x-access-token": JSON.parse(sessionStorage.getItem("accessToken")).token
            }
        })
        let response = await fetchResponse.json()
        if (response.status === 200) {
            setUserBooking(response.data.reverse())
        } else {
            console.log(response)
        }

    }

    useEffect(() => {
        fetchUserBooking()
    }, [])

    return (
        <div>
            <AdminHeader selected={"Members"} />
            <div className="amdpContainer">
                <div className="amdpInnerContainer">
                    <h1 className="amdpTitle">Member Details</h1>

                    <h2>Name</h2>
                    <div className="amdpMemberInfoSplit">
                        <div className="amdpMemberInfoLeft">
                            <div className="amdpMemberInfo">
                                <p className="amdpMemberInfoTitle">First Name</p>
                                <input type="text" className="amdpMemberField" value={firstName} onChange={(e) => setFirstName(e.target.value)} />
                            </div>
                        </div>

                        <div className="amdpMemberInfoRight">
                            <div className="amdpMemberInfo">
                                <p className="amdpMemberInfoTitle">Last Name</p>
                                <input type="text" className="amdpMemberField" value={lastName} onChange={(e) => setLastName(e.target.value)} />
                            </div>
                        </div>
                    </div>
                    <div className="amdpMemberInfoSplit">
                        <div className="amdpMemberInfoLeft">
                            <div className="amdpMemberInfo">
                                <p className="amdpMemberInfoTitle">Preferred Name</p>
                                <input type="text" className="amdpMemberField" value={salutation} onChange={(e) => setSalutation(e.target.value)} />
                            </div>
                        </div>
                        <div className="amdpMemberInfoLeft">
                            <div className="amdpMemberInfo">
                                <p className="amdpMemberInfoTitle">Username</p>
                                <input type="text" className="amdpMemberField" value={username} onChange={(e) => setUsername(e.target.value)} />
                            </div>
                        </div>
                    </div>

                    <h2>Contact Details</h2>
                    <div className="amdpMemberInfoSplit">
                        <div className="amdpMemberInfoLeft">
                            <div className="amdpMemberInfo">
                                <p className="amdpMemberInfoTitle">Email Address</p>
                                <input type="text" className="amdpMemberField" value={email} onChange={(e) => setEmail(e.target.value)} />
                            </div>
                        </div>

                        <div className="amdpMemberInfoRight">
                            <div className="amdpMemberInfo">
                                <p className="amdpMemberInfoTitle">Contact Number</p>
                                <input type="text" className="amdpMemberField" value={phone} onChange={(e) => setPhone(e.target.value)} />
                            </div>
                        </div>
                    </div>
                    <div className="residentialAddress">
                        <p className="amdpMemberInfoTitle">Residential Address</p>
                        <textarea type="text" className="amdpMemberField" style={{ width: "100%" }} value={address} onChange={(e) => setAddress(e.target.value)} />
                    </div>


                    <h2>Recent Bookings</h2>
                    <div className="amdpBookings">
                        {userBooking.map((booking) => {
                            if (booking.user_id === state._id) {
                                return (
                                    <div className="amdpBooking">
                                        <div className="amdpBookingInfo">
                                            <p className="amdpBookingInfoTitle">Booking ID</p>
                                            <p className="amdpBookingInfoValue">{booking._id}</p>
                                        </div>
                                        <div className="amdpBookingInfo">
                                            <p className="amdpBookingInfoTitle">Aircraft</p>
                                            <p className="amdpBookingInfoValue">{booking.aircraft_type}</p>
                                        </div>
                                        <div className="amdpBookingInfo">
                                            <p className="amdpBookingInfoTitle">Flight Date</p>
                                            <p className="amdpBookingInfoValue">{booking.flight_date}</p>
                                        </div>
                                        <div className="amdpBookingInfo">
                                            <p className="amdpBookingInfoTitle">Booking Time</p>
                                            <p className="amdpBookingInfoValue">{booking.start_time}-{booking.end_time}</p>
                                        </div>
                                        <div className="amdpBookingInfo">
                                            <p className="amdpBookingInfoTitle">Booking Status</p>
                                            <p className="amdpBookingInfoValue">{booking.booking_status}</p>
                                        </div>
                                        <div className="amdpBookingInfo">
                                            <p className="amdpBookingInfoTitle">Payment Status</p>
                                            <p className="amdpBookingInfoValue">{booking.payment_status}</p>
                                        </div>

                                        <a className="amdpBookingButton" onClick={() => {
                                            navigate(`/admin/booking/${booking._id}`, { state: booking })
                                        }} to={"/admin/booking/" + booking._id}>view</a>
                                    </div>
                                )
                            }
                        })}
                    </div>

                    <h2>Personal Details</h2>
                    <div className="amdpMemberInfoSplit">
                        <div className="amdpMemberInfoLeft">
                            <div className="amdpMemberInfo">
                                <p className="amdpMemberInfoTitle">Date Of Birth</p>
                                <input type="date" className="amdpMemberField" value={dateOfBirth} onChange={(e) => setDateOfBirth(e.target.value)} />
                            </div>
                        </div>

                        <div className="amdpMemberInfoRight">
                            <div className="amdpMemberInfo">
                                <p className="amdpMemberInfoTitle">Title</p>
                                <input type="text" className="amdpMemberField" value={title} onChange={(e) => setTitle(e.target.value)} />
                            </div>
                        </div>
                    </div>
                    <div className="amdpMemberInfoSplit">
                        <div className="amdpMemberInfoLeft">
                            <div className="amdpMemberInfo">
                                <p className="amdpMemberInfoTitle">Flight Cycles</p>
                                <input type="text" className="amdpMemberField" value={flights} onChange={(e) => setFlights(e.target.value)} />
                            </div>
                        </div>

                        <div className="amdpMemberInfoRight">
                            <div className="amdpMemberInfo">
                                <p className="amdpMemberInfoTitle">Flying Hours</p>
                                <input type="text" className="amdpMemberField" value={flightHours} onChange={(e) => setFlightHours(e.target.value)} />
                            </div>
                        </div>
                    </div>
                    <div className="amdpMemberInfoSplit">
                        <div className="amdpMemberInfoLeft">
                            <div className="amdpMemberInfo">
                                <p className="amdpMemberInfoTitle">Instructor</p>
                                <input type="text" className="amdpMemberField" value={instructor} onChange={(e) => setInstructor(e.target.value)} />
                            </div>
                        </div>

                        <div className="amdpMemberInfoRight">
                            <div className="amdpMemberInfo">
                                <p className="amdpMemberInfoTitle">Examiner</p>
                                <input type="text" className="amdpMemberField" value={examiner} onChange={(e) => setExaminer(e.target.value)} />
                            </div>
                        </div>
                    </div>

                    <h2>License Details</h2>
                    <div className="amdpMemberInfoSplit">
                        <div className="amdpMemberInfoLeft">
                            <div className="amdpMemberInfo">
                                <p className="amdpMemberInfoTitle">License Type</p>
                                <select className="amdpMemberField" value={licenseType} onChange={(e) => setLicenseType(e.target.value)} name="" id="">
                                    <option value="">No License</option>
                                    <option value="spl">Student Pilot License</option>
                                    <option value="ppl">Private Pilot License</option>
                                    <option value="cpl">Commercial Pilot License</option>
                                    <option value="atpl">Airline Transport Pilot License</option>
                                    <option value="mcpl">Multi-Crew Pilot License</option>
                                </select>
                            </div>
                        </div>

                        <div className="amdpMemberInfoRight">
                            <div className="amdpMemberInfo">
                                <p className="amdpMemberInfoTitle">License Number</p>
                                <input type="text" className="amdpMemberField" value={licenseNumber} onChange={(e) => setLicenseNumber(e.target.value)} />
                            </div>
                        </div>
                    </div>

                    <div className="amdpMemberInfoSplit">
                        <div className="amdpMemberInfoLeft">
                            <div className="amdpMemberInfo">
                                <p className="amdpMemberInfoTitle">Certificate of Expiry Date</p>
                                <input type="date" className="amdpMemberField" value={certificateOfExpiry} onChange={(e) => setCertificateOfExpiry(e.target.value)} />
                            </div>
                        </div>

                        <div className="amdpMemberInfoRight">
                            <div className="amdpMemberInfo">
                                <p className="amdpMemberInfoTitle">Next Medical Due</p>
                                <input type="date" className="amdpMemberField" value={medicalDate} onChange={(e) => setMedicalDate(e.target.value)} />
                            </div>
                        </div>
                    </div>

                    <h2>Account Details</h2>
                    <div className="amdpMemberInfoSplit">
                        <div className="amdpMemberInfoLeft">
                            <div className="amdpMemberInfo">
                                <p className="amdpMemberInfoTitle">Account Type</p>
                                <select className="amdpMemberField" value={accountType} onChange={(e) => setAccountType(e.target.value)} name="" id="">
                                    <option value="user">User</option>
                                    <option value="admin">Admin</option>
                                    <option value="super-admin">Super Admin</option>
                                </select>
                            </div>
                        </div>

                        <div className="amdpMemberInfoRight">
                            <div className="amdpMemberInfo">
                                <p className="amdpMemberInfoTitle">Membership</p>
                                <select className="amdpMemberField" value={membershipeType} onChange={(e) => setMembershipeType(e.target.value)} name="" id="">
                                    <option value="associate">Associate Member</option>
                                    <option value="social">Social Member</option>
                                    <option value="ordinary">Ordinary Member</option>
                                </select>
                            </div>
                        </div>
                    </div>

                    <div className="amdpMemberInfoSplit">
                        <div className="amdpMemberInfoLeft">
                            <div className="amdpMemberInfo">
                                <p className="amdpMemberInfoTitle">Instructor Rated</p>
                                <Switch checked={instructorRated} onChange={(checked, event) => { setInstructorRated(checked); console.log(checked) }} />
                            </div>
                        </div>

                        <div className="amdpMemberInfoRight">
                            <div className="amdpMemberInfo">
                                <p className="amdpMemberInfoTitle">Examiner Rated</p>
                                <Switch checked={examinerRated} onChange={(checked, event) => { setExaminerRated(checked); console.log(checked) }} />
                            </div>
                        </div>
                    </div>

                    <div className="amdpMemberInfoSplit">
                        <div className="amdpMemberInfoLeft">
                            <div className="amdpMemberInfo">
                                <p className="amdpMemberInfoTitle">Date Joined</p>
                                <input type="date" className="amdpMemberField" value={dateJoined} onChange={(e) => setDateJoined(e.target.value)} />
                            </div>
                        </div>
                        <div className="amdpMemberInfoRight">
                            <div className="amdpMemberInfo">
                                <p className="amdpMemberInfoTitle">Account Status</p>
                                <select className="amdpMemberField" value={accountStatus} onChange={(e) => setAccountStatus(e.target.value)} name="" id="">
                                    <option value="active">Active</option>
                                    <option value="banned">Suspended</option>
                                    <option value="disabled">Disabled</option>
                                </select>
                            </div>
                        </div>
                    </div>

                    <div className="residentialAddress">
                        <p className="amdpMemberInfoTitle">Account Remarks</p>
                        <textarea type="text" className="amdpMemberField" style={{ width: "100%" }} value={accountRemarks} onChange={(e) => setAccountRemarks(e.target.value)} />
                    </div>

                    



                    <div className="amdpButtonContainer">
                        <button className="amdpButton" onClick={submitPasswordChange}>Request Password Change</button>
                        <button className="amdpButton" style={{ backgroundColor: "red", color: "white" }} onClick={deleteUser}>Delete User</button>
                        <button className="amdpButton" style={{ backgroundColor: "green" }} onClick={submitChanges}>Save Changes</button>
                    </div>

                </div>
            </div>
            <Footer />
        </div>
    );
}

export default AdminMemberDetailPage;