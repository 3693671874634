import Footer from "../components/Footer";
import Header from "../components/Header"
import RSFCChatBot from "../components/RSFCChatBot";
import "../css/index-404-page.css";

const MaintenancePage = () => {
    return ( 
        <div style={{display:"flex",flexDirection:"column",justifyContent:"space-between",height:"100vh"}}>
            <Header/>
            <RSFCChatBot/>
            <div className="errorContainer">
                <img src="./img/header.png" alt="" />
                <div className="errorInnerContainer">
                    <h1>Sorry, we are repairing this website</h1>
                    <p>We are maintaining this website now, it will be back up soon</p>
                    <p>If you need any support, please email <a href="mailto:manager@singaporeflyingclub.com">manager@singaporeflyingclub.com</a> for help</p>
                </div>
            </div>
            <Footer/>
        </div>

     );
}
 
export default MaintenancePage;