import {useState, useEffect} from 'react';
import Footer from '../components/Footer';
import MemberHeader from '../components/MemberHeader';
import { Link } from 'react-router-dom';
import "../css/member-profile-page.css"
import { APPDATA } from '../app-config';


const MemberProfilePage = () => {

    const [user, setUser] = useState()
    const [flights, setFlights] = useState()
    const [community, setCommunity] = useState([])

    useEffect(() => {
        fetchUserProfile(window.location.href.split("/")[5])
    }, [])

    const fetchUserProfile = async (id) => {
        let endpoint = APPDATA.server + "/community/" + id
        console.log(endpoint)
        let fetchResponse = await fetch(endpoint,{
            method: "GET",
            credentials: "include",
            headers: {
                "Content-Type": "application/json",
            }
        })
        let response = await fetchResponse.json()
        console.log(response)
        if (response.status === 403) {
            window.location.href = "/account"
        }else if (response.status === 401) {
            window.location.href = "/account"
        }else{
            console.log(response)
            setUser(response.data)
            fetchRecentFlights(id)
            fetchUserCommunityPosts(id)
        }

    }

    const fetchRecentFlights = async (id) => {
        let endpoint = APPDATA.server + "/members/" + id + "/flights"
        let fetchResponse = await fetch(endpoint,{
            method: "GET",
            credentials: "include",
            headers: {
                "Content-Type": "application/json",
            }
        })
        let response = await fetchResponse.json()
        console.log(response)
        if (response.status === 403) {
            window.location.href = "/account"
        }else if (response.status === 401) {
            window.location.href = "/account"
        }else{
            console.log("flights",response.data)
            setFlights(response.data)
        }

    }

    const fetchUserCommunityPosts = async (id) => {
        let endpoint = APPDATA.server + "/community/" + id + "/posts"
        let fetchResponse = await fetch(endpoint,{
            method: "GET",
            credentials: "include",
            headers: {
                "Content-Type": "application/json",
            }
        })
        let response = await fetchResponse.json()
        console.log(response)
        if (response.status === 403) {
            window.location.href = "/account"
        }else if (response.status === 401) {
            window.location.href = "/account"
        }else{
            console.log(response)
            setCommunity(response.data.reverse())
        }
    }

    

    return ( 
        <div>
            <MemberHeader selected={"Community"}/>
            {user?<div className="mppContainer">
                <div className="mppInnerContainer">
                    <div className="mppSplit">
                        <h1 className="mppTitle">Profile</h1>
                        <Link id="cancelButton" to={"/members/community"}>close</Link>
                    </div>

                    <div className="mppTopProfile">
                        <img src={`${APPDATA.server}/profile/${user._id}.jpeg`} id='profileImage' alt="" />
                        <h1 className='mppName'>{user.first_name} {user.last_name}</h1>
                        <p className='mppUserTitle'>{user.title}</p>
                        <p className='mppStats'>{user.flights} flight cycle | {user.flight_hours} hours</p>
                    </div>

                    <div className="mppRecentFlights">
                        <p className='mppSubtitle'>Recent Flights</p>
                        <div className="mppRecentFlightsContainer">
                            {flights?flights.map((flight) => {

                                let actual_start_time = flight.actual_start_time
                                let actual_start_time_hours = actual_start_time.split(":")[0]
                                let actual_start_time_minutes = actual_start_time.split(":")[1]

                                let actual_end_time = flight.actual_end_time
                                let actual_end_time_hours = actual_end_time.split(":")[0]
                                let actual_end_time_minutes = actual_end_time.split(":")[1]

                                let duration = (parseInt(actual_end_time_hours) - parseInt(actual_start_time_hours)) + " Hours " + (parseInt(actual_end_time_minutes) - parseInt(actual_start_time_minutes)) + " Min"

                                return(
                                    <div className="mppFlight">
                                        <div className="mppDataRow">
                                            <p className="mppDataLabel">Aircraft</p>
                                            <p className="mppDataValue">{flight.aircraft_type}</p>
                                        </div>

                                        <div className="mppDataRow">
                                            <p className="mppDataLabel">Distance Flown</p>
                                            <p className="mppDataValue">{flight.distance} km</p>
                                        </div>

                                        <div className="mppDataRow">
                                            <p className="mppDataLabel">Date</p>
                                            <p className="mppDataValue">{flight.flight_date}</p>
                                        </div>

                                        <div className="mppDataRow">
                                            <p className="mppDataLabel">Flying time</p>
                                            <p className="mppDataValue">{duration}</p>
                                        </div>
                                    </div>
                                )
                            }):null}
                        </div>
                    </div>

                    <div className="mppCommunityPost">
                        <p className='mppSubtitle'>Community Posts</p>
                        <div className="mppCommunityPostContainer">
                            {community.length > 0? community.map((post,index)=>{
                                if (post.visibility){

                                    let date = new Date(post.createdAt)
                                    {/* using date to show how long ago the post was created */}
                                    let time = date.toLocaleTimeString()
                                    let hour = time.split(":")[0]
                                    let minute = time.split(":")[1]
                                    let ampm = time.split(" ")[1]
                                    let day = date.getDate()
                                    let month = date.getMonth()
                                    let year = date.getFullYear()
                                    let dateCreated = `${day}/${month}/${year} ${hour}:${minute}${ampm}`

                                    

                                    return(
                                        <div className="mcpPost" key={index}>
                                            <div className="mcpPostHeader">
                                                <img src={`${APPDATA.server}/profile/${user._id}.jpeg`} className="mcpImg" alt="" />
                                                <div className="mcpPostHeaderInfo">
                                                    <h1>{post.user}</h1>
                                                    <p>{dateCreated}</p>
                                                </div>
                                            </div>
                                            <img src={APPDATA.server+"/"+post.images[0].replace("public","")} className="mcpImagePost" alt="" />
                                            <div className="mcpPostContents">
                                                <h1>{post.header}</h1>
                                                <p className="mcpDescription" >{post.description}</p>
                                            </div>
                                        </div>
                                    )
                                }else{
                                    return null;
                                }
                                
                            }):null}
                        </div>
                    </div>
                    
                </div>
            </div>:null}
            <Footer/>
        </div>
    );
}
 
export default MemberProfilePage;