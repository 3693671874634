import { Link, useNavigate } from "react-router-dom";
import Footer from "../components/Footer";
import MemberHeader from "../components/MemberHeader";
import { useState, useEffect } from "react";
import "../css/member-booking-page.css"
import { APPDATA } from "../app-config";

const MemberBookingPage = () => {
    const [bookings, setBookings] = useState([])
    const [specialBookings, setSpecialBookings] = useState([])
    const [loading, setLoading] = useState(true)
    const [windowWidth, setWindowWidth] = useState(window.innerWidth)

    const navigate = useNavigate();

    useEffect(() => {
        let userData = window.sessionStorage.getItem("accessToken")
        let user = JSON.parse(userData)
        fetchBookings(user._id)
    }, [])

    const fetchBookings = async (id) => {
        let endpoint = APPDATA.server + "/booking/" + id
        let fetchResponse = await fetch(endpoint, {
            method: "GET",
            credentials: "include",
            headers: {
                "Content-Type": "application/json",
            }
        })
        let response = await fetchResponse.json()
        if (response.status === 403) {
            window.location.href = "/account"
        } else if (response.status === 401) {
            window.location.href = "/account"
        } else {
            let bookings = response.data
            let user_booking = bookings.filter((booking) => {
                return booking.user_id === id
            })
            let special_booking = bookings.filter((booking) => {
                return booking.user_id !== id
            })
            setSpecialBookings(special_booking)
            setBookings(user_booking.reverse())
            setLoading(false)
        }
    }

    window.addEventListener("resize", () => {
        setWindowWidth(window.innerWidth)
    })



    return (
        <div>
            <MemberHeader selected={"Booking"} />
            <div className="mhpContainer">
                <div className="mhpInnerContainer">
                    <div className="mbpSplit">
                        <h1 className="mhpTitle">Your Flights</h1>
                        <Link id="newBookingButton" to={"/members/booking/new-booking"}>+ new bookings</Link>
                    </div>
                    {specialBookings.length ? <>
                        <h2>Work Flights</h2>
                        {windowWidth > 1200 ? <>
                            <div className="mbpTable">
                                <div className="mbpTableHeader">
                                    <p className="mbpTableHeaderItem" style={{ width: "30%" }}>Aircraft Type</p>
                                    <p className="mbpTableHeaderItem" style={{ width: "30%" }}>Flight Date</p>
                                    <p className="mbpTableHeaderItem" style={{ width: "30%" }}>Booking Time</p>
                                    <p className="mbpTableHeaderItem" style={{ width: "30%" }}>Flight Type</p>
                                    <p className="mbpTableHeaderItem" style={{ width: "30%" }}>Pilot</p>
                                    <p className="mbpTableHeaderItem" style={{ width: "30%" }}></p>
                                </div>
                                {loading ? <p>Loading...</p> : specialBookings.map((booking) => {
                                    return (
                                        <div className="mbpTableRow">
                                            <p className="mbpTableItem" style={{ width: "30%" }}>{booking.aircraft_type}</p>
                                            <p className="mbpTableItem" style={{ width: "30%" }}>{booking.flight_date}</p>
                                            <p className="mbpTableItem" style={{ width: "30%" }}>{booking.start_time}-{booking.end_time}</p>
                                            <p className="mbpTableItem" style={{ width: "30%" }}>{booking.flight_type}</p>
                                            <p className="mbpTableItem" style={{ width: "30%" }}>{booking.pilot_name}</p>
                                            <div className="mbpActionButton" style={{ width: "30%" }}>
                                                <a id="mbpViewButton" onClick={() => {
                                                    navigate("/members/booking/" + booking._id, { state: booking })
                                                }} to={"/members/booking/" + booking._id}>view</a>
                                            </div>
                                        </div>
                                    )
                                })}

                            </div>
                        </> :
                            <>
                                {loading ? <p>Loading...</p> : specialBookings.map((booking) => {
                                    return (
                                        <div className="mbpSmallTableContainer">
                                            <div className="mbpSmallTable">
                                                <div className="mbpSmallRow">
                                                    <p className="mbpSmallItem">Aircraft Type</p>
                                                    <p className="mbpSmallItem">{booking.aircraft_type}</p>
                                                </div>
                                                <div className="mbpSmallRow">
                                                    <p className="mbpSmallItem">Flight Date</p>
                                                    <p className="mbpSmallItem">{booking.flight_date}</p>
                                                </div>
                                                <div className="mbpSmallRow">
                                                    <p className="mbpSmallItem">Booking Time</p>
                                                    <p className="mbpSmallItem">{booking.start_time}-{booking.end_time}</p>
                                                </div>
                                                <div className="mbpSmallRow">
                                                    <p className="mbpSmallItem">Flight Type</p>
                                                    <p className="mbpSmallItem">{booking.flight_type}</p>
                                                </div>
                                                <div className="mbpSmallRow" style={{ borderBottomWidth: "0px" }}>
                                                    <p className="mbpSmallItem">Pilot</p>
                                                    <p className="mbpSmallItem">{booking.pilot_name}</p>
                                                </div>
                                                <div className="mbpActionButton">
                                                    <a id="mbpViewButton" onClick={() => {
                                                        navigate("/members/booking/" + booking._id, { state: booking })
                                                    }} to={"/members/booking/" + booking._id}>view</a>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                })}
                            </>
                        }
                    </> : null}



                    <h2>Your Booking</h2>
                    {windowWidth > 1200 ? <>
                        <div className="mbpTable">
                            <div className="mbpTableHeader">
                                <p className="mbpTableHeaderItem" style={{ width: "30%" }}>Aircraft Type</p>
                                <p className="mbpTableHeaderItem" style={{ width: "30%" }}>Flight Date</p>
                                <p className="mbpTableHeaderItem" style={{ width: "30%" }}>Booking Time</p>
                                <p className="mbpTableHeaderItem" style={{ width: "30%" }}>Flight Type</p>
                                <p className="mbpTableHeaderItem" style={{ width: "30%" }}>Booking Status</p>
                                <p className="mbpTableHeaderItem" style={{ width: "30%" }}></p>
                            </div>
                            {loading ? <p>Loading...</p> : bookings.map((booking) => {
                                if (booking.booking_status === "pending" || booking.booking_status === "confirmed") {
                                    return (
                                        <div className="mbpTableRow">
                                            <p className="mbpTableItem" style={{ width: "30%" }}>{booking.aircraft_type}</p>
                                            <p className="mbpTableItem" style={{ width: "30%" }}>{booking.flight_date}</p>
                                            <p className="mbpTableItem" style={{ width: "30%" }}>{booking.start_time}-{booking.end_time}</p>
                                            <p className="mbpTableItem" style={{ width: "30%" }}>{booking.flight_type}</p>
                                            <p className="mbpTableItem" style={{ width: "30%" }}>{booking.booking_status}</p>
                                            <div className="mbpActionButton" style={{ width: "30%" }}>
                                                <a id="mbpViewButton" onClick={() => {
                                                    navigate("/members/booking/" + booking._id, { state: booking })
                                                }} to={"/members/booking/" + booking._id}>view</a>
                                            </div>
                                        </div>
                                    )
                                }
                            })}

                        </div>
                    </> :
                        <>
                            {loading ? <p>Loading...</p> : bookings.map((booking) => {
                                if (booking.booking_status === "pending" || booking.booking_status === "confirmed") {
                                    return (
                                        <div className="mbpSmallTableContainer">
                                            <div className="mbpSmallTable">
                                                <div className="mbpSmallRow">
                                                    <p className="mbpSmallItem">Aircraft Type</p>
                                                    <p className="mbpSmallItem">{booking.aircraft_type}</p>
                                                </div>
                                                <div className="mbpSmallRow">
                                                    <p className="mbpSmallItem">Flight Date</p>
                                                    <p className="mbpSmallItem">{booking.flight_date}</p>
                                                </div>
                                                <div className="mbpSmallRow">
                                                    <p className="mbpSmallItem">Booking Time</p>
                                                    <p className="mbpSmallItem">{booking.start_time}-{booking.end_time}</p>
                                                </div>
                                                <div className="mbpSmallRow">
                                                    <p className="mbpSmallItem">Flight Type</p>
                                                    <p className="mbpSmallItem">{booking.flight_type}</p>
                                                </div>
                                                <div className="mbpSmallRow" style={{ borderBottomWidth: "0px" }}>
                                                    <p className="mbpSmallItem">Booking Status</p>
                                                    <p className="mbpSmallItem">{booking.booking_status}</p>
                                                </div>
                                                <div className="mbpActionButton">
                                                    <a id="mbpViewButton" onClick={() => {
                                                        navigate("/members/booking/" + booking._id, { state: booking })
                                                    }} to={"/members/booking/" + booking._id}>view</a>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                }
                            })}
                        </>
                    }

                    <h2>Require Payment</h2>
                    {windowWidth > 1200 ? <>
                        <div className="mbpTable">
                            <div className="mbpTableHeader">
                                <p className="mbpTableHeaderItem" style={{ width: "30%" }}>Aircraft Type</p>
                                <p className="mbpTableHeaderItem" style={{ width: "30%" }}>Flight Date</p>
                                <p className="mbpTableHeaderItem" style={{ width: "30%" }}>Booking Time</p>
                                <p className="mbpTableHeaderItem" style={{ width: "30%" }}>Flight Type</p>
                                <p className="mbpTableHeaderItem" style={{ width: "30%" }}>Payment Status</p>
                                <p className="mbpTableHeaderItem" style={{ width: "30%" }}></p>
                            </div>
                            {loading ? <p>Loading...</p> : bookings.map((booking) => {
                                if (booking.payment_status === "requesting-payment") {
                                    return (
                                        <div className="mbpTableRow">
                                            <p className="mbpTableItem" style={{ width: "30%" }}>{booking.aircraft_type}</p>
                                            <p className="mbpTableItem" style={{ width: "30%" }}>{booking.flight_date}</p>
                                            <p className="mbpTableItem" style={{ width: "30%" }}>{booking.start_time}-{booking.end_time}</p>
                                            <p className="mbpTableItem" style={{ width: "30%" }}>{booking.flight_type}</p>
                                            <p className="mbpTableItem" style={{ width: "30%" }}>{booking.payment_status}</p>
                                            <div className="mbpActionButton" style={{ width: "30%" }}>
                                                <a id="mbpViewButton" onClick={() => {
                                                    navigate("/members/booking/" + booking._id, { state: booking })
                                                }} to={"/members/booking/" + booking._id}>view</a>
                                            </div>
                                        </div>
                                    )
                                }
                            })}

                        </div>
                    </> :
                        <>
                            {loading ? <p>Loading...</p> : bookings.map((booking) => {
                                if (booking.payment_status === "requesting-payment") {
                                    return (
                                        <div className="mbpSmallTableContainer">
                                            <div className="mbpSmallTable">
                                                <div className="mbpSmallRow">
                                                    <p className="mbpSmallItem">Aircraft Type</p>
                                                    <p className="mbpSmallItem">{booking.aircraft_type}</p>
                                                </div>
                                                <div className="mbpSmallRow">
                                                    <p className="mbpSmallItem">Flight Date</p>
                                                    <p className="mbpSmallItem">{booking.flight_date}</p>
                                                </div>
                                                <div className="mbpSmallRow">
                                                    <p className="mbpSmallItem">Booking Time</p>
                                                    <p className="mbpSmallItem">{booking.start_time}-{booking.end_time}</p>
                                                </div>
                                                <div className="mbpSmallRow">
                                                    <p className="mbpSmallItem">Flight Type</p>
                                                    <p className="mbpSmallItem">{booking.flight_type}</p>
                                                </div>
                                                <div className="mbpSmallRow" style={{ borderBottomWidth: "0px" }}>
                                                    <p className="mbpSmallItem">Payment Status</p>
                                                    <p className="mbpSmallItem">{booking.payment_status}</p>
                                                </div>
                                                <div className="mbpActionButton">
                                                    <a id="mbpViewButton" onClick={() => {
                                                        navigate("/members/booking/" + booking._id, { state: booking })
                                                    }} to={"/members/booking/" + booking._id}>view</a>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                }
                            })}
                        </>
                    }

                    <h2>Past Booking</h2>
                    {windowWidth > 1200 ? <>
                        <div className="mbpTable">
                            <div className="mbpTableHeader">
                                <p className="mbpTableHeaderItem" style={{ width: "30%" }}>Aircraft Type</p>
                                <p className="mbpTableHeaderItem" style={{ width: "30%" }}>Flight Date</p>
                                <p className="mbpTableHeaderItem" style={{ width: "30%" }}>Booking Time</p>
                                <p className="mbpTableHeaderItem" style={{ width: "30%" }}>Flight Type</p>
                                <p className="mbpTableHeaderItem" style={{ width: "30%" }}>Payment Status</p>
                                <p className="mbpTableHeaderItem" style={{ width: "30%" }}></p>
                            </div>
                            {loading ? <p>Loading...</p> : bookings.map((booking) => {
                                if (booking.payment_status === "paid" && booking.booking_status === "completed") {
                                    return (
                                        <div className="mbpTableRow">
                                            <p className="mbpTableItem" style={{ width: "30%" }}>{booking.aircraft_type}</p>
                                            <p className="mbpTableItem" style={{ width: "30%" }}>{booking.flight_date}</p>
                                            <p className="mbpTableItem" style={{ width: "30%" }}>{booking.start_time}-{booking.end_time}</p>
                                            <p className="mbpTableItem" style={{ width: "30%" }}>{booking.flight_type}</p>
                                            <p className="mbpTableItem" style={{ width: "30%" }}>{booking.payment_status}</p>
                                            <div className="mbpActionButton" style={{ width: "30%" }}>
                                                <a id="mbpViewButton" onClick={() => {
                                                    navigate("/members/booking/" + booking._id, { state: booking })
                                                }} to={"/members/booking/" + booking._id}>view</a>
                                            </div>
                                        </div>
                                    )
                                }
                            })}

                        </div>
                    </> :
                        <>
                            {loading ? <p>Loading...</p> : bookings.map((booking) => {
                                if (booking.payment_status === "paid" && booking.booking_status === "completed") {
                                    return (
                                        <div className="mbpSmallTableContainer">
                                            <div className="mbpSmallTable">
                                                <div className="mbpSmallRow">
                                                    <p className="mbpSmallItem">Aircraft Type</p>
                                                    <p className="mbpSmallItem">{booking.aircraft_type}</p>
                                                </div>
                                                <div className="mbpSmallRow">
                                                    <p className="mbpSmallItem">Flight Date</p>
                                                    <p className="mbpSmallItem">{booking.flight_date}</p>
                                                </div>
                                                <div className="mbpSmallRow">
                                                    <p className="mbpSmallItem">Booking Time</p>
                                                    <p className="mbpSmallItem">{booking.start_time}-{booking.end_time}</p>
                                                </div>
                                                <div className="mbpSmallRow">
                                                    <p className="mbpSmallItem">Flight Type</p>
                                                    <p className="mbpSmallItem">{booking.flight_type}</p>
                                                </div>
                                                <div className="mbpSmallRow" style={{ borderBottomWidth: "0px" }}>
                                                    <p className="mbpSmallItem">Payment Status</p>
                                                    <p className="mbpSmallItem">{booking.payment_status}</p>
                                                </div>
                                                <div className="mbpActionButton">
                                                    <a id="mbpViewButton" onClick={() => {
                                                        navigate("/members/booking/" + booking._id, { state: booking })
                                                    }} to={"/members/booking/" + booking._id}>view</a>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                }
                            })}
                        </>
                    }


                </div>
            </div>
            <Footer />
        </div>
    );
}

export default MemberBookingPage;