import Header from "../components/Header";
import Footer from "../components/Footer";
import { MdAirplanemodeActive, MdOutlineHistoryEdu, MdPeopleAlt } from 'react-icons/md';
import { TbChevronDown, TbChevronUp } from 'react-icons/tb';
import { useState,useEffect } from 'react';
import "../css/index-page.css";
import { APPDATA } from "../app-config";
import RSFCChatBot from "../components/RSFCChatBot";

export default function IndexPage() {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth)

  window.onresize = () => {
    setWindowWidth(window.innerWidth)
    console.log(window.innerWidth)
  }

  const [name, setName] = useState("")
  const [email, setEmail] = useState("")
  const [message, setMessage] = useState("")

  const [faq1, setFaq1] = useState(false)
  const [faq2, setFaq2] = useState(false)
  const [faq3, setFaq3] = useState(false)
  const [faq4, setFaq4] = useState(false)
  const [faq5, setFaq5] = useState(false)
  const [faq6, setFaq6] = useState(false)

  const [announcement, setAnnouncement] = useState("")
  const [announcementStatus, setAnnouncementStatus] = useState(false)


  const submitSupport = async () => {
    if (email && name && message&&email.includes("@")&& email.indexOf("@")<email.lastIndexOf(".")){
      let endpoint = APPDATA.server + "/support/new"
      const fetchResponse = await fetch(endpoint, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          "Accept": "application/json"
        },
        body: JSON.stringify({
          name: name,
          email: email,
          message: message,
        })
      })
      
      const data = await fetchResponse.json()
      if (data.status === 200) {
        alert("Your Message has been received")
  
      } else {
        alert("Unable to send message to support")
      }
    }else {
      alert("Please fill in the necessary fields")
    }
   
  }

  const loadWebsiteContent = async() => {
    let endpoint = APPDATA.server + "/rsfc/website"
    let fetchResponse = await fetch(endpoint, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "Accept": "application/json"
      }
    })
    let response = await fetchResponse.json()
    console.log(response)
    if (fetchResponse.status === 200) {
      
      setAnnouncement(response.data.announcement)
      setAnnouncementStatus(response.data.announcement_status)
      if (response.data.maintenance_status ){
        window.location.href = "/maintenance"
      }
      
    }
  }

  // useEffect(() => {
  //   loadWebsiteContent()
  
  //   return () => {

  //   }
  // }, [])

  // Chat Bot


  return (
    <div className="App">
      <Header/>
      <RSFCChatBot/>

      {/* Header Image */}
      <div className="headerImageContainer">
        <img src="./img/header.png" alt="" className="headerImage" />
        <div className="headerDiv">
          <div className="headerAbsolute">
            <h1>Republic of Singapore Flying Club</h1>
            <h2>Making flying affordable for all</h2>
            <h4>Founded in 1928, Republic of Singapore Flying Club is one of Singapore most pioneer aviation flying club </h4>
            <div className="buttonContainer">
              <a href="./membership">Join Us Today!</a>
            </div>
          </div>
        </div>
      </div>
      {/* Statistics */}
      <div className="statistics">
        <div className="indyStats">
          <MdPeopleAlt size={200} />
          <h1>2,000+</h1>
          <h2>happy aviators flown with RSFC</h2>
        </div>
        <div className="indyStats">
          <MdOutlineHistoryEdu size={200} />
          <h1>90+</h1>
          <h2>years of pioneering the aviation</h2>
        </div>
        <div className="indyStats">
          <MdAirplanemodeActive size={200} />
          <h1>10,000+</h1>
          <h2>flights with RSFC with multiple events throughout the year</h2>
        </div>
      </div>

      {/* Checkered */}
      <div className="checked">
        {/* Div 1 */}
        <div className="splitDiv">
          <img src="./img/home1.png" alt="" />
          <div className="splitTextDiv">
            <div className="innerSplitDiv">
              <h1>About Us</h1>
              <p>Founded in 1928, Republic of Singapore Flying Club is one of Singapore most pioneer aviation flying club </p>
              <div className="buttonContainer">
                <a href="./about-us">Learn More!</a>
              </div>
            </div>
          </div>
        </div>


        {/* Div 2*/}


        <div className="splitDiv" id="special">
          <div className="splitTextDiv">
            <div className="innerSplitDiv">
              <h1>Want to fly?</h1>
              <p>Aspiring to be a captian of your own aircraft? Join us and the many talented pilots to help make that dream a reality</p>
              <div className="buttonContainer">
                <a href="./membership">Learn More!</a>
              </div>
            </div>
          </div>
          <img src="./img/home2.png" alt="" />
        </div>



        {/* Div 3 */}
        <div className="splitDiv">
          <img src="./img/home3.png" alt="" />
          <div className="splitTextDiv">
            <div className="innerSplitDiv">
              <h1>Our Fleet</h1>
              <p>We are committed to ensuring that you are safe while flying. All of our aircrafts are well maintained and regularly inspected to ensure the highest standard possible!</p>
              <div className="buttonContainer">
                <a href="./about-us#fleet">Learn More!</a>
              </div>
            </div>
          </div>
        </div>

      </div>


      {/* Mini FAQ */}
      <div className="miniFAQ">
        <h1>Frequently Asked Questions</h1>
        <button className="questionButton" onClick={()=>setFaq1(!faq1)}>
          <div className="innerButtonDiv">
            <p>What is the minimum age needed to start flying?</p>
            {faq1?<TbChevronUp size={40}/>:<TbChevronDown size={40} />}
          </div>
          {faq1?<p>The minimum age to apply for your Student Pilot License is 16 Years old</p>:null}
        </button>

        <button className="questionButton" onClick={()=>setFaq2(!faq2)}>
          <div className="innerButtonDiv">
            <p>How much does it usually cost if I would like to get my pilot license?</p>
            {faq2?<TbChevronUp size={40}/>:<TbChevronDown size={40} />}
          </div>
          {faq2?<p>For the cost, it varies between students, on average it costs approximately $15,000</p>:null}
        </button>

        <button className="questionButton" onClick={()=>setFaq3(!faq3)}>
          <div className="innerButtonDiv">
            <p>Which airport do we fly out off when we do our flight training?</p>
            {faq3?<TbChevronUp size={40}/>:<TbChevronDown size={40} />}
          </div>
          {faq3?<p>For all flight training, we will be using Seletar Airport (WSSL). It has a single runway, and it is widely used for general aviation in Singapore</p>:null}
        </button>

        <button className="questionButton" onClick={()=>setFaq4(!faq4)}>
          <div className="innerButtonDiv">
            <p>Can I bring someone along as I learn how to fly?</p>
            {faq4?<TbChevronUp size={40}/>:<TbChevronDown size={40} />}
          </div>
          {faq4?<p>It depends on your instructor. Up there, we want to ensure flight safety. If your instructor is confident that you can handle, why not?</p>:null}
        </button>

        <button className="questionButton" onClick={()=>setFaq5(!faq5)}>
          <div className="innerButtonDiv">
            <p>Where do I register if I would want to get my license?</p>
            {faq5?<TbChevronUp size={40}/>:<TbChevronDown size={40} />}
          </div>
          {faq5?<p>You will have to register with CAAS in Singapore and from there you may apply to be a member with our club.</p>:null}
        </button>
      
        <button className="questionButton" onClick={()=>setFaq6(!faq6)}>
          <div className="innerButtonDiv">  
            <p>How long does it usually take to get my Private Pilot License (PPL)?</p>
            {faq6?<TbChevronUp size={40}/>:<TbChevronDown size={40} />}
          </div>
          {faq6?<p>Usually on average it takes 8 months to 20 months. It depends on the student's commitment and capability</p>:null}
        </button>
      </div>

      {/* Question Forms */}

      <div className="questionForms">
        <h1>Still in doubt?</h1>
        <div className="forms">
          <h6>Starting out is the hardest part but we would like to take this journey with you.  Send us a message and we’ll have a team member get in touch.</h6>
          <div className="innerForms">
            {/* <p>name</p>
            <input type="text" value={name} style={{ width: "100%" }} onChange={(e) => setName(e.target.value)} className="spTextField" placeholder="Enter your name" /> */}
            <p>email</p>
            <input type="email" value={email} style={{ width: "100%" }} onChange={(e) => setEmail(e.target.value)} className="spTextField" placeholder="Enter your email" />
            <p>message</p>
            <textarea name="" id="" cols="30" rows="10" value={message} style={{ width: "100%" }} onChange={(e) => setMessage(e.target.value)} className="spTextField" placeholder="Enter your message"></textarea>
            <div className="buttonContainer">
              <a id="spSubmitButton" onClick={()=>{
                  // Link to email
                  let client = "manager@singaporeflyingclub.com"
                  let subject = "I would like to learn more!"
                  let body = `${message}`
                  window.location.href = `mailto:${client}?subject=${subject}&body=${body}`

                // submitSupport()
                }}>Send message</a>
            </div>
          </div>
        </div>
      </div>

        <Footer />
    </div>
    
  );
}