import Footer from "../components/Footer";
import MemberHeader from "../components/MemberHeader";
import "../css/member-community-page.css"
import { APPDATA } from "../app-config";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { IoEllipsisHorizontalSharp } from 'react-icons/io5';
import { red } from "@mui/material/colors";
import { Link } from "react-router-dom";


const MemberCommunityPage = () => {

    const [community, setCommunity] = useState([]);
    const [showComment, setShowComment] = useState()
    const [comments, setComments] = useState()
    const [upcomingEvents, setUpcomingEvents] = useState([])
    const [showOptions, setShowOptions] = useState(0)
    const [menuTop, setMenuTop] = useState()
    const [menuRight, setMenuRight] = useState()

    window.onscroll = () => {
        setShowOptions(false)
    }

    const navigate = useNavigate()

    useEffect(() => {
        fetchCommunity();
    }, []);

    const fetchCommunity = async () => {
        let endpoint = APPDATA.server + "/community/"
        let fetchResponse = await fetch(endpoint, {
            method: "GET",
            credentials: "include",
            headers: {
                "Content-Type": "application/json",
            },
        });
        let response = await fetchResponse.json();
        console.log(response);
        setCommunity(response.data.reverse());
    }

    const deletePost = async (post_id) => {
        let endpoint = APPDATA.server + "/community/" + post_id + "/delete";
        console.log(endpoint)
        let fetchResponse = await fetch(endpoint, {
            method: "POST",
            credentials: "include",
            headers: {
                "Content-Type": "application/json",
            },
        })
        let response = await fetchResponse.json()
        if (response.status === 200) {
            window.location.reload()
            fetchCommunity()
            alert("Post Deleted")
        } else {

            alert("Unable to delete post")
        }
    }

    const postComment = async (post_id) => {
        let endpoint = APPDATA.server + "/community/" + post_id + "/comment"
        console.log(endpoint)
        let fetchResponse = await fetch(endpoint, {
            method: "POST",
            credentials: "include",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                user_id: JSON.parse(window.sessionStorage.getItem("accessToken"))._id,
                comment: comments
            })
        });
        setComments("")
        setShowComment()
        let response = await fetchResponse.json();
        if (response.status === 200) {
            fetchCommunity()
            alert("Comment posted successfully")
        } else if (response.status === 403) {
            navigate("/account")
        } else if (response.status === 401) {
            navigate("/account")
        } else {
            alert("Something went wrong")
        }
    }

    useEffect(() => {
        fetchUpcomingEvents()
    }, [])

    const fetchUpcomingEvents = async (id) => {
        // const [events, setEvents] = useState([])
        let endpoint = APPDATA.server + "/events/"
        let fetchResponse = await fetch(endpoint, {
            method: "GET",
            credentials: "include",
            headers: {
                "Content-Type": "application/json",
            }
        })

        let response = await fetchResponse.json()

        if (response.status === 403) {
            window.location.href = "/account"
        } else if (response.status === 401) {
            window.location.href = "/account"
        } else {
            console.log(response.data)
            setUpcomingEvents(response.data)
        }
    }

    return (
        <div>
            <MemberHeader selected={"Community"} />
            <div className="mhpContainer">



                <div className="mhpInnerContainer">
                    <h1 className="mhpTitle">Upcoming Events</h1>
                    {/* {Upcoming Events} */}
                    <div className="mcpUpcomingEventsContainer">
                        {upcomingEvents.length > 0 ? upcomingEvents.map((upcomingEvent, index) => {
                            console.log(upcomingEvent)
                            const month = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"]
                            const startDate = new Date(upcomingEvent.event_start_date)
                            const startDay = startDate.getDate()
                            const startMonth = month[startDate.getMonth()];
                            const startYear = startDate.getFullYear();
                            const endDate = new Date(upcomingEvent.event_end_date)
                            const endDay = endDate.getDate()
                            const endMonth = month[endDate.getMonth()];
                            const endYear = endDate.getFullYear();
                            //check if the current time is not greater than the event end time
                            if (new Date() < new Date(upcomingEvent.event_end_date)) {
                                return (
                                    <div className="eventsThumbnail" key={index}>
                                        <img className="eventThumbnailImage" src={APPDATA.server + upcomingEvent.event_image[0].replace("public", "")} alt="" />
                                        <div className="eventDetails">
                                            <h2 className="eventThumbnailTitle">{upcomingEvent.event_title}</h2>
                                            <p className="eventThumbnailDate">{startDay} {startMonth} {startYear} to {endDay} {endMonth} {endYear}</p>
                                            <div className="eventEditButtonContainer">
                                                <button className="eventEditButton" onClick={() => {
                                                    navigate("/events/event-details/" + upcomingEvent._id, { state: upcomingEvent })
                                                }} to={"/events/event-details/" + upcomingEvent._id}>View Details</button>
                                            </div>

                                        </div>

                                    </div>
                                )
                            }


                        }) : null}
                    </div>
                    <div className="mbpSplit">
                        <h1 className="mhpTitle">RSFC Community</h1>
                        <Link id="newBookingButton" to={"/members/community/post"}>+ new post</Link>
                    </div>
                    {/* Post View */}
                    <div className="mcpPostContainer">
                        {community.length > 0 ? community.map((post, index) => {
                            if (post.visibility) {
                                console.log(post)
                                let date = new Date(post.createdAt)
                                {/* using date to show how long ago the post was created */ }
                                let time = date.toLocaleTimeString()
                                let hour = time.split(":")[0]
                                let minute = time.split(":")[1]
                                let ampm = time.split(" ")[1]
                                let day = date.getDate()
                                let month = date.getMonth()
                                let year = date.getFullYear()
                                let dateCreated = `${day}/${month}/${year} ${hour}:${minute}${ampm}`



                                return (
                                    <div className="mcpPost" key={index}>

                                        <div className="mcpPostHeader" >
                                            <div className="mcpProfileAndInfo" onClick={() => navigate("/members/profile/" + post.user_id)}>
                                                <img src={`${APPDATA.server}/profile/${post.user_id}.jpeg`} className="mcpImg" alt="" loading="lazy" />
                                                <div className="mcpPostHeaderInfo">
                                                    <h1>{post.user}</h1>
                                                    <p>{dateCreated}</p>
                                                </div>
                                            </div>
                                            <div className="mcpOptionButton" onClick={(e) => { showOptions === index ? setShowOptions(false) : setShowOptions(index); setMenuTop(e.currentTarget.getBoundingClientRect()); setMenuRight(e.currentTarget.style.right) }}>
                                                <IoEllipsisHorizontalSharp size={40} style={{ cursor: "pointer" }} className="ellipsis" />

                                                {showOptions === index ? <div style={{ position: "absolute", top: menuTop, right: menuRight }} >
                                                    <a style={{ padding: "10px 20px", backgroundColor: "#1B4965", color: "white", borderRadius: "5px" }} onClick={() => deletePost(post.id)} color={red}>Delete</a>
                                                </div> : null}
                                            </div>
                                        </div>
                                        <img src={APPDATA.server + post.images[0].replace("public", "")} className="mcpImagePost" alt="" loading="lazy" />
                                        <div className="mcpPostContents">
                                            <h1>{post.header}</h1>
                                            <p className="mcpDescription" >{post.description}</p>
                                        </div>

                                        <div className="mcpCommentsContainer">
                                            <div className="mcpCommentSplit">
                                                <p style={{ fontWeight: "bold" }}>Comments</p>
                                                <a onClick={() => setShowComment(showComment === index ? "" : index)}>+ Comments</a>
                                            </div>

                                            {showComment === index ? <div className="mcpNewComments">
                                                <p className="mcpNewComments">
                                                    <div className="mcpCommentSplit">
                                                        <p style={{ fontWeight: "bold" }}>Add a new comment</p>
                                                        <a onClick={() => postComment(post.id)}>Post Comment</a>
                                                    </div>
                                                    <textarea name="" className="mcpCommentSection" value={comments} onChange={(e) => setComments(e.target.value)} cols="30" rows="10"></textarea>
                                                </p>
                                            </div> : null}

                                            <div className="mcpCommentSection">
                                                {post.comments.length ? post.comments.map((comment, index) => {
                                                    return (
                                                        <div className="mcpComment" key={index}>
                                                            <div className="mcpCommentHeader" onClick={() => navigate("/members/profile/" + comment.user_id)}>
                                                                <img src={`${APPDATA.server}/profile/${comment.user_id}.jpeg`} className="mcpCommentImg" alt="" />
                                                                <p>{comment.comment}</p>
                                                            </div>
                                                        </div>
                                                    )
                                                }) : <p>No comments yet</p>}
                                            </div>
                                        </div>


                                    </div>
                                )
                            } else {
                                return null;
                            }

                        }) : null}
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    );
}

export default MemberCommunityPage;