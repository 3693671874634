import AdminHeader from "../components/AdminHeader";
import Footer from "../components/Footer";
import "../css/admin-control-panel-page.css"
import { APPDATA } from "../app-config";
import { useState, useEffect } from "react"
import { TbTruckLoading } from "react-icons/tb";
import { Link, useNavigate } from "react-router-dom";
import Switch from "react-switch";
import { BsDownload } from "react-icons/bs"




const AdminControlPanelPage = () => {
    const [support, setSupport] = useState([])
    const [loading, setLoading] = useState(true)
    const [postStatus, setPostStatus] = useState(false)
    const [announcement, setAnnouncement] = useState("")
    const [maintenance, setMaintenance] = useState(false)
    const navigate = useNavigate()
    const [windowWidth, setWindowWidth] = useState(window.innerWidth)
    const [fleetData, setFleetData] = useState()

    const [websiteConfiguration, setWebsiteConfiguration] = useState()
    const [automatedPayment, setAutomatedPayment] = useState()
    const [hourlyRate, setHourlyRate] = useState()
    const [instructorRate, setInstructorRate] = useState()
    const [examinerRate, setExaminerRate] = useState()
    const [incrementalRate, setIncrementalRate] = useState()
    const [applications, setApplications] = useState()


    const fetchSupport = async () => {
        let endpoint = APPDATA.server + "/support/"
        let fetchResponse = await fetch(endpoint, {
            method: "GET",
            credentials: "include",
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + JSON.parse(window.sessionStorage.getItem("accessToken")).token,
                "x-access-token": JSON.parse(window.sessionStorage.getItem("accessToken")).token,
                "withCredentials": true
            }
        })

        let response = await fetchResponse.json()
            console.log(response)
            setSupport(response.data)
            setLoading(false)
    

    }

    const fetchMaintenance = async () => {
        let endpoint = APPDATA.server + "/rsfc/maintenance"
        let fetchResponse = await fetch(endpoint, {
            method: "GET",
            credentials: "include",
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json",
            }
        })
        let response = await fetchResponse.json()
        console.log(response)
        setMaintenance(response.data.maintenance_status)
        setLoading(false)

    }

    const submitAnnouncement = async () => {
        if (announcement.length > 300) {
            alert("Announcement exceed character count")
        } else {
            let endpoint = APPDATA.server + "/rsfc/announcement"
            let fetchResponse = await fetch(endpoint, {
                method: "POST",
                credentials: "include",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": "Bearer " + JSON.parse(window.sessionStorage.getItem("accessToken")).token,
                    "x-access-token": JSON.parse(window.sessionStorage.getItem("accessToken")).token
                },
                body: JSON.stringify({
                    announcement: announcement,
                    status: postStatus
                })
            })

            let response = await fetchResponse.json()
            if (response.status === 403) {

            } else {
                console.log(response)
                alert("Announcement Updated")
            }
        }
    }

    const fetchApplications = async () => {
        let endpoint = APPDATA.server + "/application/"
        let fetchResponse = await fetch(endpoint, {
            method: "GET",
            credentials: "include",
            headers: {
                "Content-Type": "application/json",
            }
        })
        let response = await fetchResponse.json()
        if (response.status === 403) {

        } else {
            console.log(response)
            setApplications(response.data)
            setLoading(false)
        }
        
    }

    const fetchAnnouncement = async () => {

        let endpoint = APPDATA.server + "/rsfc/announcement"
        let fetchResponse = await fetch(endpoint, {
            method: "GET",
            credentials: "include",
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + JSON.parse(window.sessionStorage.getItem("accessToken")).token,
                "x-access-token": JSON.parse(window.sessionStorage.getItem("accessToken")).token
            }
        })

        let response = await fetchResponse.json()
        if (response.status === 403) {

        } else {
            console.log(response)
            setAnnouncement(response.data.announcement)
            setPostStatus(response.data.announcement_status)
        }
    }

    const submitMaintenance = async () => {
        let endpoint = APPDATA.server + "/rsfc/maintenance"
        let fetchResponse = await fetch(endpoint, {
            method: "POST",
            credentials: "include",
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + JSON.parse(window.sessionStorage.getItem("accessToken")).token,
                "x-access-token": JSON.parse(window.sessionStorage.getItem("accessToken")).token
            },
            body: JSON.stringify({
                status: maintenance
            })
        })

        let response = await fetchResponse.json()
        if (response.status === 403) {

        } else {
            console.log(response)
        }
    }

    const fetchDataDownload = async (type) => {
        let endpoint = APPDATA.server + "/rsfc/download"
        let fetchResponse = await fetch(endpoint, {
            method: "POST",
            credentials: "include",
            headers: {
                "Content-Type": "application/json",

            },
            body: JSON.stringify({
                data_type: type
            })
        })
        let response = await fetchResponse.json()
        console.log(response)
        if (response.status === 403) {

        } else {
            let link = response.payload
            window.open(`${APPDATA.server}${link}`,)

        }
    }

    const fetchFleet = async () => {
        let endpoint = APPDATA.server + "/fleet/"
        let fetchResponse = await fetch(endpoint, {
            method: "GET",
            credentials: "include",
            headers: {
                "Content-Type": "application/json",
            }
        })
        let response = await fetchResponse.json()
        setFleetData(response.data)

    }

    const fetchConfigurations = async() =>{
        let endpoint = APPDATA.server + "/rsfc/configurations"
        let fetchResponse = await fetch(endpoint, {
            credentials: "include",
            method: "GET",
            headers: {
                "Content-Type": "application/json",
            }
        })
        let response = await fetchResponse.json()
        setWebsiteConfiguration(response.data)
        setExaminerRate(response.data.examiner_rate)
        setInstructorRate(response.data.instructor_rate)
        setHourlyRate(response.data.hourly_rate)
        setAutomatedPayment(response.data.automated_payment)
        setIncrementalRate(response.data.incremental_rate)

        
    }

    const submitConfigurations = async() =>{
        let endpoint = APPDATA.server + "/rsfc/configurations"
        let fetchResponse = await fetch(endpoint, {
            credentials: "include",
            method: "POST",
            credentials: "include",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                examiner_rate: examinerRate,
                instructor_rate: instructorRate,
                hourly_rate: hourlyRate,
                automated_payment: automatedPayment,
                incremental_rate: incrementalRate
            })
        })
        let response = await fetchResponse.json()
        setWebsiteConfiguration(response.data)
        setExaminerRate(response.data.examiner_rate)
        setInstructorRate(response.data.instructor_rate)
        setHourlyRate(response.data.hourly_rate)
        setAutomatedPayment(response.data.automated_payment)
    }

    useEffect(() => {
        fetchSupport()
        fetchAnnouncement()
        fetchMaintenance()
        fetchFleet()
        fetchConfigurations()
        fetchApplications()
    }, [])

    useEffect(() => {
        submitMaintenance()
    }, [maintenance])

    useEffect(() => {
        submitConfigurations()
    }, [examinerRate, instructorRate, hourlyRate, automatedPayment,incrementalRate])

    window.addEventListener("resize", () => {
        setWindowWidth(window.innerWidth)
    })

    return (
        <div>
            <AdminHeader selected={"Control Panel"} />
            <div className="acppContainer">
                <div className="acppInnerContainer">
                    <h1 className="acppTitle">Control Panel</h1>
                    {/* Website Configuration */}
                    <h2>Website Configuration</h2>
                    <div className="acppConfigContainer">
                        <h3>Maintenance Mode</h3>
                        <div className="acppPostStatus">
                            <Switch checked={maintenance} onChange={(checked, event) => { setMaintenance(checked); console.log(checked) }} />
                            <p style={{ marginLeft: "10px" }}>Site Status: {maintenance ? "Site under maintenance mode" : "Site is accessible"}</p>
                        </div>

                        <div className="acppButtonContainer">
                            <h3>Announcement Banner</h3>
                            <p style={{ color: announcement.length > 300 ? "red" : "black" }}>{announcement.length}/300</p>
                        </div>
                        <textarea name="announcement" onChange={(e) => setAnnouncement(e.target.value)} id="" cols="30" rows="10" value={announcement}></textarea>
                        <div className="acppButtonContainer">
                            <div className="acppPostStatus">
                                <Switch checked={postStatus} onChange={(checked, event) => { setPostStatus(checked); console.log(checked) }} />
                                <p style={{ marginLeft: "10px" }}>Post Status: {postStatus ? "Visible to Public" : "Hidden"}</p>
                            </div>
                            <button className="acppButton" onClick={submitAnnouncement}>Save</button>
                        </div>
                    </div>


                    {/* Booking Section */}
                    <h2>Booking Configuration</h2>
                    <div className="acppConfigContainer">
                        <h3>Flight Payment</h3>
                        <div className="acppSplit">
                            <p>Automated Payment</p>
                            <Switch checked={automatedPayment} onChange={(checked, event) => { setAutomatedPayment(checked); console.log(checked) }} />
                        </div>
                        <div className="acppSplitForm">
                            <p className="acppLabel">Flight Hourly Rate</p>
                            <input type="text" className="acppField" value={hourlyRate} onChange={(e) => setHourlyRate(e.target.value)} />
                        </div>
                        <div className="acppSplitForm">
                            <p className="acppLabel">Flight Rate (After 30 Minute)</p>
                            <input type="text" className="acppField" value={incrementalRate} onChange={(e) => setIncrementalRate(e.target.value)} />
                        </div>
                        <div className="acppSplitForm">
                            <p className="acppLabel">Instructor Rate</p>
                            <input type="text" className="acppField" value={instructorRate} onChange={(e) => setInstructorRate(e.target.value)} />
                        </div>
                        <div className="acppSplitForm">
                            <p className="acppLabel">Examiner Rate</p>
                            <input type="text" className="acppField" value={examinerRate} onChange={(e) => setExaminerRate(e.target.value)} />
                        </div>
                    </div>

                    {/* Fleet Section */}
                    <div className="acppSplit">
                        <h2>RSFC Fleet</h2>
                        <Link className="acppNewButton" to={"/admin/fleet/new"}>+ new aircraft</Link>
                    </div>
                    <div className="spTable">
                        {windowWidth > 1200 ? <>
                            <div className="spTableHeader">
                                <p className="spTableHeaderItem" style={{ width: "30%" }}>S/N</p>
                                <p className="spTableHeaderItem" style={{ width: "50%" }}>Aircraft Name</p>
                                <p className="spTableHeaderItem" style={{ width: "30%" }}>Registration</p>
                                <p className="spTableHeaderItem" style={{ width: "30%" }}>Status</p>
                                <p className="spTableHeaderItem" style={{ width: "30%" }}>Cycles</p>
                                <p className="spTableHeaderItem" style={{ width: "30%" }}>Hours</p>
                                <p className="spTableHeaderItem" style={{ width: "30%" }}></p>
                            </div>


                            {fleetData ? fleetData.map((fleet,index) => {

                                return (
                                    <div className="spTableRow">
                                        <p className="spTableItem" style={{ width: "30%" }}>{index+1}</p>
                                        <p className="spTableItem" style={{ width: "50%" }}>{fleet.aircraft_name}</p>
                                        <p className="spTableItem" style={{ width: "30%" }}>{fleet.aircraft_registration}</p>
                                        <p className="spTableItem" style={{ width: "30%" }}>{fleet.aircraft_status}</p>
                                        <p className="spTableItem" style={{ width: "30%" }}>{fleet.aircraft_flight_cycles}</p>
                                        <p className="spTableItem" style={{ width: "30%" }}>{fleet.aircraft_flight_hours}</p>
                                        <div className="spActionButton" style={{ width: "30%" }}>
                                            <a id="mbpViewButton" onClick={() => {
                                                navigate(`/admin/fleet/${fleet._id}`, { state: fleet })
                                            }} to={`/admin/fleet/${fleet._id}`}>view</a>
                                        </div>
                                    </div>
                                )
                            }) : <p>loading...</p>}
                        </> :
                            <>
                                {fleetData ? fleetData.map((fleet,index) => {
                                    return (
                                        <div className="spSmallTable">
                                            <div className="spSmallTableRow">
                                                <p className="spSmallTableItem">S/N</p>
                                                <p className="spSmallTableItem">{index+1}</p>
                                            </div>
                                            <div className="spSmallTableRow">
                                                <p className="spSmallTableItem">Aircraft Name</p>
                                                <p className="spSmallTableItem">{fleet.aircraft_name}</p>
                                            </div>
                                            <div className="spSmallTableRow">
                                                <p className="spSmallTableItem">Aircraft Registration</p>
                                                <p className="spSmallTableItem">{fleet.aircraft_registration}</p>
                                            </div>
                                            <div className="spSmallTableRow">
                                                <p className="spSmallTableItem">Status</p>
                                                <p className="spSmallTableItem">{fleet.aircraft_status}</p>
                                            </div>
                                            <div className="spSmallTableRow">
                                                <p className="spSmallTableItem">Flight Cycles</p>
                                                <p className="spSmallTableItem">{fleet.aircraft_flight_cycles}</p>
                                            </div>
                                            <div className="spSmallTableRow">
                                                <p className="spSmallTableItem">Flight Hours</p>
                                                <p className="spSmallTableItem">{fleet.aircraft_flight_hours}</p>
                                            </div>
                                            <div className="spActionButton">
                                                <a id="mbpViewButton" onClick={() => {
                                                    navigate(`/admin/fleet/${fleet._id}`, { state: fleet })
                                                }} to={`/admin/fleet/${fleet._id}`}>view</a>
                                            </div>
                                        </div>
                                    )
                                }) : null}

                            </>
                        }

                    </div>






                    {/* Download Data */}
                    <h2>Download Centre</h2>
                    <div className="spDataContainer">

                        <div className="spDatarow">
                            <h2 className="spDataHeader">Member's Data</h2>
                            <p className="spDescription">Download the Republic of Singapore Flying Club Member's data. Data file consists of member's name, account details, license etc.</p>
                            <div className="spDataButtonContainer">
                                <div className="spDataDownloadButton" onClick={() => fetchDataDownload("members")}>
                                    <p className="spButtonText">Download</p>
                                    <BsDownload color={"white"} />
                                </div>
                            </div>
                        </div>

                        <div className="spDatarow">
                            <h2 className="spDataHeader">Booking Data</h2>
                            <p className="spDescription">Download all flight bookings made by members of the The Republic of Singapore Flying Club. Data file consists of flight times and the flight they have made</p>
                            <div className="spDataButtonContainer">
                                <div className="spDataDownloadButton" onClick={() => fetchDataDownload("booking")}>
                                    <p className="spButtonText">Download</p>
                                    <BsDownload color={"white"} />
                                </div>
                            </div>
                        </div>

                        {/* <div className="spDatarow">
                            <h2 className="spDataHeader">Event's Data</h2>
                            <p className="spDescription">Download the Republic of Singapore Flying Club Event's data. The Data file consists of the event details, event attendees and more.</p>
                            <div className="spDataButtonContainer">
                                <div className="spDataDownloadButton" onClick={()=>fetchDataDownload("events")}>
                                    <p className="spButtonText">Download</p>
                                    <BsDownload color={"white"} />
                                </div>
                            </div>
                        </div> */}

                        <div className="spDatarow">
                            <h2 className="spDataHeader">Community Data</h2>
                            <p className="spDescription">Download the Republic of Singapore Flying Club Community's data. The Data file consists of community titles, descriptions, images and more.</p>
                            <div className="spDataButtonContainer">
                                <div className="spDataDownloadButton" onClick={() => fetchDataDownload("community")}>
                                    <p className="spButtonText">Download</p>
                                    <BsDownload color={"white"} />
                                </div>
                            </div>
                        </div>

                    </div>

                    {/* Support Section */}
                    <h2>My Support</h2>

                    <div className="spTable">
                        {windowWidth > 1200 ? <>
                            <div className="spTableHeader">
                                <p className="spTableHeaderItem" style={{ width: "30%" }}>Name</p>
                                <p className="spTableHeaderItem" style={{ width: "50%" }}>Email</p>
                                <p className="spTableHeaderItem" style={{ width: "30%" }}>Date</p>
                                <p className="spTableHeaderItem" style={{ width: "30%" }}>Status</p>
                                <p className="spTableHeaderItem" style={{ width: "30%" }}></p>
                            </div>


                            {support ? support.map((support_data) => {
                                let date = new Date(support_data.createdAt)
                                let dateCreated = date.getDate() + "/" + (date.getMonth() + 1) + "/" + date.getFullYear()

                                return (
                                    <div className="spTableRow">
                                        <p className="spTableItem" style={{ width: "30%" }}>{support_data.name}</p>
                                        <p className="spTableItem" style={{ width: "50%" }}>{support_data.email}</p>
                                        <p className="spTableItem" style={{ width: "30%" }}>{dateCreated}</p>
                                        <p className="spTableItem" style={{ width: "30%" }}>{support_data.case_status}</p>
                                        <div className="spActionButton" style={{ width: "30%" }}>
                                            <a id="mbpViewButton" onClick={() => {
                                                navigate("/admin/control-panel/support", { state: support_data })
                                            }} to={"/admin/control-panel/support"}>view</a>
                                        </div>
                                    </div>
                                )
                            }) : <p>loading...</p>}
                        </> :
                            <>
                                {support ? support.map((support_data) => {
                                    let date = new Date(support_data.createdAt)
                                    let dateCreated = date.getDate() + "/" + (date.getMonth() + 1) + "/" + date.getFullYear()
                                    return (
                                        <div className="spSmallTable">
                                            <div className="spSmallTableRow">
                                                <p className="spSmallTableItem">Name</p>
                                                <p className="spSmallTableItem">{support_data.name}</p>
                                            </div>
                                            <div className="spSmallTableRow">
                                                <p className="spSmallTableItem">Email</p>
                                                <p className="spSmallTableItem">{support_data.email}</p>
                                            </div>
                                            <div className="spSmallTableRow">
                                                <p className="spSmallTableItem">Date</p>
                                                <p className="spSmallTableItem">{dateCreated}</p>
                                            </div>
                                            <div className="spActionButton">
                                                <a id="mbpViewButton" onClick={() => {
                                                    navigate("/admin/control-panel/support", { state: support_data })
                                                }} to={"/admin/control-panel/support"}>view</a>
                                            </div>
                                        </div>
                                    )
                                }) : null}

                            </>
                        }

                    </div>

                    {/* Application Approval */}
                    <h2>Application Approval</h2>
                    <div className="spTable">
                        {windowWidth > 1200 ? <>
                            <div className="spTableHeader">
                                <p className="spTableHeaderItem" style={{ width: "30%" }}>Name</p>
                                <p className="spTableHeaderItem" style={{ width: "30%" }}>Application Type</p>
                                <p className="spTableHeaderItem" style={{ width: "30%" }}>Date</p>
                                <p className="spTableHeaderItem" style={{ width: "30%" }}>Documents</p>
                                <p className="spTableHeaderItem" style={{ width: "30%" }}>Status</p>
                                <p className="spTableHeaderItem" style={{ width: "30%" }}></p>
                            </div>


                            {applications ? applications.map((application) => {
                                let date = new Date(application.createdAt)
                                let dateCreated = date.getDate() + "/" + (date.getMonth() + 1) + "/" + date.getFullYear()
                                console.log(application)
                                return (
                                    <div className="spTableRow">
                                        <p className="spTableItem" style={{ width: "30%" }}>{application.name}</p>
                                        <p className="spTableItem" style={{ width: "30%" }}>{application.application_type}</p>
                                        <p className="spTableItem" style={{ width: "30%" }}>{dateCreated}</p>
                                        <p className="spTableItem" style={{ width: "30%" }}>{application.application_documents?application.application_documents.length:0} documents</p>
                                        <p className="spTableItem" style={{ width: "30%" }}>{application.application_status}</p>
                                        <div className="spActionButton" style={{ width: "30%" }}>
                                            <a id="mbpViewButton" onClick={() => {
                                                navigate("/admin/control-panel/application", { state: application })
                                            }} to={"/admin/control-panel/application"}>view</a>
                                        </div>
                                    </div>
                                )
                            }) : <p>loading...</p>}
                        </> :
                            <>
                                {applications ? applications.map((application) => {
                                    let date = new Date(application.createdAt)
                                    let dateCreated = date.getDate() + "/" + (date.getMonth() + 1) + "/" + date.getFullYear()
                                    return (
                                        <div className="spSmallTable">
                                            <div className="spSmallTableRow">
                                                <p className="spSmallTableItem">Name</p>
                                                <p className="spSmallTableItem">{application.name}</p>
                                            </div>
                                            <div className="spSmallTableRow">
                                                <p className="spSmallTableItem">Application Type</p>
                                                <p className="spSmallTableItem">{application.email}</p>
                                            </div>
                                            <div className="spSmallTableRow">
                                                <p className="spSmallTableItem">Date</p>
                                                <p className="spSmallTableItem">{dateCreated}</p>
                                            </div>
                                            <div className="spSmallTableRow">
                                                <p className="spSmallTableItem">Documents</p>
                                                <p className="spSmallTableItem">{application.application_documents.length} documents</p>
                                            </div>
                                            <div className="spSmallTableRow">
                                                <p className="spSmallTableItem">Status</p>
                                                <p className="spSmallTableItem">{application.application_status}</p>
                                            </div>
                                            <div className="spActionButton">
                                                <a id="mbpViewButton" onClick={() => {
                                                    navigate("/admin/control-panel/application", { state: application })
                                                }} to={"/admin/control-panel/application"}>view</a>
                                            </div>
                                        </div>
                                    )
                                }) : null}

                            </>
                        }

                    </div>


                </div>


            </div>
            <Footer />
        </div>
    );
}

export default AdminControlPanelPage;