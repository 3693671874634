import AdminHeader from "../components/AdminHeader";
import Footer from "../components/Footer";
import { Link,useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";
import "../css/admin-events-page.css"
import { APPDATA } from "../app-config";

const AdminEventsPage = () => {
    const [events, setEvents] = useState([])
    
    const [windowWidth, setWindowWidth] = useState(window.innerWidth)

    const navigate = useNavigate();
    useEffect(() => {
        fetchEvents()
    }, [])

    const fetchEvents = async (id) => {
        let endpoint = APPDATA.server + "/events/"
        let fetchResponse = await fetch(endpoint, {
            method: "GET",
            credentials: "include",
            headers: {
                "Content-Type": "application/json",
            }
        })
        
        let response = await fetchResponse.json()

        if (response.status === 403) {
            window.location.href = "/account"
        }else if (response.status === 401) {
            window.location.href = "/account"
        }else{
            console.log(response.data)
            setEvents(response.data)
        }
    }

    window.addEventListener("resize", () => {
        setWindowWidth(window.innerWidth)
    })

    
    return ( 
        <div>
            <AdminHeader selected={"Events"}/>
            <div className="aepContainer">
                <div className="aepInnerContainer">
                    <div className="aepSplit">
                        <h1 className="aepTitle">Events</h1>
                        <Link id="newEventButton" to={"/admin/events/new-event"}>+ new event</Link>
                    </div>
                </div>
                    
                <div className="eventContainer">
                    {events.length>0?events.map((event,index)=>{
                        console.log(event)
                        const month = ["January","February","March","April","May","June","July","August","September","October","November","December"]
                        const startDate = new Date(event.event_start_date)                       
                        const startDay = startDate.getDate()
                        const startMonth = month[startDate.getMonth()];
                        const startYear = startDate.getFullYear();
                        const endDate = new Date(event.event_end_date)
                        const endDay = endDate.getDate()
                        const endMonth = month[endDate.getMonth()];
                        const endYear = endDate.getFullYear();
                        if (startMonth, endMonth)
                        return(
                            <div className="eventsThumbnail" key={index}>
                                <img className="eventThumbnailImage" src={APPDATA.server+event.event_image[0].replace("public","")} alt="" />
                                <div className="eventDetails">
                                    <h2 className="eventThumbnailTitle">{event.event_title}</h2>
                                    <p className="eventThumbnailDate">{startDay} {startMonth} {startYear} to {endDay} {endMonth} {endYear}</p>
                                    <div className="eventEditButtonContainer">
                                        <button className="eventEditButton" onClick={() => {
                                            navigate("/admin/events/" + event._id, { state: event })
                                        }} to={"/admin/events/" + event._id}>View</button>
                                    </div>
                                    
                                </div>
                                
                            </div>
                        )
                        
                    }):null}
                        
                </div>
                    
                    
            
            </div>
            <Footer/>
        </div>
    );
}
 
export default AdminEventsPage;