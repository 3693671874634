import Footer from "../components/Footer";
import Header from "../components/Header";
import {useState} from 'react';
import "../css/account-page.css"
import { APPDATA } from "../app-config";


const AccountRecoveryPage = () => {
    const [pageState, setPageState] = useState('change-password')


    const [password1, setPassword1] = useState("")
    const [password2, setPassword2] = useState("")

    const [usernameEmail, setUsernameEmail] = useState("")




    const submitChangePassword = async() =>{
        if (password1 === password2){
           let endpoint = APPDATA.server + "/account/password"
            const fetchResponse = await fetch(endpoint, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    "Accept": "application/json"
                },
                body: JSON.stringify({
                    password:password1,
                    recovery_id: window.location.pathname.split("/")[2]
                })
            })
            const data = await fetchResponse.json()
            if (data.status === 200){
                window.location.href = "/account"
                alert("Password Changed")
            }else{
                alert("Unable to change password")
            } 
        }else{
            alert("Passwords do not match")
        }
        
    }


    if (pageState === 'change-password'){
        return ( 
            <div>
                <Header/>
                <div className="apContainer">
                    <div className="apInnerContainer">
                        <h1 className="apTitle">Account Recovery</h1>
                        <h2 className="apSubtitle">Change your password</h2>

                        <div className="apForms">
                            <div className="apFormContainer">
                                <h3 className="apFormTitle">Password</h3>
                                <input type="password" value={password1} style={{width:"100%"}} onChange={(e)=>setPassword1(e.target.value)} className="apTextField" placeholder="Choose a strong password"/>
                                <h3 className="apFormTitle">Re-enter Password</h3>
                                <input type="password"value={password2} style={{width:"100%"}} onChange={(e)=>setPassword2(e.target.value)} className="apTextField" placeholder="Re-enter the same password"/>

                                <div className="apButtonContainer">
                                    <a id="apSubmitButton" onClick={submitChangePassword}>Request Change</a>
                                </div>
                            </div>
                            
                        </div>
                    </div>
                </div>
                <Footer/>
            </div>
        );
    } else {
        return(
            <div>
                <Header/>
                <Footer/>
            </div>
        )
    }
}
 
export default AccountRecoveryPage;