
import Footer from "../components/Footer";
import Header from "../components/Header";
import RSFCChatBot from "../components/RSFCChatBot";
import "../css/membership-page.css"
import { useState } from "react";
import { APPDATA } from "../app-config";

export default function MembershipPage() {
    const [name, setName] = useState("")
    const [email, setEmail] = useState("")
    const [username, setUsername] = useState("")
    const [contactNumber, setContactNumber] = useState("")

    const submitApplication = async() => {
        let endpoint = APPDATA.server + "/rsfc/membership"
        let data = {
            name: name,
            email: email,
            username: username,
            contactNumber: contactNumber
        }
        let fetchResponse = await fetch(endpoint, {
            method: "POST",

            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify(data)
        })
        let response = await fetchResponse.json()
        console.log(response)
        alert("Application submitted successfully!")
    }

    return (
        <div>
            <Header />
            <RSFCChatBot/>
            {/* Our Membership */}
            <div className="empty" id="ourmembership"></div>
            <div className="mpContainer">
                <img className="eventHeaderImage" src="./img/experience3.png" alt="" />
                <div className="mpInnerContainer">
                    <h1 className="mpTitle">Our Membership</h1>
                    <p>Being a member with us means that you get to have access to our planes at a discounted rate and you get to join our ever growing club of aviators. Learn from only the best at Singapore’s oldest and reputatable flying club. You may simply create a free account to join the RSFC community and see what other fellow pilots are doing or you may fill in this application form and join us as an ordinary member which entitles you to book flights with us. What are you waiting for join us today!</p>
                    {/* <div className="mpWrapper">
                        <div className="mpDetails">

                            <div className="detailsContainer">
                                <div className="mpContactDetails">
                                    <h4>Name</h4>
                                    <input type="text" className="mpTextInput" value={name} onChange={(e)=>setName(e.target.value)} />
                                </div>

                                <div className="mpContactDetails">
                                    <h4>E-mail</h4>
                                    <input type="email" className="mpTextInput" value={email} onChange={(e)=>setEmail(e.target.value)}/>
                                </div>
                            </div>

                            <div className="detailsContainer">
                                <div className="mpContactDetails">
                                    <h4>Username</h4>
                                    <input type="text" className="mpTextInput" value={username} onChange={(e)=>setUsername(e.target.value)}/>
                                </div>

                                <div className="mpContactDetails">
                                    <h4>Contact Number</h4>
                                    <input type="tel" className="mpTextInput" value={contactNumber} onChange={(e)=>setContactNumber(e.target.value)}/>
                                </div>

                            </div>


                            <div className="mpButtonContainer">
                                <a id="submitButton" onClick={()=>{
                                    submitApplication()
                                }}>Submit Application</a>

                            </div>
                        </div>
                    </div> */}



                    {/* Learning to Fly */}
                    <div className="empty" id="learningtofly"></div>
                    <div className="learnToFly">
                        <h1 className="mpTitle">Learning to fly</h1>
                        <p>Republic of Singapore Flying Club license students with a Private Pilot License (PPL). The PPL is for leisure flying purposes ONLY.<br /><br />The Republic of Singapore Flying Club conducts training courses for its members to obtain the Singapore restricted Private Pilot Licence. <br /><br />Due to the lack of airspace in Singapore it is not possible to complete the Pilot Navigation Training phase of the syllabus and upon successful completion of the training and Flight Test, a student will be issued with a Singapore restricted PPL. <br /><br />However, an unrestricted PPL is still obtainable by completing the Pilot Navigation Training with an affiliated flying club in Malaysia after obtaining your restricted PPL. </p>
                    </div>


                    {/* Summary of how to get PPL */}
                    <div className="empty" id="summary"></div>
                    <div className="empty"></div>
                    <h1 className="mpTitle" >Summary of how to obtain a Singapore Private Pilot License (Aeroplanes) </h1>

                    <div className="pplSteps">
                        <div className="mpStepsContainer">
                            <p>1. Medical Check Up</p>
                            <p>Perform your medical check up at Raffles Medical @Changi Airport Terminal 3</p>
                        </div>

                        <div className="mpStepsContainer">
                            <p>2. Apply for Student Pilot License</p>
                            <p>After your medical examination, you may process to apply for a Student Pilot License (SPL) with CAAS </p>
                        </div>

                        <div className="mpStepsContainer">
                            <p>3. Join the RSFC as an Ordinary Member</p>
                            <p>Pay the required fees to enrol as a member of our club
                                At the same time, apply for Airport Security Pass</p>
                        </div>

                        <div className="mpStepsContainer">
                            <p>4. Begin flying training</p>
                            <p>Once all the previous mentioned steps have been completed, you are ready to start your training!</p>
                        </div>

                        <div className="mpStepsContainer">
                            <p>5. Prepare and sit for Oral and Theory Exams</p>
                            <p>At the same time while you are having your flight training, you will have to prepare and sit for the CAAS R/T oral exam as well as 6 CAAS theory exams before you can start flying.</p>
                        </div>

                        <div className="mpStepsContainer">
                            <p>6. Flight Handling Test</p>
                            <p>You are now ready to go for your Flight Handling Test to determine if you are competent to fly a plane by urself.</p>
                        </div>

                        <div className="mpStepsContainer">
                            <p>7. Apply for Restricted PPL</p>
                            <p>A Restricted PPL allows you to fly as Pilot-In-Command (PIC) of an aircraft within the aerodrome control zone and local training areas only</p>
                        </div>

                        <div className="mpStepsContainer">
                            <p>8. Attend optional Navigation Training (Optional)</p>
                            <p>This training is conducted by an affiliated flying club in Malaysia in order to obtain the full PPL</p>
                        </div>

                        <div className="mpStepsContainer">
                            <p>9. Apply for Full PPL</p>
                            <p>A full PPL allows you to fly as a PIC or co-pilot of a Singapore-registered aircraft (not operated for hire and reward), for the aircraft types or groups as endorsed in your licence.</p>
                        </div>
                    </div>
                    {/* <div className="buttonContainer">
                        <a id="applyButton" href="/apply">Click here to apply</a>
                    </div> */}
                </div>
            </div>
            <Footer />
        </div>
    )
}
